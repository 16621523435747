import {
  Component,
  AfterViewInit,
  ViewEncapsulation,
  input,
  inject,
} from '@angular/core';
import { TreeNode } from '@ali-hm/angular-tree-component';
import { MainDashboardComponent } from '../../../../site-pages/secure-pages/main-dashboard/main-dashboard.component';
import { AuthService } from '../../../../authentication/auth.service';
import { CommonModule } from '@angular/common';
declare let $: any; // for jquery dom selections
declare let _: any;

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule],
})
export class ItemComponent implements AfterViewInit {
  node = input<TreeNode>(); // data for this tree node
  private mainDashboard = inject(MainDashboardComponent);
  private auth = inject(AuthService);

  ngAfterViewInit(): void {
    // modify item details element to be expanded if appropriate
    let nodeId = this.node().data.id;
    let listExpandedNodeIds = this.mainDashboard.detailsExpanded;
    let isExpanded = listExpandedNodeIds.indexOf(nodeId) != -1;

    let detailsExpandedDiv = $(
      '#item_' + this.node().data.id + ' * .item-details'
    );
    if (isExpanded) {
      detailsExpandedDiv.addClass('in');
    }

    // use regex on item status and priority string values to apply category
    // labels to the item status and priority header elements in the form of
    // classes
    // (e.g., 'to-do-status', 'in-progress-status', 'high-priority') in order to have
    // applicable styles applied to them
    let itemStatusString = this.node().data.status.toLowerCase();
    let itemStatusHtmlElem = $(
      '#item_' + this.node().data.id + ' * .status-in-header'
    );

    if (new RegExp('to do').test(itemStatusString)) {
      itemStatusHtmlElem.addClass('to-do-status');
    }
    if (new RegExp('progress').test(itemStatusString)) {
      itemStatusHtmlElem.addClass('in-progress-status');
    }
    if (
      new RegExp('done').test(itemStatusString) ||
      new RegExp('complete').test(itemStatusString)
    ) {
      itemStatusHtmlElem.addClass('done-status');
    }
    if (new RegExp('blocked').test(itemStatusString)) {
      itemStatusHtmlElem.addClass('blocked-status');
    }
    if (itemStatusString == '') {
      $(itemStatusHtmlElem).hide();
    }

    let itemPriorityString = this.node().data.priority.toLowerCase();
    let itemPriorityHtmlElem = $(
      '#item_' + this.node().data.id + ' * .priority-in-header'
    );

    if (new RegExp('low').test(itemPriorityString)) {
      itemPriorityHtmlElem.addClass('low-priority');
    }
    if (new RegExp('medium').test(itemPriorityString)) {
      itemPriorityHtmlElem.addClass('medium-priority');
    }
    if (new RegExp('normal').test(itemPriorityString)) {
      itemPriorityHtmlElem.addClass('normal-priority');
    }
    if (new RegExp('high').test(itemPriorityString)) {
      itemPriorityHtmlElem.addClass('high-priority');
    }
    if (
      new RegExp('critical').test(itemPriorityString) ||
      new RegExp('very high').test(itemPriorityString)
    ) {
      itemPriorityHtmlElem.addClass('critical-priority');
    }
    if (itemPriorityString == '') {
      $(itemPriorityHtmlElem).hide();
    }

    // modify the item color to be a function of its type (a string)
    let itemElement = $('#item_' + this.node().data.id);
    let itemType = this.node().data.type.toLowerCase();
    let itemColor = this.generateColorFromString(itemType);
    itemElement.css('background-color', itemColor);

    // tag items for which initial tutorial popover should be shown
    let itemTitleString = this.node().data.title.toLowerCase();
    if (itemTitleString == 'my first project') {
      itemElement.addClass('my-first-project-item');
    }
  }

  // given an arbitrary string, returns a string hsl representation of a color
  // based on a hardwired mapping function
  generateColorFromString(str: string): string {
    return this.mainDashboard.generateColorFromString(str);
  }

  // returns the number of item history records that are displayed by default
  getNumEventsDisplayedByDefault(): number {
    return this.mainDashboard.numEventsInRecentHistory();
  }

  // returns whether this item is shared with any other users in addition to the
  // current user
  isShared(): boolean {
    return this.node().data['shared_with'].length > 1;
  }

  // given a list of item assignee objects, returns a string of the text that
  // should be displayed on the frontend to indicate the assignees for this item
  // e.g., given ["currentUser@gmail.com", "user2@gmail.com", "user3@gmail.com"],
  // return 'Me, user2, user3'
  getAssigneesDisplayText(itemAssignees: any[]): string {
    let fullDisplayText = '';

    for (let i = 0; i < itemAssignees.length; i++) {
      let thisAssigneeEmail = itemAssignees[i];
      let userDisplayText =
        this.getUserDisplayTextForPermissions(thisAssigneeEmail);
      fullDisplayText = fullDisplayText.concat(userDisplayText);

      // if it isn't the last assignee, add a comma
      if (i < itemAssignees.length - 1) {
        fullDisplayText = fullDisplayText.concat(', ');
      }
    }
    return fullDisplayText;
  }

  // zoom to display just this item and its descendents
  zoomToThisItem(): void {
    window.location.hash = '#/'.concat(this.node().data.id);
  }

  // given a list of item subscriber objects, returns a string of the text that
  // should be displayed on the frontend to indicate the subscribers for this item
  // e.g., given ["currentUser@gmail.com", "user2@gmail.com", "user3@gmail.com"],
  // return 'Me, user2, user3'
  getSubscribersDisplayText(itemSubscribers: any[]): string {
    let fullDisplayText = '';

    for (let i = 0; i < itemSubscribers.length; i++) {
      let thisSubscriberEmail = itemSubscribers[i];
      let userDisplayText =
        this.getUserDisplayTextForPermissions(thisSubscriberEmail);
      fullDisplayText = fullDisplayText.concat(userDisplayText);

      // if it isn't the last subscriber, add a comma
      if (i < itemSubscribers.length - 1) {
        fullDisplayText = fullDisplayText.concat(', ');
      }
    }
    return fullDisplayText;
  }

  // given a list of this item's strictly inherited permissions and its explicit
  // permissions, returns returns a string of the text that should be displayed
  // on the frontend to indicate the users this item is shared with (not including the current
  // user)
  getPermissionsDisplayText(allPermissions: any[]): string {
    let fullDisplayText = '';
    let isFirstPermission = true;

    for (let i = 0; i < allPermissions.length; i++) {
      let permission = allPermissions[i];
      // if it isn't the first permission, add a comma at the beginning
      if (!isFirstPermission) {
        fullDisplayText = fullDisplayText.concat(', ');
      }
      let userDisplayText = this.getUserDisplayTextForPermissions(
        permission.user.email
      );
      fullDisplayText = fullDisplayText.concat(userDisplayText);
      isFirstPermission = false;
    }

    if (fullDisplayText == '') {
      fullDisplayText = '(none)';
    }

    return fullDisplayText;
  }

  // given a list of this item's tags (a list of strings), returns a single
  // string of the text that should be displayed after "Tags:" for the item
  // in view mode
  getTagsDisplayText(tags: any[]): string {
    let tagsText = '';

    for (let i = 0; i < tags.length; i++) {
      let tag = '#'.concat(tags[i]);
      tagsText = tagsText.concat(tag);

      // if it isn't the last tag, add a comma
      if (i < tags.length - 1) {
        tagsText = tagsText.concat(', ');
      }
    }

    return tagsText;
  }

  // given a user email corresponding to a user with whom an item is shared (as
  // a string), returns the text of how this user is referenced and displayed
  // in the frontend (as a string)
  //   - basically, if the email corresponds to the current user, return "Me"
  //   - else return just the prefix of the email to be displayed
  getUserDisplayTextForPermissions(userEmail: string): string {
    let currentUser = this.auth.getCurrentUser().email;
    if (userEmail == currentUser) {
      return 'Me';
    } else {
      return this.mainDashboard.getPrefix(userEmail);
    }
  }

  // returns true if this item has its full history (not just its recent history)
  // displayed
  hasFullHistoryDisplayed(): boolean {
    let fullHistoryExpanded = this.mainDashboard.fullHistoryExpanded;
    let thisNodeId = this.node().id;
    let hasFullHistDisplayed = fullHistoryExpanded.indexOf(thisNodeId) != -1;

    return hasFullHistDisplayed;
  }

  // returns a boolean of whether or not this item has more events than are
  // shown by default as "recent history"
  isMoreHistory(): boolean {
    return (
      this.node().data.history.length >
      this.mainDashboard.numEventsInRecentHistory()
    );
  }

  // given an item id, adds/removes the item to/from the current list of items
  // with details expanded (called when user clicks the "expand/collapse item
  // details" button)
  toggleDetailsExpanded(itemId: number): void {
    this.mainDashboard.toggleDetailsExpanded(itemId);
  }

  // returns the correct class name to append to the "expand/collapse item details"
  // button such that it displays the correct icon based on whether the item's
  // details are currently expanded or not
  getAppropriateExpandCollapseGlyphicon(): string {
    let nodeId = this.node().data.id;
    let listExpandedNodeIds = this.mainDashboard.detailsExpanded;
    let isExpanded = listExpandedNodeIds.indexOf(nodeId) != -1;

    if (isExpanded) {
      return 'glyphicon-menu-up';
    } else {
      return 'glyphicon-menu-down';
    }
  }

  // returns the correct text for the "expand/collapse item details" button
  // hovertext based on whether the item's details are currently expanded or not
  getAppropriateExpandCollapseButtonHovertext(): string {
    let nodeId = this.node().data.id;
    let listExpandedNodeIds = this.mainDashboard.detailsExpanded;
    let isExpanded = listExpandedNodeIds.indexOf(nodeId) != -1;

    if (isExpanded) {
      return 'Collapse details';
    } else {
      return 'Expand details';
    }
  }

  // generate the string for the hovertext of the item's title header
  getItemTitleHoverText(node: TreeNode): string {
    let hoverText = '';
    if (node) {
      hoverText = node.data.title;
      if (node.children != null && node.children.length) {
        let numChildren = node.children.length;
        let numChildrenString = '';
        if (numChildren == 1) {
          numChildrenString = ' ('
            .concat(numChildren.toString())
            .concat(' subitem)');
        } else {
          numChildrenString = ' ('
            .concat(numChildren.toString())
            .concat(' subitems)');
        }
        hoverText = hoverText.concat(numChildrenString);
      }
      hoverText = hoverText.concat(' id:').concat(node.data.id);
      return hoverText || '';
    }
  }

  // generate a string containing the count of this node's children in parentheses if it has
  // children; otherwise, return an empty string
  getStringForNumChildrenInParen(node: TreeNode): string {
    if (node && node.children?.length) {
      let numChildren = node.children.length;
      if (numChildren != 0) {
        return `(${numChildren})`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  // moves this item and its descendents from trash back to the main dashboard;
  // puts it back in the same position within the original tree
  moveFromTrashButtonAction(): void {
    this.mainDashboard.timeOfLastItemUpdate.set(new Date());

    // move item and descendents from trash
    let nodeToMoveFromTrash = this.node();
    let allSiblings = nodeToMoveFromTrash.parent.data.children;
    let index = nodeToMoveFromTrash.index;
    allSiblings.splice(index, 1);
    if (allSiblings.length == 0) {
      nodeToMoveFromTrash.parent.data.hasChildren = false;
    }
    this.mainDashboard.itemsInTrashTreeNodes = [ ...this.mainDashboard.itemsInTrashTreeNodes];

    // add item and descendents to dashboard
    let oldParentId = nodeToMoveFromTrash.data['parent_id']; // get relevant variables
    let oldParentNode = null;
    let oldSiblings = this.mainDashboard.itemsAllTreeNodes;
    if (oldParentId) {
      oldParentNode = this.mainDashboard.itemsAllTree.getTreeNodeById(oldParentId);
    }
    if (oldParentNode) {
      oldSiblings = oldParentNode.data.children;
    }

    oldSiblings.unshift(nodeToMoveFromTrash.data); // add item to array of old siblings, reorder by display order, and update tree
    this.mainDashboard.sortByKey(oldSiblings, 'display_order');
    // this.mainDashboard.allItems.treeModel.update();
    this.mainDashboard.itemsAllTreeNodes = [...this.mainDashboard.itemsAllTreeNodes];
    this.mainDashboard.applyItemFilter();

    this.mainDashboard.numItemsAddedThisMonth.set(
      this.mainDashboard.numItemsAddedThisMonth() + 1
    );

    // update item history
    let newEvent = {
      action: 'undelete',
      object_type: 'item',
      object_name: this.mainDashboard.abbreviatedVersion(
        nodeToMoveFromTrash.data['title']
      ),
      timestamp: new Date(),
      before_value: null,
      after_value: null,
      user: this.auth.getCurrentUser().email,
      was_automated_action: false,
      new: true,
    };
    nodeToMoveFromTrash.data['history'].unshift(newEvent);
    nodeToMoveFromTrash.data['in_trash'] = false;
    this.mainDashboard.updateInTrashPropertyForItemAndDescendents(
      nodeToMoveFromTrash.data,
      false
    );

    // add action to array of newest changes
    let itemUpdate = {
      type: 'moveItemFromTrash',
      timestamp: new Date(),
      data: { itemId: this.node().data.id, item: this.node().data },
    };
    this.mainDashboard.queueItemUpdate(itemUpdate);
    this.mainDashboard.scheduleAutoSave();
  }

  // add a child item for this item
  addChildItem(): void {
    if (this.mainDashboard.addLimitReached()) {
      this.mainDashboard.showLimitReachedDialog();
    } else {
      this.mainDashboard.itemBeingEdited = this.mainDashboard.generateNewItem(
        this.node().data.id,
        -1
      ); // set dummy display order; will set it on save
      this.mainDashboard.showEditItemDialog();
    }
  }

  // given an item history record object, returns a string of text to be
  // displayed to the user communicating the contents of that record
  generateHistoryRecordText(record: any): string {
    return this.mainDashboard.generateHistoryRecordText(record);
  }

  // triggered by the "pencil/edit" icon; sets the item to be in edit mode
  editItemButtonAction($event: any): void {
    this.mainDashboard.itemBeingEdited = $.extend(true, {}, this.node().data);
    this.mainDashboard.showEditItemDialog();
  }

  // show only most recent history of this item
  hideFullHistory(): void {
    // remove node from fullHistoryExpanded
    let nodeId = this.node().data.id;
    let fullHistoryExpanded = this.mainDashboard.fullHistoryExpanded;
    let index = fullHistoryExpanded.indexOf(nodeId);
    fullHistoryExpanded.splice(index, 1);
  }

  // show full history of this item
  showFullHistory(): void {
    // add to list of node ids with expanded full history displayed
    let nodeId = this.node().data.id;
    let fullHistoryExpanded = this.mainDashboard.fullHistoryExpanded;
    fullHistoryExpanded.push(nodeId);
  }

  // returns a boolean indicating whether this are additional events in this item's
  // history (aside from those in "recent history") that are currently not displayed
  additionalHistoryHidden(): boolean {
    return this.isMoreHistory() && !this.hasFullHistoryDisplayed();
  }
}

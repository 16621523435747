import { Component, AfterViewInit, inject } from '@angular/core';
import { LoadingService } from '../../../shared/services/loading.service';
declare let $: any; // for jquery dom selections

@Component({
  selector: 'app-page-not-found-page',
  templateUrl: './page-not-found-page.component.html',
})
export class PageNotFoundComponent implements AfterViewInit {
  private loadingService = inject(LoadingService);
  
  ngAfterViewInit(): void {
    // wait until window is fully loaded before hiding the loading screen
    // (which covers the other content)
    this.loadingService.hide();
  }
}

import { inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth.service';
import { map, Observable, take, tap } from 'rxjs';

// Authentication guard to prevent users who aren't logged in from seeing
// logged in content
@Injectable({
  providedIn: 'root',
})
export class LoggedInContentAuthGuard implements CanActivate {
  private router = inject(Router);
  private auth = inject(AuthService);

  constructor() {}

  canActivate(): Observable<boolean> {
    let output = this.auth.getAuthState().pipe(
      take(1),
      map((authState) => !!authState),
      tap({
        next: (authenticated) => {
          // if no user is logged in, renavigate them to homepage
          if (!authenticated) this.router.navigate(['home']);
          else {
            // if a user is logged in but they haven't yet verified their email,
            // also redirect them to the homepage
            if (this.auth.getCurrentUser().emailVerified == false) {
              this.router.navigate(['home']);
            }
          }
        },
        error: (error) => {
          console.log('on error', error.message);
        },
      })
    );
    return output;
  }
}

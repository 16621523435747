<a href="/"
  ><img
    class="projectzen-logo"
    src="/assets/projectzen_logo.jpg"
    alt="projectzen_logo.jpg"
/></a>
<!--<a class="big-button position-inline-with-margin" href="signup">Sign Up</a>-->
<h1 class="main-header">Do More, Better.</h1>
<h2 class="subheader">
  ProjectZen is an elegant, flexible tool for agile teams.
</h2>
<form class="container log-in-form">
  <div class="text-inputs">
    <div class="container label text-box-label-container">
      <label class="label text-box-label">Email<br /></label>
    </div>
    <input
      class="input text-input-field"
      id="txtEmail"
      type="email"
      name="email"
      required
      spellcheck="false"
      autocorrect="off"
      maxlength="75"
      autocapitalize="off"
    />
    <div class="container label text-box-label-container">
      <label class="label text-box-label">Password<br /></label>
    </div>
    <input
      id="txtPassword"
      class="input text-input-field"
      type="password"
      name="password"
      required
    />
  </div>
  <div class="container big-button-container position-centered">
    <button
      type="button"
      id="btnLogin"
      class="small-button position-top-right"
      class="btn btn-secondary"
      (click)="logInUser('txtEmail', 'txtPassword')"
    >
      Log In
    </button>
  </div>
</form>
<div class="container forgot-password-link-container">
  <a class="forgot-password-link" href="accounts/password-reset"
    >Forgot password?</a
  >
</div>
<div class="page-footer">
  <p>ProjectZen, 6 Liberty Square #2334, Boston, MA 02109</p>
  <p>&nbsp;</p>
  <a href="terms">Terms</a>
  <p>&nbsp;</p>
  <a href="privacy">Privacy</a>
  <p>&nbsp;</p>
  <a href="mailto:help@projectzen.com">Help</a>
</div>

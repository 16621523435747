import { ITreeOptions, TreeComponent, TreeModule, TreeNode } from '@ali-hm/angular-tree-component';
import { Component, input, output, ViewChild } from '@angular/core';
import { ItemComponent } from '../item/item.component';

@Component({
  standalone: true,
  selector: 'app-tree-root',
  imports: [ItemComponent, TreeModule],
  templateUrl: './tree-root.component.html',
  styleUrl: './tree-root.component.scss',
})
export class TreeRootComponent {
  @ViewChild(TreeComponent) treeRoot: TreeComponent;

  readonly onMovedNode = output();
  readonly options = input.required<ITreeOptions>();
  readonly nodes= input.required<Array<TreeNode>>();

  moveNode($event) {
    this.onMovedNode.emit($event);
  }

  updateModel() {
    if(this.treeRoot){
      this.treeRoot.treeModel.update();
    }
  }

  blurFocusedNodes(): void {
    const focusedNode = this.treeRoot.treeModel.getFocusedNode();
    if (focusedNode) {
      if (focusedNode.isActive) {
        focusedNode.toggleActivated();
      }
      focusedNode.blur();
    }
  }

  getTreeNodeById(id: string){
    return this.treeRoot.treeModel.getNodeById(id);
  }

  expandById(id: string){
    const node = this.getTreeNodeById(id);
    if(node){
      node.expand();
    }
  }
}

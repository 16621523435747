import * as i0 from '@angular/core';
import { Directive, Input, Injectable, Component, ViewEncapsulation, EventEmitter, Output, HostListener, ContentChild, ViewChild, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { autorun, reaction, computed as computed$1, observable as observable$1, action as action$1 } from 'mobx';
const _c0 = a0 => ({
  $implicit: a0
});
function LoadingComponent_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1, "loading...");
    i0.ɵɵelementEnd();
  }
}
const _c1 = (a0, a1, a2) => ({
  $implicit: a0,
  node: a1,
  index: a2
});
function TreeNodeContent_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.node.displayField);
  }
}
const _c2 = () => ({
  dontDetach: true
});
function TreeNodeExpanderComponent_ng_container_0_span_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 3);
    i0.ɵɵlistener("click", function TreeNodeExpanderComponent_ng_container_0_span_1_Template_span_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.node.mouseAction("expanderClick", $event));
    });
    i0.ɵɵelement(1, "span", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("toggle-children-wrapper-expanded", ctx_r1.node.isExpanded)("toggle-children-wrapper-collapsed", ctx_r1.node.isCollapsed);
  }
}
function TreeNodeExpanderComponent_ng_container_0_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 5);
  }
}
function TreeNodeExpanderComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TreeNodeExpanderComponent_ng_container_0_span_1_Template, 2, 4, "span", 1)(2, TreeNodeExpanderComponent_ng_container_0_span_2_Template, 1, 0, "span", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.node.hasChildren);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.node.hasChildren);
  }
}
function TreeNodeCheckboxComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "input", 1);
    i0.ɵɵlistener("click", function TreeNodeCheckboxComponent_ng_container_0_Template_input_click_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.node.mouseAction("checkboxClick", $event));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("checked", ctx_r1.node.isSelected)("indeterminate", ctx_r1.node.isPartiallySelected);
  }
}
const _c3 = (a0, a1, a2, a3) => ({
  $implicit: a0,
  node: a1,
  index: a2,
  templates: a3
});
function TreeNodeWrapperComponent_div_0_tree_node_checkbox_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tree-node-checkbox", 4);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("node", ctx_r1.node);
  }
}
function TreeNodeWrapperComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, TreeNodeWrapperComponent_div_0_tree_node_checkbox_1_Template, 1, 1, "tree-node-checkbox", 3);
    i0.ɵɵelement(2, "tree-node-expander", 4);
    i0.ɵɵelementStart(3, "div", 5);
    i0.ɵɵlistener("click", function TreeNodeWrapperComponent_div_0_Template_div_click_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.node.mouseAction("click", $event));
    })("dblclick", function TreeNodeWrapperComponent_div_0_Template_div_dblclick_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.node.mouseAction("dblClick", $event));
    })("mouseover", function TreeNodeWrapperComponent_div_0_Template_div_mouseover_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.node.mouseAction("mouseOver", $event));
    })("mouseout", function TreeNodeWrapperComponent_div_0_Template_div_mouseout_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.node.mouseAction("mouseOut", $event));
    })("contextmenu", function TreeNodeWrapperComponent_div_0_Template_div_contextmenu_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.node.mouseAction("contextMenu", $event));
    })("treeDrop", function TreeNodeWrapperComponent_div_0_Template_div_treeDrop_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.node.onDrop($event));
    })("treeDropDragOver", function TreeNodeWrapperComponent_div_0_Template_div_treeDropDragOver_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.node.mouseAction("dragOver", $event));
    })("treeDropDragLeave", function TreeNodeWrapperComponent_div_0_Template_div_treeDropDragLeave_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.node.mouseAction("dragLeave", $event));
    })("treeDropDragEnter", function TreeNodeWrapperComponent_div_0_Template_div_treeDropDragEnter_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.node.mouseAction("dragEnter", $event));
    });
    i0.ɵɵelement(4, "tree-node-content", 6);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵstyleProp("padding-left", ctx_r1.node.getNodePadding());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.node.options.useCheckbox);
    i0.ɵɵadvance();
    i0.ɵɵproperty("node", ctx_r1.node);
    i0.ɵɵadvance();
    i0.ɵɵclassProp("node-content-wrapper-active", ctx_r1.node.isActive)("node-content-wrapper-focused", ctx_r1.node.isFocused);
    i0.ɵɵproperty("treeAllowDrop", ctx_r1.node.allowDrop)("allowDragoverStyling", ctx_r1.node.allowDragoverStyling())("treeDrag", ctx_r1.node)("treeDragEnabled", ctx_r1.node.allowDrag());
    i0.ɵɵadvance();
    i0.ɵɵproperty("node", ctx_r1.node)("index", ctx_r1.index)("template", ctx_r1.templates.treeNodeTemplate);
  }
}
function TreeNodeChildrenComponent_ng_container_0_div_1_tree_node_collection_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tree-node-collection", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("nodes", ctx_r0.node.children)("templates", ctx_r0.templates)("treeModel", ctx_r0.node.treeModel);
  }
}
function TreeNodeChildrenComponent_ng_container_0_div_1_tree_loading_component_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tree-loading-component", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵstyleProp("padding-left", ctx_r0.node.getNodePadding());
    i0.ɵɵproperty("template", ctx_r0.templates.loadingTemplate)("node", ctx_r0.node);
  }
}
function TreeNodeChildrenComponent_ng_container_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, TreeNodeChildrenComponent_ng_container_0_div_1_tree_node_collection_1_Template, 1, 3, "tree-node-collection", 2)(2, TreeNodeChildrenComponent_ng_container_0_div_1_tree_loading_component_2_Template, 1, 4, "tree-loading-component", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("tree-children", true)("tree-children-no-padding", ctx_r0.node.options.levelPadding);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.node.children);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.node.children);
  }
}
function TreeNodeChildrenComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TreeNodeChildrenComponent_ng_container_0_div_1_Template, 3, 6, "div", 1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("treeAnimateOpen", ctx_r0.node.isExpanded)("treeAnimateOpenSpeed", ctx_r0.node.options.animateSpeed)("treeAnimateOpenAcceleration", ctx_r0.node.options.animateAcceleration)("treeAnimateOpenEnabled", ctx_r0.node.options.animateExpand);
  }
}
function TreeNodeCollectionComponent_ng_container_0_tree_node_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tree-node", 2);
  }
  if (rf & 2) {
    const node_r1 = ctx.$implicit;
    const i_r2 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("node", node_r1)("index", i_r2)("templates", ctx_r2.templates);
  }
}
function TreeNodeCollectionComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵtemplate(2, TreeNodeCollectionComponent_ng_container_0_tree_node_2_Template, 1, 3, "tree-node", 1);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵstyleProp("margin-top", ctx_r2.marginTop);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.viewportNodes)("ngForTrackBy", ctx_r2.trackNode);
  }
}
function TreeNodeComponent_ng_container_0_div_1_tree_node_drop_slot_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tree-node-drop-slot", 6);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("dropIndex", ctx_r0.node.index)("node", ctx_r0.node.parent);
  }
}
function TreeNodeComponent_ng_container_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, TreeNodeComponent_ng_container_0_div_1_tree_node_drop_slot_1_Template, 1, 2, "tree-node-drop-slot", 3);
    i0.ɵɵelement(2, "tree-node-wrapper", 4)(3, "tree-node-children", 5)(4, "tree-node-drop-slot", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r0.node.getClass());
    i0.ɵɵclassProp("tree-node", true)("tree-node-expanded", ctx_r0.node.isExpanded && ctx_r0.node.hasChildren)("tree-node-collapsed", ctx_r0.node.isCollapsed && ctx_r0.node.hasChildren)("tree-node-leaf", ctx_r0.node.isLeaf)("tree-node-active", ctx_r0.node.isActive)("tree-node-focused", ctx_r0.node.isFocused);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.index === 0);
    i0.ɵɵadvance();
    i0.ɵɵproperty("node", ctx_r0.node)("index", ctx_r0.index)("templates", ctx_r0.templates);
    i0.ɵɵadvance();
    i0.ɵɵproperty("node", ctx_r0.node)("templates", ctx_r0.templates);
    i0.ɵɵadvance();
    i0.ɵɵproperty("dropIndex", ctx_r0.node.index + 1)("node", ctx_r0.node.parent);
  }
}
function TreeNodeComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TreeNodeComponent_ng_container_0_div_1_Template, 5, 22, "div", 1);
    i0.ɵɵelementContainer(2, 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.templates.treeNodeFullTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.templates.treeNodeFullTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction4(3, _c3, ctx_r0.node, ctx_r0.node, ctx_r0.index, ctx_r0.templates));
  }
}
const _c4 = ["*"];
function TreeViewportComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵprojection(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵstyleProp("height", ctx_r0.getTotalHeight());
  }
}
const _c5 = ["loadingTemplate"];
const _c6 = ["treeNodeTemplate"];
const _c7 = ["treeNodeWrapperTemplate"];
const _c8 = ["treeNodeFullTemplate"];
const _c9 = ["viewport"];
const _c10 = (a0, a1, a2, a3) => ({
  loadingTemplate: a0,
  treeNodeTemplate: a1,
  treeNodeWrapperTemplate: a2,
  treeNodeFullTemplate: a3
});
function TreeComponent_tree_node_collection_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tree-node-collection", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("nodes", ctx_r0.treeModel.roots)("treeModel", ctx_r0.treeModel)("templates", i0.ɵɵpureFunction4(3, _c10, ctx_r0.loadingTemplate, ctx_r0.treeNodeTemplate, ctx_r0.treeNodeWrapperTemplate, ctx_r0.treeNodeFullTemplate));
  }
}
function TreeComponent_tree_node_drop_slot_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tree-node-drop-slot", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("dropIndex", 0)("node", ctx_r0.treeModel.virtualRoot);
  }
}
class TreeMobxAutorunDirective {
  constructor(templateRef, viewContainer) {
    this.templateRef = templateRef;
    this.viewContainer = viewContainer;
    this.templateBindings = {};
  }
  ngOnInit() {
    this.view = this.viewContainer.createEmbeddedView(this.templateRef);
    if (this.dispose) {
      this.dispose();
    }
    if (this.shouldDetach()) {
      this.view.detach();
    }
    this.autoDetect(this.view);
  }
  shouldDetach() {
    return this.treeMobxAutorun && this.treeMobxAutorun.detach;
  }
  autoDetect(view) {
    this.dispose = autorun(() => view.detectChanges());
  }
  ngOnDestroy() {
    if (this.dispose) {
      this.dispose();
    }
  }
  /** @nocollapse */
  static {
    this.ɵfac = function TreeMobxAutorunDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeMobxAutorunDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef));
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TreeMobxAutorunDirective,
      selectors: [["", "treeMobxAutorun", ""]],
      inputs: {
        treeMobxAutorun: "treeMobxAutorun"
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeMobxAutorunDirective, [{
    type: Directive,
    args: [{
      selector: '[treeMobxAutorun]',
      standalone: false
    }]
  }], () => [{
    type: i0.TemplateRef
  }, {
    type: i0.ViewContainerRef
  }], {
    treeMobxAutorun: [{
      type: Input
    }]
  });
})();
const KEYS = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  ENTER: 13,
  SPACE: 32,
  CONTEXT_MENU: 32
};
const TREE_ACTIONS = {
  TOGGLE_ACTIVE: (tree, node, $event) => node && node.toggleActivated(),
  TOGGLE_ACTIVE_MULTI: (tree, node, $event) => node && node.toggleActivated(true),
  TOGGLE_SELECTED: (tree, node, $event) => node && node.toggleSelected(),
  ACTIVATE: (tree, node, $event) => node.setIsActive(true),
  DEACTIVATE: (tree, node, $event) => node.setIsActive(false),
  SELECT: (tree, node, $event) => node.setIsSelected(true),
  DESELECT: (tree, node, $event) => node.setIsSelected(false),
  FOCUS: (tree, node, $event) => node.focus(),
  TOGGLE_EXPANDED: (tree, node, $event) => node.hasChildren && node.toggleExpanded(),
  EXPAND: (tree, node, $event) => node.expand(),
  COLLAPSE: (tree, node, $event) => node.collapse(),
  DRILL_DOWN: (tree, node, $event) => tree.focusDrillDown(),
  DRILL_UP: (tree, node, $event) => tree.focusDrillUp(),
  NEXT_NODE: (tree, node, $event) => tree.focusNextNode(),
  PREVIOUS_NODE: (tree, node, $event) => tree.focusPreviousNode(),
  MOVE_NODE: (tree, node, $event, {
    from,
    to
  }) => {
    // default action assumes from = node, to = {parent, index}
    if ($event.ctrlKey) {
      tree.copyNode(from, to);
    } else {
      tree.moveNode(from, to);
    }
  }
};
const defaultActionMapping = {
  mouse: {
    click: TREE_ACTIONS.TOGGLE_ACTIVE,
    dblClick: null,
    contextMenu: null,
    expanderClick: TREE_ACTIONS.TOGGLE_EXPANDED,
    checkboxClick: TREE_ACTIONS.TOGGLE_SELECTED,
    drop: TREE_ACTIONS.MOVE_NODE
  },
  keys: {
    [KEYS.RIGHT]: TREE_ACTIONS.DRILL_DOWN,
    [KEYS.LEFT]: TREE_ACTIONS.DRILL_UP,
    [KEYS.DOWN]: TREE_ACTIONS.NEXT_NODE,
    [KEYS.UP]: TREE_ACTIONS.PREVIOUS_NODE,
    [KEYS.SPACE]: TREE_ACTIONS.TOGGLE_ACTIVE,
    [KEYS.ENTER]: TREE_ACTIONS.TOGGLE_ACTIVE
  }
};
class TreeOptions {
  get hasChildrenField() {
    return this.options.hasChildrenField || 'hasChildren';
  }
  get childrenField() {
    return this.options.childrenField || 'children';
  }
  get displayField() {
    return this.options.displayField || 'name';
  }
  get idField() {
    return this.options.idField || 'id';
  }
  get isExpandedField() {
    return this.options.isExpandedField || 'isExpanded';
  }
  get getChildren() {
    return this.options.getChildren;
  }
  get levelPadding() {
    return this.options.levelPadding || 0;
  }
  get useVirtualScroll() {
    return this.options.useVirtualScroll;
  }
  get animateExpand() {
    return this.options.animateExpand;
  }
  get animateSpeed() {
    return this.options.animateSpeed || 1;
  }
  get animateAcceleration() {
    return this.options.animateAcceleration || 1.2;
  }
  get scrollOnActivate() {
    return this.options.scrollOnActivate === undefined ? true : this.options.scrollOnActivate;
  }
  get rtl() {
    return !!this.options.rtl;
  }
  get rootId() {
    return this.options.rootId;
  }
  get useCheckbox() {
    return this.options.useCheckbox;
  }
  get useTriState() {
    return this.options.useTriState === undefined ? true : this.options.useTriState;
  }
  get scrollContainer() {
    return this.options.scrollContainer;
  }
  get allowDragoverStyling() {
    return this.options.allowDragoverStyling === undefined ? true : this.options.allowDragoverStyling;
  }
  constructor(options = {}) {
    this.options = options;
    this.actionMapping = {
      mouse: {
        click: this.options?.actionMapping?.mouse?.click ?? defaultActionMapping.mouse.click,
        dblClick: this.options?.actionMapping?.mouse?.dblClick ?? defaultActionMapping.mouse.dblClick,
        contextMenu: this.options?.actionMapping?.mouse?.contextMenu ?? defaultActionMapping.mouse.contextMenu,
        expanderClick: this.options?.actionMapping?.mouse?.expanderClick ?? defaultActionMapping.mouse.expanderClick,
        checkboxClick: this.options?.actionMapping?.mouse?.checkboxClick ?? defaultActionMapping.mouse.checkboxClick,
        drop: this.options?.actionMapping?.mouse?.drop ?? defaultActionMapping.mouse.drop,
        dragStart: this.options?.actionMapping?.mouse?.dragStart ?? undefined,
        drag: this.options?.actionMapping?.mouse?.drag ?? undefined,
        dragEnd: this.options?.actionMapping?.mouse?.dragEnd ?? undefined,
        dragOver: this.options?.actionMapping?.mouse?.dragOver ?? undefined,
        dragLeave: this.options?.actionMapping?.mouse?.dragLeave ?? undefined,
        dragEnter: this.options?.actionMapping?.mouse?.dragEnter ?? undefined,
        mouseOver: this.options?.actionMapping?.mouse?.mouseOver ?? undefined,
        mouseOut: this.options?.actionMapping?.mouse?.mouseOut ?? undefined
      },
      keys: {
        [KEYS.RIGHT]: TREE_ACTIONS.DRILL_DOWN,
        [KEYS.LEFT]: TREE_ACTIONS.DRILL_UP,
        [KEYS.DOWN]: TREE_ACTIONS.NEXT_NODE,
        [KEYS.UP]: TREE_ACTIONS.PREVIOUS_NODE,
        [KEYS.SPACE]: TREE_ACTIONS.TOGGLE_ACTIVE,
        [KEYS.ENTER]: TREE_ACTIONS.TOGGLE_ACTIVE
      }
    };
    if (this.options?.actionMapping?.keys) {
      this.actionMapping.keys = {
        ...this.actionMapping.keys,
        ...this.options.actionMapping.keys
      };
    }
    if (options.rtl) {
      this.actionMapping.keys[KEYS.RIGHT] = options.actionMapping?.keys[KEYS.RIGHT] || TREE_ACTIONS.DRILL_UP;
      this.actionMapping.keys[KEYS.LEFT] = options.actionMapping?.keys[KEYS.LEFT] || TREE_ACTIONS.DRILL_DOWN;
    }
  }
  getNodeClone(node) {
    if (this.options.getNodeClone) {
      return this.options.getNodeClone(node);
    }
    // remove id from clone
    // keeping ie11 compatibility
    const nodeClone = Object.assign({}, node.data);
    if (nodeClone.id) {
      delete nodeClone.id;
    }
    return nodeClone;
  }
  allowDrop(element, to, $event) {
    if (this.options.allowDrop instanceof Function) {
      return this.options.allowDrop(element, to, $event);
    } else {
      return this.options.allowDrop === undefined ? true : this.options.allowDrop;
    }
  }
  allowDrag(node) {
    if (this.options.allowDrag instanceof Function) {
      return this.options.allowDrag(node);
    } else {
      return this.options.allowDrag;
    }
  }
  nodeClass(node) {
    return this.options.nodeClass ? this.options.nodeClass(node) : '';
  }
  nodeHeight(node) {
    if (node.data.virtual) {
      return 0;
    }
    let nodeHeight = this.options.nodeHeight || 22;
    if (typeof nodeHeight === 'function') {
      nodeHeight = nodeHeight(node);
    }
    // account for drop slots:
    return nodeHeight + (node.index === 0 ? 2 : 1) * this.dropSlotHeight;
  }
  get dropSlotHeight() {
    return typeof this.options.dropSlotHeight === 'number' ? this.options.dropSlotHeight : 2;
  }
}
const TREE_EVENTS = {
  toggleExpanded: 'toggleExpanded',
  activate: 'activate',
  deactivate: 'deactivate',
  nodeActivate: 'nodeActivate',
  nodeDeactivate: 'nodeDeactivate',
  select: 'select',
  deselect: 'deselect',
  focus: 'focus',
  blur: 'blur',
  initialized: 'initialized',
  updateData: 'updateData',
  moveNode: 'moveNode',
  copyNode: 'copyNode',
  event: 'event',
  loadNodeChildren: 'loadNodeChildren',
  changeFilter: 'changeFilter',
  stateChange: 'stateChange'
};
var __decorate$3 = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
class TreeNode {
  get isHidden() {
    return this.treeModel.isHidden(this);
  }
  get isExpanded() {
    return this.treeModel.isExpanded(this);
  }
  get isActive() {
    return this.treeModel.isActive(this);
  }
  get isFocused() {
    return this.treeModel.isNodeFocused(this);
  }
  get isSelected() {
    if (this.isSelectable()) {
      return this.treeModel.isSelected(this);
    } else {
      return this.children.some(node => node.isSelected);
    }
  }
  get isAllSelected() {
    if (this.isSelectable()) {
      return this.treeModel.isSelected(this);
    } else {
      return this.children.every(node => node.isAllSelected);
    }
  }
  get isPartiallySelected() {
    return this.isSelected && !this.isAllSelected;
  }
  get level() {
    return this.parent ? this.parent.level + 1 : 0;
  }
  get path() {
    return this.parent ? [...this.parent.path, this.id] : [];
  }
  get elementRef() {
    throw `Element Ref is no longer supported since introducing virtual scroll\n
      You may use a template to obtain a reference to the element`;
  }
  get originalNode() {
    return this._originalNode;
  }
  constructor(data, parent, treeModel, index) {
    this.data = data;
    this.parent = parent;
    this.treeModel = treeModel;
    this.position = 0;
    this.allowDrop = (element, $event) => {
      return this.options.allowDrop(element, {
        parent: this,
        index: 0
      }, $event);
    };
    this.allowDragoverStyling = () => {
      return this.options.allowDragoverStyling;
    };
    if (this.id === undefined || this.id === null) {
      this.id = uuid();
    } // Make sure there's a unique id without overriding existing ids to work with immutable data structures
    this.index = index;
    if (this.getField('children')) {
      this._initChildren();
    }
    this.autoLoadChildren();
  }
  // helper get functions:
  get hasChildren() {
    return !!(this.getField('hasChildren') || this.children && this.children.length > 0);
  }
  get isCollapsed() {
    return !this.isExpanded;
  }
  get isLeaf() {
    return !this.hasChildren;
  }
  get isRoot() {
    return this.parent.data.virtual;
  }
  get realParent() {
    return this.isRoot ? null : this.parent;
  }
  // proxy functions:
  get options() {
    return this.treeModel.options;
  }
  fireEvent(event) {
    this.treeModel.fireEvent(event);
  }
  // field accessors:
  get displayField() {
    return this.getField('display');
  }
  get id() {
    return this.getField('id');
  }
  set id(value) {
    this.setField('id', value);
  }
  getField(key) {
    return this.data[this.options[`${key}Field`]];
  }
  setField(key, value) {
    this.data[this.options[`${key}Field`]] = value;
  }
  // traversing:
  _findAdjacentSibling(steps, skipHidden = false) {
    const siblings = this._getParentsChildren(skipHidden);
    const index = siblings.indexOf(this);
    return siblings.length > index + steps ? siblings[index + steps] : null;
  }
  findNextSibling(skipHidden = false) {
    return this._findAdjacentSibling(+1, skipHidden);
  }
  findPreviousSibling(skipHidden = false) {
    return this._findAdjacentSibling(-1, skipHidden);
  }
  getVisibleChildren() {
    return this.visibleChildren;
  }
  get visibleChildren() {
    return (this.children || []).filter(node => !node.isHidden);
  }
  getFirstChild(skipHidden = false) {
    let children = skipHidden ? this.visibleChildren : this.children;
    return children != null && children.length ? children[0] : null;
  }
  getLastChild(skipHidden = false) {
    let children = skipHidden ? this.visibleChildren : this.children;
    return children != null && children.length ? children[children.length - 1] : null;
  }
  findNextNode(goInside = true, skipHidden = false) {
    return goInside && this.isExpanded && this.getFirstChild(skipHidden) || this.findNextSibling(skipHidden) || this.parent && this.parent.findNextNode(false, skipHidden);
  }
  findPreviousNode(skipHidden = false) {
    let previousSibling = this.findPreviousSibling(skipHidden);
    if (!previousSibling) {
      return this.realParent;
    }
    return previousSibling._getLastOpenDescendant(skipHidden);
  }
  _getLastOpenDescendant(skipHidden = false) {
    const lastChild = this.getLastChild(skipHidden);
    return this.isCollapsed || !lastChild ? this : lastChild._getLastOpenDescendant(skipHidden);
  }
  _getParentsChildren(skipHidden = false) {
    const children = this.parent && (skipHidden ? this.parent.getVisibleChildren() : this.parent.children);
    return children || [];
  }
  getIndexInParent(skipHidden = false) {
    return this._getParentsChildren(skipHidden).indexOf(this);
  }
  isDescendantOf(node) {
    if (this === node) return true;else return this.parent && this.parent.isDescendantOf(node);
  }
  getNodePadding() {
    return this.options.levelPadding * (this.level - 1) + 'px';
  }
  getClass() {
    return [this.options.nodeClass(this), `tree-node-level-${this.level}`].join(' ');
  }
  onDrop($event) {
    this.mouseAction('drop', $event.event, {
      from: $event.element,
      to: {
        parent: this,
        index: 0,
        dropOnNode: true
      }
    });
  }
  allowDrag() {
    return this.options.allowDrag(this);
  }
  // helper methods:
  loadNodeChildren() {
    if (!this.options.getChildren) {
      return Promise.resolve(); // Not getChildren method - for using redux
    }
    return Promise.resolve(this.options.getChildren(this)).then(children => {
      if (children) {
        this.setField('children', children);
        this._initChildren();
        if (this.options.useTriState && this.treeModel.isSelected(this)) {
          this.setIsSelected(true);
        }
        this.children.forEach(child => {
          if (child.getField('isExpanded') && child.hasChildren) {
            child.expand();
          }
        });
      }
    }).then(() => {
      this.fireEvent({
        eventName: TREE_EVENTS.loadNodeChildren,
        node: this
      });
    });
  }
  expand() {
    if (!this.isExpanded) {
      this.toggleExpanded();
    }
    return this;
  }
  collapse() {
    if (this.isExpanded) {
      this.toggleExpanded();
    }
    return this;
  }
  doForAll(fn) {
    Promise.resolve(fn(this)).then(() => {
      if (this.children) {
        this.children.forEach(child => child.doForAll(fn));
      }
    });
  }
  expandAll() {
    this.doForAll(node => node.expand());
  }
  collapseAll() {
    this.doForAll(node => node.collapse());
  }
  ensureVisible() {
    if (this.realParent) {
      this.realParent.expand();
      this.realParent.ensureVisible();
    }
    return this;
  }
  toggleExpanded() {
    this.setIsExpanded(!this.isExpanded);
    return this;
  }
  setIsExpanded(value) {
    if (this.hasChildren) {
      this.treeModel.setExpandedNode(this, value);
    }
    return this;
  }
  autoLoadChildren() {
    this.handler = reaction(() => this.isExpanded, isExpanded => {
      if (!this.children && this.hasChildren && isExpanded) {
        this.loadNodeChildren();
      }
    }, {
      fireImmediately: true
    });
  }
  dispose() {
    if (this.children) {
      this.children.forEach(child => child.dispose());
    }
    if (this.handler) {
      this.handler();
    }
    this.parent = null;
    this.children = null;
  }
  setIsActive(value, multi = false) {
    this.treeModel.setActiveNode(this, value, multi);
    if (value) {
      this.focus(this.options.scrollOnActivate);
    }
    return this;
  }
  isSelectable() {
    return this.isLeaf || !this.children || !this.options.useTriState;
  }
  setIsSelected(value) {
    if (this.isSelectable()) {
      this.treeModel.setSelectedNode(this, value);
    } else {
      this.visibleChildren.forEach(child => child.setIsSelected(value));
    }
    return this;
  }
  toggleSelected() {
    this.setIsSelected(!this.isSelected);
    return this;
  }
  toggleActivated(multi = false) {
    this.setIsActive(!this.isActive, multi);
    return this;
  }
  setActiveAndVisible(multi = false) {
    this.setIsActive(true, multi).ensureVisible();
    setTimeout(this.scrollIntoView.bind(this));
    return this;
  }
  scrollIntoView(force = false) {
    this.treeModel.virtualScroll.scrollIntoView(this, force);
  }
  focus(scroll = true) {
    let previousNode = this.treeModel.getFocusedNode();
    this.treeModel.setFocusedNode(this);
    if (scroll) {
      this.scrollIntoView();
    }
    if (previousNode) {
      this.fireEvent({
        eventName: TREE_EVENTS.blur,
        node: previousNode
      });
    }
    this.fireEvent({
      eventName: TREE_EVENTS.focus,
      node: this
    });
    return this;
  }
  blur() {
    let previousNode = this.treeModel.getFocusedNode();
    this.treeModel.setFocusedNode(null);
    if (previousNode) {
      this.fireEvent({
        eventName: TREE_EVENTS.blur,
        node: this
      });
    }
    return this;
  }
  setIsHidden(value) {
    this.treeModel.setIsHidden(this, value);
  }
  hide() {
    this.setIsHidden(true);
  }
  show() {
    this.setIsHidden(false);
  }
  mouseAction(actionName, $event, data = null) {
    this.treeModel.setFocus(true);
    const actionMapping = this.options.actionMapping.mouse;
    const mouseAction = actionMapping[actionName];
    if (mouseAction) {
      mouseAction(this.treeModel, this, $event, data);
    }
  }
  getSelfHeight() {
    return this.options.nodeHeight(this);
  }
  _initChildren() {
    this.children = this.getField('children').map((c, index) => new TreeNode(c, this, this.treeModel, index));
  }
}
__decorate$3([computed$1], TreeNode.prototype, "isHidden", null);
__decorate$3([computed$1], TreeNode.prototype, "isExpanded", null);
__decorate$3([computed$1], TreeNode.prototype, "isActive", null);
__decorate$3([computed$1], TreeNode.prototype, "isFocused", null);
__decorate$3([computed$1], TreeNode.prototype, "isSelected", null);
__decorate$3([computed$1], TreeNode.prototype, "isAllSelected", null);
__decorate$3([computed$1], TreeNode.prototype, "isPartiallySelected", null);
__decorate$3([observable$1], TreeNode.prototype, "children", void 0);
__decorate$3([observable$1], TreeNode.prototype, "index", void 0);
__decorate$3([observable$1], TreeNode.prototype, "position", void 0);
__decorate$3([observable$1], TreeNode.prototype, "height", void 0);
__decorate$3([computed$1], TreeNode.prototype, "level", null);
__decorate$3([computed$1], TreeNode.prototype, "path", null);
__decorate$3([computed$1], TreeNode.prototype, "visibleChildren", null);
__decorate$3([action$1], TreeNode.prototype, "setIsSelected", null);
__decorate$3([action$1], TreeNode.prototype, "_initChildren", null);
function uuid() {
  return Math.floor(Math.random() * 10000000000000);
}
var __decorate$2 = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
class TreeModel {
  constructor() {
    this.options = new TreeOptions();
    this.eventNames = Object.keys(TREE_EVENTS);
    this.expandedNodeIds = {};
    this.selectedLeafNodeIds = {};
    this.activeNodeIds = {};
    this.hiddenNodeIds = {};
    this.focusedNodeId = null;
    this.firstUpdate = true;
    this.subscriptions = [];
  }
  static {
    this.focusedTree = null;
  }
  // events
  fireEvent(event) {
    event.treeModel = this;
    this.events[event.eventName].emit(event);
    this.events.event.emit(event);
  }
  subscribe(eventName, fn) {
    const subscription = this.events[eventName].subscribe(fn);
    this.subscriptions.push(subscription);
  }
  // getters
  getFocusedNode() {
    return this.focusedNode;
  }
  getActiveNode() {
    return this.activeNodes[0];
  }
  getActiveNodes() {
    return this.activeNodes;
  }
  getVisibleRoots() {
    return this.virtualRoot.visibleChildren;
  }
  getFirstRoot(skipHidden = false) {
    const root = skipHidden ? this.getVisibleRoots() : this.roots;
    return root != null && root.length ? root[0] : null;
  }
  getLastRoot(skipHidden = false) {
    const root = skipHidden ? this.getVisibleRoots() : this.roots;
    return root != null && root.length ? root[root.length - 1] : null;
  }
  get isFocused() {
    return TreeModel.focusedTree === this;
  }
  isNodeFocused(node) {
    return this.focusedNode === node;
  }
  isEmptyTree() {
    return this.roots && this.roots.length === 0;
  }
  get focusedNode() {
    return this.focusedNodeId ? this.getNodeById(this.focusedNodeId) : null;
  }
  get expandedNodes() {
    const nodes = Object.keys(this.expandedNodeIds).filter(id => this.expandedNodeIds[id]).map(id => this.getNodeById(id));
    return nodes.filter(Boolean);
  }
  get activeNodes() {
    const nodes = Object.keys(this.activeNodeIds).filter(id => this.activeNodeIds[id]).map(id => this.getNodeById(id));
    return nodes.filter(Boolean);
  }
  get hiddenNodes() {
    const nodes = Object.keys(this.hiddenNodeIds).filter(id => this.hiddenNodeIds[id]).map(id => this.getNodeById(id));
    return nodes.filter(Boolean);
  }
  get selectedLeafNodes() {
    const nodes = Object.keys(this.selectedLeafNodeIds).filter(id => this.selectedLeafNodeIds[id]).map(id => this.getNodeById(id));
    return nodes.filter(Boolean);
  }
  // locating nodes
  getNodeByPath(path, startNode = null) {
    if (!path) return null;
    startNode = startNode || this.virtualRoot;
    if (path.length === 0) return startNode;
    if (!startNode.children) return null;
    const childId = path.shift();
    const childNode = startNode.children.find(c => c.id === childId);
    if (!childNode) return null;
    return this.getNodeByPath(path, childNode);
  }
  getNodeById(id) {
    const idStr = id.toString();
    return this.getNodeBy(node => node.id.toString() === idStr);
  }
  getNodeBy(predicate, startNode = null) {
    startNode = startNode || this.virtualRoot;
    if (!startNode.children) return null;
    const found = startNode.children.find(predicate);
    if (found) {
      // found in children
      return found;
    } else {
      // look in children's children
      for (let child of startNode.children) {
        const foundInChildren = this.getNodeBy(predicate, child);
        if (foundInChildren) return foundInChildren;
      }
    }
  }
  isExpanded(node) {
    return this.expandedNodeIds[node.id];
  }
  isHidden(node) {
    return this.hiddenNodeIds[node.id];
  }
  isActive(node) {
    return this.activeNodeIds[node.id];
  }
  isSelected(node) {
    return this.selectedLeafNodeIds[node.id];
  }
  ngOnDestroy() {
    this.dispose();
    this.unsubscribeAll();
  }
  dispose() {
    // Dispose reactions of the replaced nodes
    if (this.virtualRoot) {
      this.virtualRoot.dispose();
    }
  }
  unsubscribeAll() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }
  // actions
  setData({
    nodes,
    options = null,
    events = null
  }) {
    if (options) {
      this.options = new TreeOptions(options);
    }
    if (events) {
      this.events = events;
    }
    if (nodes) {
      this.nodes = nodes;
    }
    this.update();
  }
  update() {
    // Rebuild tree:
    let virtualRootConfig = {
      id: this.options.rootId,
      virtual: true,
      [this.options.childrenField]: this.nodes
    };
    this.dispose();
    this.virtualRoot = new TreeNode(virtualRootConfig, null, this, 0);
    this.roots = this.virtualRoot.children;
    // Fire event:
    if (this.firstUpdate) {
      if (this.roots) {
        this.firstUpdate = false;
        this._calculateExpandedNodes();
      }
    } else {
      this.fireEvent({
        eventName: TREE_EVENTS.updateData
      });
    }
  }
  setFocusedNode(node) {
    this.focusedNodeId = node ? node.id : null;
  }
  setFocus(value) {
    TreeModel.focusedTree = value ? this : null;
  }
  doForAll(fn) {
    this.roots.forEach(root => root.doForAll(fn));
  }
  focusNextNode() {
    let previousNode = this.getFocusedNode();
    let nextNode = previousNode ? previousNode.findNextNode(true, true) : this.getFirstRoot(true);
    if (nextNode) nextNode.focus();
  }
  focusPreviousNode() {
    let previousNode = this.getFocusedNode();
    let nextNode = previousNode ? previousNode.findPreviousNode(true) : this.getLastRoot(true);
    if (nextNode) nextNode.focus();
  }
  focusDrillDown() {
    let previousNode = this.getFocusedNode();
    if (previousNode && previousNode.isCollapsed && previousNode.hasChildren) {
      previousNode.toggleExpanded();
    } else {
      let nextNode = previousNode ? previousNode.getFirstChild(true) : this.getFirstRoot(true);
      if (nextNode) nextNode.focus();
    }
  }
  focusDrillUp() {
    let previousNode = this.getFocusedNode();
    if (!previousNode) return;
    if (previousNode.isExpanded) {
      previousNode.toggleExpanded();
    } else {
      let nextNode = previousNode.realParent;
      if (nextNode) nextNode.focus();
    }
  }
  setActiveNode(node, value, multi = false) {
    if (multi) {
      this._setActiveNodeMulti(node, value);
    } else {
      this._setActiveNodeSingle(node, value);
    }
    if (value) {
      node.focus(this.options.scrollOnActivate);
      this.fireEvent({
        eventName: TREE_EVENTS.activate,
        node
      });
      this.fireEvent({
        eventName: TREE_EVENTS.nodeActivate,
        node
      }); // For IE11
    } else {
      this.fireEvent({
        eventName: TREE_EVENTS.deactivate,
        node
      });
      this.fireEvent({
        eventName: TREE_EVENTS.nodeDeactivate,
        node
      }); // For IE11
    }
  }
  setSelectedNode(node, value) {
    this.selectedLeafNodeIds = Object.assign({}, this.selectedLeafNodeIds, {
      [node.id]: value
    });
    if (value) {
      node.focus();
      this.fireEvent({
        eventName: TREE_EVENTS.select,
        node
      });
    } else {
      this.fireEvent({
        eventName: TREE_EVENTS.deselect,
        node
      });
    }
  }
  setExpandedNode(node, value) {
    this.expandedNodeIds = Object.assign({}, this.expandedNodeIds, {
      [node.id]: value
    });
    this.fireEvent({
      eventName: TREE_EVENTS.toggleExpanded,
      node,
      isExpanded: value
    });
  }
  expandAll() {
    this.roots.forEach(root => root.expandAll());
  }
  collapseAll() {
    this.roots.forEach(root => root.collapseAll());
  }
  setIsHidden(node, value) {
    this.hiddenNodeIds = Object.assign({}, this.hiddenNodeIds, {
      [node.id]: value
    });
  }
  setHiddenNodeIds(nodeIds) {
    this.hiddenNodeIds = nodeIds.reduce((hiddenNodeIds, id) => Object.assign(hiddenNodeIds, {
      [id]: true
    }), {});
  }
  performKeyAction(node, $event) {
    const keyAction = this.options.actionMapping.keys[$event.keyCode];
    if (keyAction) {
      $event.preventDefault();
      keyAction(this, node, $event);
      return true;
    } else {
      return false;
    }
  }
  filterNodes(filter, autoShow = true) {
    let filterFn;
    if (!filter) {
      return this.clearFilter();
    }
    // support function and string filter
    if (filter && typeof filter.valueOf() === 'string') {
      filterFn = node => node.displayField.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
    } else if (filter && typeof filter === 'function') {
      filterFn = filter;
    } else {
      console.error('Don\'t know what to do with filter', filter);
      console.error('Should be either a string or function');
      return;
    }
    const ids = {};
    this.roots.forEach(node => this._filterNode(ids, node, filterFn, autoShow));
    this.hiddenNodeIds = ids;
    this.fireEvent({
      eventName: TREE_EVENTS.changeFilter
    });
  }
  clearFilter() {
    this.hiddenNodeIds = {};
    this.fireEvent({
      eventName: TREE_EVENTS.changeFilter
    });
  }
  moveNode(node, to) {
    const fromIndex = node.getIndexInParent();
    const fromParent = node.parent;
    if (!this.canMoveNode(node, to, fromIndex)) return;
    const fromChildren = fromParent.getField('children');
    // If node doesn't have children - create children array
    if (!to.parent.getField('children')) {
      to.parent.setField('children', []);
    }
    const toChildren = to.parent.getField('children');
    const originalNode = fromChildren.splice(fromIndex, 1)[0];
    // Compensate for index if already removed from parent:
    let toIndex = fromParent === to.parent && to.index > fromIndex ? to.index - 1 : to.index;
    toChildren.splice(toIndex, 0, originalNode);
    fromParent.treeModel.update();
    if (to.parent.treeModel !== fromParent.treeModel) {
      to.parent.treeModel.update();
    }
    this.fireEvent({
      eventName: TREE_EVENTS.moveNode,
      node: originalNode,
      to: {
        parent: to.parent.data,
        index: toIndex
      },
      from: {
        parent: fromParent.data,
        index: fromIndex
      }
    });
  }
  copyNode(node, to) {
    const fromIndex = node.getIndexInParent();
    if (!this.canMoveNode(node, to, fromIndex)) return;
    // If node doesn't have children - create children array
    if (!to.parent.getField('children')) {
      to.parent.setField('children', []);
    }
    const toChildren = to.parent.getField('children');
    const nodeCopy = this.options.getNodeClone(node);
    toChildren.splice(to.index, 0, nodeCopy);
    node.treeModel.update();
    if (to.parent.treeModel !== node.treeModel) {
      to.parent.treeModel.update();
    }
    this.fireEvent({
      eventName: TREE_EVENTS.copyNode,
      node: nodeCopy,
      to: {
        parent: to.parent.data,
        index: to.index
      }
    });
  }
  getState() {
    return {
      expandedNodeIds: this.expandedNodeIds,
      selectedLeafNodeIds: this.selectedLeafNodeIds,
      activeNodeIds: this.activeNodeIds,
      hiddenNodeIds: this.hiddenNodeIds,
      focusedNodeId: this.focusedNodeId
    };
  }
  setState(state) {
    if (!state) return;
    Object.assign(this, {
      expandedNodeIds: state.expandedNodeIds || {},
      selectedLeafNodeIds: state.selectedLeafNodeIds || {},
      activeNodeIds: state.activeNodeIds || {},
      hiddenNodeIds: state.hiddenNodeIds || {},
      focusedNodeId: state.focusedNodeId
    });
  }
  subscribeToState(fn) {
    autorun(() => fn(this.getState()));
  }
  canMoveNode(node, to, fromIndex = undefined) {
    const fromNodeIndex = fromIndex || node.getIndexInParent();
    // same node:
    if (node.parent === to.parent && fromIndex === to.index) {
      return false;
    }
    return !to.parent.isDescendantOf(node);
  }
  calculateExpandedNodes() {
    this._calculateExpandedNodes();
  }
  // private methods
  _filterNode(ids, node, filterFn, autoShow) {
    // if node passes function then it's visible
    let isVisible = filterFn(node);
    if (node.children) {
      // if one of node's children passes filter then this node is also visible
      node.children.forEach(child => {
        if (this._filterNode(ids, child, filterFn, autoShow)) {
          isVisible = true;
        }
      });
    }
    // mark node as hidden
    if (!isVisible) {
      ids[node.id] = true;
    }
    // auto expand parents to make sure the filtered nodes are visible
    if (autoShow && isVisible) {
      node.ensureVisible();
    }
    return isVisible;
  }
  _calculateExpandedNodes(startNode = null) {
    startNode = startNode || this.virtualRoot;
    if (startNode.data[this.options.isExpandedField]) {
      this.expandedNodeIds = Object.assign({}, this.expandedNodeIds, {
        [startNode.id]: true
      });
    }
    if (startNode.children) {
      startNode.children.forEach(child => this._calculateExpandedNodes(child));
    }
  }
  _setActiveNodeSingle(node, value) {
    // Deactivate all other nodes:
    this.activeNodes.filter(activeNode => activeNode !== node).forEach(activeNode => {
      this.fireEvent({
        eventName: TREE_EVENTS.deactivate,
        node: activeNode
      });
      this.fireEvent({
        eventName: TREE_EVENTS.nodeDeactivate,
        node: activeNode
      }); // For IE11
    });
    if (value) {
      this.activeNodeIds = {
        [node.id]: true
      };
    } else {
      this.activeNodeIds = {};
    }
  }
  _setActiveNodeMulti(node, value) {
    this.activeNodeIds = Object.assign({}, this.activeNodeIds, {
      [node.id]: value
    });
  }
  /** @nocollapse */
  static {
    this.ɵfac = function TreeModel_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeModel)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TreeModel,
      factory: TreeModel.ɵfac
    });
  }
}
__decorate$2([observable$1], TreeModel.prototype, "roots", void 0);
__decorate$2([observable$1], TreeModel.prototype, "expandedNodeIds", void 0);
__decorate$2([observable$1], TreeModel.prototype, "selectedLeafNodeIds", void 0);
__decorate$2([observable$1], TreeModel.prototype, "activeNodeIds", void 0);
__decorate$2([observable$1], TreeModel.prototype, "hiddenNodeIds", void 0);
__decorate$2([observable$1], TreeModel.prototype, "focusedNodeId", void 0);
__decorate$2([observable$1], TreeModel.prototype, "virtualRoot", void 0);
__decorate$2([computed$1], TreeModel.prototype, "focusedNode", null);
__decorate$2([computed$1], TreeModel.prototype, "expandedNodes", null);
__decorate$2([computed$1], TreeModel.prototype, "activeNodes", null);
__decorate$2([computed$1], TreeModel.prototype, "hiddenNodes", null);
__decorate$2([computed$1], TreeModel.prototype, "selectedLeafNodes", null);
__decorate$2([action$1], TreeModel.prototype, "setData", null);
__decorate$2([action$1], TreeModel.prototype, "update", null);
__decorate$2([action$1], TreeModel.prototype, "setFocusedNode", null);
__decorate$2([action$1], TreeModel.prototype, "setFocus", null);
__decorate$2([action$1], TreeModel.prototype, "doForAll", null);
__decorate$2([action$1], TreeModel.prototype, "focusNextNode", null);
__decorate$2([action$1], TreeModel.prototype, "focusPreviousNode", null);
__decorate$2([action$1], TreeModel.prototype, "focusDrillDown", null);
__decorate$2([action$1], TreeModel.prototype, "focusDrillUp", null);
__decorate$2([action$1], TreeModel.prototype, "setActiveNode", null);
__decorate$2([action$1], TreeModel.prototype, "setSelectedNode", null);
__decorate$2([action$1], TreeModel.prototype, "setExpandedNode", null);
__decorate$2([action$1], TreeModel.prototype, "expandAll", null);
__decorate$2([action$1], TreeModel.prototype, "collapseAll", null);
__decorate$2([action$1], TreeModel.prototype, "setIsHidden", null);
__decorate$2([action$1], TreeModel.prototype, "setHiddenNodeIds", null);
__decorate$2([action$1], TreeModel.prototype, "filterNodes", null);
__decorate$2([action$1], TreeModel.prototype, "clearFilter", null);
__decorate$2([action$1], TreeModel.prototype, "moveNode", null);
__decorate$2([action$1], TreeModel.prototype, "copyNode", null);
__decorate$2([action$1], TreeModel.prototype, "setState", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeModel, [{
    type: Injectable
  }], null, {
    roots: [],
    expandedNodeIds: [],
    selectedLeafNodeIds: [],
    activeNodeIds: [],
    hiddenNodeIds: [],
    focusedNodeId: [],
    virtualRoot: [],
    focusedNode: [],
    expandedNodes: [],
    activeNodes: [],
    hiddenNodes: [],
    selectedLeafNodes: [],
    setData: [],
    update: [],
    setFocusedNode: [],
    setFocus: [],
    doForAll: [],
    focusNextNode: [],
    focusPreviousNode: [],
    focusDrillDown: [],
    focusDrillUp: [],
    setActiveNode: [],
    setSelectedNode: [],
    setExpandedNode: [],
    expandAll: [],
    collapseAll: [],
    setIsHidden: [],
    setHiddenNodeIds: [],
    filterNodes: [],
    clearFilter: [],
    moveNode: [],
    copyNode: [],
    setState: []
  });
})();
class TreeDraggedElement {
  constructor() {
    this._draggedElement = null;
  }
  set(draggedElement) {
    this._draggedElement = draggedElement;
  }
  get() {
    return this._draggedElement;
  }
  isDragging() {
    return !!this.get();
  }
  /** @nocollapse */
  static {
    this.ɵfac = function TreeDraggedElement_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeDraggedElement)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TreeDraggedElement,
      factory: TreeDraggedElement.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeDraggedElement, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
var __decorate$1 = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const Y_OFFSET = 500; // Extra pixels outside the viewport, in each direction, to render nodes in
const Y_EPSILON = 150; // Minimum pixel change required to recalculate the rendered nodes
class TreeVirtualScroll {
  get y() {
    return this.yBlocks * Y_EPSILON;
  }
  get totalHeight() {
    return this.treeModel.virtualRoot ? this.treeModel.virtualRoot.height : 0;
  }
  constructor(treeModel) {
    this.treeModel = treeModel;
    this.yBlocks = 0;
    this.x = 0;
    this.viewportHeight = null;
    this.viewport = null;
    treeModel.virtualScroll = this;
    this._dispose = [autorun(() => this.fixScroll())];
  }
  fireEvent(event) {
    this.treeModel.fireEvent(event);
  }
  init() {
    const fn = this.recalcPositions.bind(this);
    fn();
    this._dispose = [...this._dispose, reaction(() => this.treeModel.roots, fn), reaction(() => this.treeModel.expandedNodeIds, fn), reaction(() => this.treeModel.hiddenNodeIds, fn)];
    this.treeModel.subscribe(TREE_EVENTS.loadNodeChildren, fn);
  }
  isEnabled() {
    return this.treeModel.options.useVirtualScroll;
  }
  _setYBlocks(value) {
    this.yBlocks = value;
  }
  recalcPositions() {
    this.treeModel.virtualRoot.height = this._getPositionAfter(this.treeModel.getVisibleRoots(), 0);
  }
  _getPositionAfter(nodes, startPos) {
    let position = startPos;
    nodes.forEach(node => {
      node.position = position;
      position = this._getPositionAfterNode(node, position);
    });
    return position;
  }
  _getPositionAfterNode(node, startPos) {
    let position = node.getSelfHeight() + startPos;
    if (node.children && node.isExpanded) {
      // TBD: consider loading component as well
      position = this._getPositionAfter(node.visibleChildren, position);
    }
    node.height = position - startPos;
    return position;
  }
  clear() {
    this._dispose.forEach(d => d());
  }
  setViewport(viewport) {
    Object.assign(this, {
      viewport,
      x: viewport.scrollLeft,
      yBlocks: Math.round(viewport.scrollTop / Y_EPSILON),
      viewportHeight: viewport.getBoundingClientRect ? viewport.getBoundingClientRect().height : 0
    });
  }
  scrollIntoView(node, force, scrollToMiddle = true) {
    if (node.options.scrollContainer) {
      const scrollContainer = node.options.scrollContainer;
      const scrollContainerHeight = scrollContainer.getBoundingClientRect().height;
      const scrollContainerTop = scrollContainer.getBoundingClientRect().top;
      const nodeTop = this.viewport.getBoundingClientRect().top + node.position - scrollContainerTop;
      if (force ||
      // force scroll to node
      nodeTop < scrollContainer.scrollTop ||
      // node is above scroll container
      nodeTop + node.getSelfHeight() > scrollContainer.scrollTop + scrollContainerHeight) {
        // node is below container
        scrollContainer.scrollTop = scrollToMiddle ? nodeTop - scrollContainerHeight / 2 :
        // scroll to middle
        nodeTop; // scroll to start
      }
    } else {
      if (force ||
      // force scroll to node
      node.position < this.y ||
      // node is above viewport
      node.position + node.getSelfHeight() > this.y + this.viewportHeight) {
        // node is below viewport
        if (this.viewport) {
          this.viewport.scrollTop = scrollToMiddle ? node.position - this.viewportHeight / 2 :
          // scroll to middle
          node.position; // scroll to start
          this._setYBlocks(Math.floor(this.viewport.scrollTop / Y_EPSILON));
        }
      }
    }
  }
  getViewportNodes(nodes) {
    if (!nodes) return [];
    const visibleNodes = nodes.filter(node => !node.isHidden);
    if (!this.isEnabled()) return visibleNodes;
    if (!this.viewportHeight || !visibleNodes.length) return [];
    // When loading children async this method is called before their height and position is calculated.
    // In that case firstIndex === 0 and lastIndex === visibleNodes.length - 1 (e.g. 1000),
    // which means that it loops through every visibleNodes item and push them into viewportNodes array.
    // We can prevent nodes from being pushed to the array and wait for the appropriate calculations to take place
    const lastVisibleNode = visibleNodes.slice(-1)[0];
    if (!lastVisibleNode.height && lastVisibleNode.position === 0) return [];
    // Search for first node in the viewport using binary search
    // Look for first node that starts after the beginning of the viewport (with buffer)
    // Or that ends after the beginning of the viewport
    const firstIndex = binarySearch(visibleNodes, node => {
      return node.position + Y_OFFSET > this.y || node.position + node.height > this.y;
    });
    // Search for last node in the viewport using binary search
    // Look for first node that starts after the end of the viewport (with buffer)
    const lastIndex = binarySearch(visibleNodes, node => {
      return node.position - Y_OFFSET > this.y + this.viewportHeight;
    }, firstIndex);
    const viewportNodes = [];
    for (let i = firstIndex; i <= lastIndex; i++) {
      viewportNodes.push(visibleNodes[i]);
    }
    return viewportNodes;
  }
  fixScroll() {
    const maxY = Math.max(0, this.totalHeight - this.viewportHeight);
    if (this.y < 0) this._setYBlocks(0);
    if (this.y > maxY) this._setYBlocks(maxY / Y_EPSILON);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function TreeVirtualScroll_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeVirtualScroll)(i0.ɵɵinject(TreeModel));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TreeVirtualScroll,
      factory: TreeVirtualScroll.ɵfac
    });
  }
}
__decorate$1([observable$1], TreeVirtualScroll.prototype, "yBlocks", void 0);
__decorate$1([observable$1], TreeVirtualScroll.prototype, "x", void 0);
__decorate$1([observable$1], TreeVirtualScroll.prototype, "viewportHeight", void 0);
__decorate$1([computed$1], TreeVirtualScroll.prototype, "y", null);
__decorate$1([computed$1], TreeVirtualScroll.prototype, "totalHeight", null);
__decorate$1([action$1], TreeVirtualScroll.prototype, "_setYBlocks", null);
__decorate$1([action$1], TreeVirtualScroll.prototype, "recalcPositions", null);
__decorate$1([action$1], TreeVirtualScroll.prototype, "setViewport", null);
__decorate$1([action$1], TreeVirtualScroll.prototype, "scrollIntoView", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeVirtualScroll, [{
    type: Injectable
  }], () => [{
    type: TreeModel
  }], {
    yBlocks: [],
    x: [],
    viewportHeight: [],
    y: [],
    totalHeight: [],
    _setYBlocks: [],
    recalcPositions: [],
    setViewport: [],
    scrollIntoView: []
  });
})();
function binarySearch(nodes, condition, firstIndex = 0) {
  let index = firstIndex;
  let toIndex = nodes.length - 1;
  while (index !== toIndex) {
    let midIndex = Math.floor((index + toIndex) / 2);
    if (condition(nodes[midIndex])) {
      toIndex = midIndex;
    } else {
      if (index === midIndex) index = toIndex;else index = midIndex;
    }
  }
  return index;
}
class LoadingComponent {
  /** @nocollapse */static {
    this.ɵfac = function LoadingComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoadingComponent)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LoadingComponent,
      selectors: [["tree-loading-component"]],
      inputs: {
        template: "template",
        node: "node"
      },
      standalone: false,
      decls: 2,
      vars: 5,
      consts: [[4, "ngIf"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
      template: function LoadingComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, LoadingComponent_span_0_Template, 2, 0, "span", 0);
          i0.ɵɵelementContainer(1, 1);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", !ctx.template);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngTemplateOutlet", ctx.template)("ngTemplateOutletContext", i0.ɵɵpureFunction1(3, _c0, ctx.node));
        }
      },
      dependencies: [i1.NgIf, i1.NgTemplateOutlet],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingComponent, [{
    type: Component,
    args: [{
      encapsulation: ViewEncapsulation.None,
      selector: 'tree-loading-component',
      template: `
    <span *ngIf="!template">loading...</span>
    <ng-container
      [ngTemplateOutlet]="template"
      [ngTemplateOutletContext]="{ $implicit: node }">
    </ng-container>
  `,
      standalone: false
    }]
  }], null, {
    template: [{
      type: Input
    }],
    node: [{
      type: Input
    }]
  });
})();
const DRAG_OVER_CLASS$1 = 'is-dragging-over';
const DRAG_DISABLED_CLASS = 'is-dragging-over-disabled';
class TreeDropDirective {
  set treeAllowDrop(allowDrop) {
    if (allowDrop instanceof Function) {
      this._allowDrop = allowDrop;
    } else this._allowDrop = (element, $event) => allowDrop;
  }
  allowDrop($event) {
    return this._allowDrop(this.treeDraggedElement.get(), $event);
  }
  constructor(el, renderer, treeDraggedElement, ngZone) {
    this.el = el;
    this.renderer = renderer;
    this.treeDraggedElement = treeDraggedElement;
    this.ngZone = ngZone;
    this.allowDragoverStyling = true;
    this.onDropCallback = new EventEmitter();
    this.onDragOverCallback = new EventEmitter();
    this.onDragLeaveCallback = new EventEmitter();
    this.onDragEnterCallback = new EventEmitter();
    this._allowDrop = (element, $event) => true;
    this.dragOverEventHandler = this.onDragOver.bind(this);
    this.dragEnterEventHandler = this.onDragEnter.bind(this);
    this.dragLeaveEventHandler = this.onDragLeave.bind(this);
  }
  ngAfterViewInit() {
    let el = this.el.nativeElement;
    this.ngZone.runOutsideAngular(() => {
      el.addEventListener('dragover', this.dragOverEventHandler);
      el.addEventListener('dragenter', this.dragEnterEventHandler);
      el.addEventListener('dragleave', this.dragLeaveEventHandler);
    });
  }
  ngOnDestroy() {
    let el = this.el.nativeElement;
    el.removeEventListener('dragover', this.dragOverEventHandler);
    el.removeEventListener('dragenter', this.dragEnterEventHandler);
    el.removeEventListener('dragleave', this.dragLeaveEventHandler);
  }
  onDragOver($event) {
    if (!this.allowDrop($event)) {
      if (this.allowDragoverStyling) {
        return this.addDisabledClass();
      }
      return;
    }
    this.onDragOverCallback.emit({
      event: $event,
      element: this.treeDraggedElement.get()
    });
    $event.preventDefault();
    if (this.allowDragoverStyling) {
      this.addClass();
    }
  }
  onDragEnter($event) {
    if (!this.allowDrop($event)) return;
    $event.preventDefault();
    this.onDragEnterCallback.emit({
      event: $event,
      element: this.treeDraggedElement.get()
    });
  }
  onDragLeave($event) {
    if (!this.allowDrop($event)) {
      if (this.allowDragoverStyling) {
        return this.removeDisabledClass();
      }
      return;
    }
    this.onDragLeaveCallback.emit({
      event: $event,
      element: this.treeDraggedElement.get()
    });
    if (this.allowDragoverStyling) {
      this.removeClass();
    }
  }
  onDrop($event) {
    if (!this.allowDrop($event)) return;
    $event.preventDefault();
    this.onDropCallback.emit({
      event: $event,
      element: this.treeDraggedElement.get()
    });
    if (this.allowDragoverStyling) {
      this.removeClass();
    }
    this.treeDraggedElement.set(null);
  }
  addClass() {
    this.renderer.addClass(this.el.nativeElement, DRAG_OVER_CLASS$1);
  }
  removeClass() {
    this.renderer.removeClass(this.el.nativeElement, DRAG_OVER_CLASS$1);
  }
  addDisabledClass() {
    this.renderer.addClass(this.el.nativeElement, DRAG_DISABLED_CLASS);
  }
  removeDisabledClass() {
    this.renderer.removeClass(this.el.nativeElement, DRAG_DISABLED_CLASS);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function TreeDropDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeDropDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(TreeDraggedElement), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TreeDropDirective,
      selectors: [["", "treeDrop", ""]],
      hostBindings: function TreeDropDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("drop", function TreeDropDirective_drop_HostBindingHandler($event) {
            return ctx.onDrop($event);
          });
        }
      },
      inputs: {
        allowDragoverStyling: "allowDragoverStyling",
        treeAllowDrop: "treeAllowDrop"
      },
      outputs: {
        onDropCallback: "treeDrop",
        onDragOverCallback: "treeDropDragOver",
        onDragLeaveCallback: "treeDropDragLeave",
        onDragEnterCallback: "treeDropDragEnter"
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeDropDirective, [{
    type: Directive,
    args: [{
      selector: '[treeDrop]',
      standalone: false
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: TreeDraggedElement
  }, {
    type: i0.NgZone
  }], {
    allowDragoverStyling: [{
      type: Input
    }],
    onDropCallback: [{
      type: Output,
      args: ['treeDrop']
    }],
    onDragOverCallback: [{
      type: Output,
      args: ['treeDropDragOver']
    }],
    onDragLeaveCallback: [{
      type: Output,
      args: ['treeDropDragLeave']
    }],
    onDragEnterCallback: [{
      type: Output,
      args: ['treeDropDragEnter']
    }],
    treeAllowDrop: [{
      type: Input
    }],
    onDrop: [{
      type: HostListener,
      args: ['drop', ['$event']]
    }]
  });
})();
class TreeNodeDropSlot {
  onDrop($event) {
    this.node.mouseAction('drop', $event.event, {
      from: $event.element,
      to: {
        parent: this.node,
        index: this.dropIndex
      }
    });
  }
  allowDrop(element, $event) {
    return this.node.options.allowDrop(element, {
      parent: this.node,
      index: this.dropIndex
    }, $event);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function TreeNodeDropSlot_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeNodeDropSlot)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TreeNodeDropSlot,
      selectors: [["TreeNodeDropSlot"], ["tree-node-drop-slot"]],
      inputs: {
        node: "node",
        dropIndex: "dropIndex"
      },
      standalone: false,
      decls: 1,
      vars: 2,
      consts: [[1, "node-drop-slot", 3, "treeDrop", "treeAllowDrop", "allowDragoverStyling"]],
      template: function TreeNodeDropSlot_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵlistener("treeDrop", function TreeNodeDropSlot_Template_div_treeDrop_0_listener($event) {
            return ctx.onDrop($event);
          });
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("treeAllowDrop", ctx.allowDrop.bind(ctx))("allowDragoverStyling", true);
        }
      },
      dependencies: [TreeDropDirective],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeNodeDropSlot, [{
    type: Component,
    args: [{
      selector: 'TreeNodeDropSlot, tree-node-drop-slot',
      encapsulation: ViewEncapsulation.None,
      template: `
    <div
      class="node-drop-slot"
      (treeDrop)="onDrop($event)"
      [treeAllowDrop]="allowDrop.bind(this)"
      [allowDragoverStyling]="true">
    </div>
  `,
      standalone: false
    }]
  }], null, {
    node: [{
      type: Input
    }],
    dropIndex: [{
      type: Input
    }]
  });
})();

// Re-export mobx operators to be able to use inside components with AOT:
function actionInternal(...args) {
  return action$1(...args);
}
const action = Object.assign(actionInternal, action$1);
function computedInternal(...args) {
  return computed$1(...args);
}
const computed = Object.assign(computedInternal, computed$1);
function observableInternal(...args) {
  return observable$1(...args);
}
const observable = Object.assign(observableInternal, observable$1);
const EASE_ACCELERATION = 1.005;
class TreeAnimateOpenDirective {
  set isOpen(value) {
    if (value) {
      this._show();
      if (this.isEnabled && this._isOpen === false) {
        this._animateOpen();
      }
    } else {
      this.isEnabled ? this._animateClose() : this._hide();
    }
    this._isOpen = !!value;
  }
  constructor(renderer, templateRef, viewContainerRef) {
    this.renderer = renderer;
    this.templateRef = templateRef;
    this.viewContainerRef = viewContainerRef;
  }
  _show() {
    if (this.innerElement) return;
    // create child view
    this.innerElement = this.viewContainerRef.createEmbeddedView(this.templateRef).rootNodes[0];
  }
  _hide() {
    this.viewContainerRef.clear();
    this.innerElement = null;
  }
  _animateOpen() {
    let delta = this.animateSpeed;
    let ease = this.animateAcceleration;
    let maxHeight = 0;
    // set height to 0
    this.renderer.setStyle(this.innerElement, 'max-height', `0`);
    // increase maxHeight until height doesn't change
    setTimeout(() => {
      const i = setInterval(() => {
        if (!this._isOpen || !this.innerElement) return clearInterval(i);
        maxHeight += delta;
        const roundedMaxHeight = Math.round(maxHeight);
        this.renderer.setStyle(this.innerElement, 'max-height', `${roundedMaxHeight}px`);
        const height = this.innerElement.getBoundingClientRect ? this.innerElement.getBoundingClientRect().height : 0; // TBD use renderer
        delta *= ease;
        ease *= EASE_ACCELERATION;
        if (height < roundedMaxHeight) {
          // Make maxHeight auto because animation finished and container might change height later on
          this.renderer.setStyle(this.innerElement, 'max-height', null);
          clearInterval(i);
        }
      }, 17);
    });
  }
  _animateClose() {
    if (!this.innerElement) return;
    let delta = this.animateSpeed;
    let ease = this.animateAcceleration;
    let height = this.innerElement.getBoundingClientRect().height; // TBD use renderer
    // slowly decrease maxHeight to 0, starting from current height
    const i = setInterval(() => {
      if (this._isOpen || !this.innerElement) return clearInterval(i);
      height -= delta;
      this.renderer.setStyle(this.innerElement, 'max-height', `${height}px`);
      delta *= ease;
      ease *= EASE_ACCELERATION;
      if (height <= 0) {
        // after animation complete - remove child element
        this.viewContainerRef.clear();
        this.innerElement = null;
        clearInterval(i);
      }
    }, 17);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function TreeAnimateOpenDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeAnimateOpenDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef));
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TreeAnimateOpenDirective,
      selectors: [["", "treeAnimateOpen", ""]],
      inputs: {
        animateSpeed: [0, "treeAnimateOpenSpeed", "animateSpeed"],
        animateAcceleration: [0, "treeAnimateOpenAcceleration", "animateAcceleration"],
        isEnabled: [0, "treeAnimateOpenEnabled", "isEnabled"],
        isOpen: [0, "treeAnimateOpen", "isOpen"]
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeAnimateOpenDirective, [{
    type: Directive,
    args: [{
      selector: '[treeAnimateOpen]',
      standalone: false
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.TemplateRef
  }, {
    type: i0.ViewContainerRef
  }], {
    animateSpeed: [{
      type: Input,
      args: ['treeAnimateOpenSpeed']
    }],
    animateAcceleration: [{
      type: Input,
      args: ['treeAnimateOpenAcceleration']
    }],
    isEnabled: [{
      type: Input,
      args: ['treeAnimateOpenEnabled']
    }],
    isOpen: [{
      type: Input,
      args: ['treeAnimateOpen']
    }]
  });
})();
class TreeNodeContent {
  /** @nocollapse */static {
    this.ɵfac = function TreeNodeContent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeNodeContent)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TreeNodeContent,
      selectors: [["tree-node-content"]],
      inputs: {
        node: "node",
        index: "index",
        template: "template"
      },
      standalone: false,
      decls: 2,
      vars: 7,
      consts: [[4, "ngIf"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
      template: function TreeNodeContent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TreeNodeContent_span_0_Template, 2, 1, "span", 0);
          i0.ɵɵelementContainer(1, 1);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", !ctx.template);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngTemplateOutlet", ctx.template)("ngTemplateOutletContext", i0.ɵɵpureFunction3(3, _c1, ctx.node, ctx.node, ctx.index));
        }
      },
      dependencies: [i1.NgIf, i1.NgTemplateOutlet],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeNodeContent, [{
    type: Component,
    args: [{
      selector: 'tree-node-content',
      encapsulation: ViewEncapsulation.None,
      template: `
  <span *ngIf="!template">{{ node.displayField }}</span>
  <ng-container
    [ngTemplateOutlet]="template"
    [ngTemplateOutletContext]="{ $implicit: node, node: node, index: index }">
  </ng-container>`,
      standalone: false
    }]
  }], null, {
    node: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    template: [{
      type: Input
    }]
  });
})();
const DRAG_OVER_CLASS = 'is-dragging-over';
class TreeDragDirective {
  constructor(el, renderer, treeDraggedElement, ngZone) {
    this.el = el;
    this.renderer = renderer;
    this.treeDraggedElement = treeDraggedElement;
    this.ngZone = ngZone;
    this.dragEventHandler = this.onDrag.bind(this);
  }
  ngAfterViewInit() {
    let el = this.el.nativeElement;
    this.ngZone.runOutsideAngular(() => {
      el.addEventListener('drag', this.dragEventHandler);
    });
  }
  ngDoCheck() {
    this.renderer.setAttribute(this.el.nativeElement, 'draggable', this.treeDragEnabled ? 'true' : 'false');
  }
  ngOnDestroy() {
    let el = this.el.nativeElement;
    el.removeEventListener('drag', this.dragEventHandler);
  }
  onDragStart(ev) {
    // setting the data is required by firefox
    ev.dataTransfer.setData('text', ev.target.id);
    this.treeDraggedElement.set(this.draggedElement);
    if (this.draggedElement.mouseAction) {
      this.draggedElement.mouseAction('dragStart', ev);
    }
  }
  onDrag(ev) {
    if (this.draggedElement.mouseAction) {
      this.draggedElement.mouseAction('drag', ev);
    }
  }
  onDragEnd() {
    if (this.draggedElement.mouseAction) {
      this.draggedElement.mouseAction('dragEnd');
    }
    this.treeDraggedElement.set(null);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function TreeDragDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeDragDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(TreeDraggedElement), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  /** @nocollapse */
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TreeDragDirective,
      selectors: [["", "treeDrag", ""]],
      hostBindings: function TreeDragDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dragstart", function TreeDragDirective_dragstart_HostBindingHandler($event) {
            return ctx.onDragStart($event);
          })("dragend", function TreeDragDirective_dragend_HostBindingHandler() {
            return ctx.onDragEnd();
          });
        }
      },
      inputs: {
        draggedElement: [0, "treeDrag", "draggedElement"],
        treeDragEnabled: "treeDragEnabled"
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeDragDirective, [{
    type: Directive,
    args: [{
      selector: '[treeDrag]',
      standalone: false
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: TreeDraggedElement
  }, {
    type: i0.NgZone
  }], {
    draggedElement: [{
      type: Input,
      args: ['treeDrag']
    }],
    treeDragEnabled: [{
      type: Input
    }],
    onDragStart: [{
      type: HostListener,
      args: ['dragstart', ['$event']]
    }],
    onDragEnd: [{
      type: HostListener,
      args: ['dragend']
    }]
  });
})();
class TreeNodeExpanderComponent {
  /** @nocollapse */static {
    this.ɵfac = function TreeNodeExpanderComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeNodeExpanderComponent)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TreeNodeExpanderComponent,
      selectors: [["tree-node-expander"]],
      inputs: {
        node: "node"
      },
      standalone: false,
      decls: 1,
      vars: 2,
      consts: [[4, "treeMobxAutorun"], ["class", "toggle-children-wrapper", 3, "toggle-children-wrapper-expanded", "toggle-children-wrapper-collapsed", "click", 4, "ngIf"], ["class", "toggle-children-placeholder", 4, "ngIf"], [1, "toggle-children-wrapper", 3, "click"], [1, "toggle-children"], [1, "toggle-children-placeholder"]],
      template: function TreeNodeExpanderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TreeNodeExpanderComponent_ng_container_0_Template, 3, 2, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("treeMobxAutorun", i0.ɵɵpureFunction0(1, _c2));
        }
      },
      dependencies: [i1.NgIf, TreeMobxAutorunDirective],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeNodeExpanderComponent, [{
    type: Component,
    args: [{
      selector: 'tree-node-expander',
      encapsulation: ViewEncapsulation.None,
      template: `
    <ng-container *treeMobxAutorun="{ dontDetach: true }">
      <span
        *ngIf="node.hasChildren"
        [class.toggle-children-wrapper-expanded]="node.isExpanded"
        [class.toggle-children-wrapper-collapsed]="node.isCollapsed"
        class="toggle-children-wrapper"
        (click)="node.mouseAction('expanderClick', $event)"
      >
        <span class="toggle-children"></span>
      </span>
      <span *ngIf="!node.hasChildren" class="toggle-children-placeholder">
      </span>
    </ng-container>
  `,
      standalone: false
    }]
  }], null, {
    node: [{
      type: Input
    }]
  });
})();
class TreeNodeCheckboxComponent {
  /** @nocollapse */static {
    this.ɵfac = function TreeNodeCheckboxComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeNodeCheckboxComponent)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TreeNodeCheckboxComponent,
      selectors: [["tree-node-checkbox"]],
      inputs: {
        node: "node"
      },
      standalone: false,
      decls: 1,
      vars: 2,
      consts: [[4, "treeMobxAutorun"], ["type", "checkbox", 1, "tree-node-checkbox", 3, "click", "checked", "indeterminate"]],
      template: function TreeNodeCheckboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TreeNodeCheckboxComponent_ng_container_0_Template, 2, 2, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("treeMobxAutorun", i0.ɵɵpureFunction0(1, _c2));
        }
      },
      dependencies: [TreeMobxAutorunDirective],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeNodeCheckboxComponent, [{
    type: Component,
    args: [{
      selector: 'tree-node-checkbox',
      encapsulation: ViewEncapsulation.None,
      template: `
    <ng-container *treeMobxAutorun="{ dontDetach: true }">
      <input
        class="tree-node-checkbox"
        type="checkbox"
        (click)="node.mouseAction('checkboxClick', $event)"
        [checked]="node.isSelected"
        [indeterminate]="node.isPartiallySelected"
      />
    </ng-container>
  `,
      standalone: false
    }]
  }], null, {
    node: [{
      type: Input
    }]
  });
})();
class TreeNodeWrapperComponent {
  /** @nocollapse */static {
    this.ɵfac = function TreeNodeWrapperComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeNodeWrapperComponent)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TreeNodeWrapperComponent,
      selectors: [["tree-node-wrapper"]],
      inputs: {
        node: "node",
        index: "index",
        templates: "templates"
      },
      standalone: false,
      decls: 2,
      vars: 8,
      consts: [["class", "node-wrapper", 3, "padding-left", 4, "ngIf"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], [1, "node-wrapper"], [3, "node", 4, "ngIf"], [3, "node"], [1, "node-content-wrapper", 3, "click", "dblclick", "mouseover", "mouseout", "contextmenu", "treeDrop", "treeDropDragOver", "treeDropDragLeave", "treeDropDragEnter", "treeAllowDrop", "allowDragoverStyling", "treeDrag", "treeDragEnabled"], [3, "node", "index", "template"]],
      template: function TreeNodeWrapperComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TreeNodeWrapperComponent_div_0_Template, 5, 15, "div", 0);
          i0.ɵɵelementContainer(1, 1);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", !ctx.templates.treeNodeWrapperTemplate);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngTemplateOutlet", ctx.templates.treeNodeWrapperTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction4(3, _c3, ctx.node, ctx.node, ctx.index, ctx.templates));
        }
      },
      dependencies: [i1.NgIf, i1.NgTemplateOutlet, TreeNodeContent, TreeDropDirective, TreeDragDirective, TreeNodeExpanderComponent, TreeNodeCheckboxComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeNodeWrapperComponent, [{
    type: Component,
    args: [{
      selector: 'tree-node-wrapper',
      encapsulation: ViewEncapsulation.None,
      template: `
      <div *ngIf="!templates.treeNodeWrapperTemplate" class="node-wrapper" [style.padding-left]="node.getNodePadding()">
          <tree-node-checkbox *ngIf="node.options.useCheckbox" [node]="node"></tree-node-checkbox>
          <tree-node-expander [node]="node"></tree-node-expander>
          <div class="node-content-wrapper"
               [class.node-content-wrapper-active]="node.isActive"
               [class.node-content-wrapper-focused]="node.isFocused"
               (click)="node.mouseAction('click', $event)"
               (dblclick)="node.mouseAction('dblClick', $event)"
               (mouseover)="node.mouseAction('mouseOver', $event)"
               (mouseout)="node.mouseAction('mouseOut', $event)"
               (contextmenu)="node.mouseAction('contextMenu', $event)"
               (treeDrop)="node.onDrop($event)"
               (treeDropDragOver)="node.mouseAction('dragOver', $event)"
               (treeDropDragLeave)="node.mouseAction('dragLeave', $event)"
               (treeDropDragEnter)="node.mouseAction('dragEnter', $event)"
               [treeAllowDrop]="node.allowDrop"
               [allowDragoverStyling]="node.allowDragoverStyling()"
               [treeDrag]="node"
               [treeDragEnabled]="node.allowDrag()">

              <tree-node-content [node]="node" [index]="index" [template]="templates.treeNodeTemplate">
              </tree-node-content>
          </div>
      </div>
      <ng-container
              [ngTemplateOutlet]="templates.treeNodeWrapperTemplate"
              [ngTemplateOutletContext]="{ $implicit: node, node: node, index: index, templates: templates }">
      </ng-container>
  `,
      standalone: false
    }]
  }], null, {
    node: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    templates: [{
      type: Input
    }]
  });
})();
var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
class TreeNodeChildrenComponent {
  /** @nocollapse */static {
    this.ɵfac = function TreeNodeChildrenComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeNodeChildrenComponent)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TreeNodeChildrenComponent,
      selectors: [["tree-node-children"]],
      inputs: {
        node: "node",
        templates: "templates"
      },
      standalone: false,
      decls: 1,
      vars: 2,
      consts: [[4, "treeMobxAutorun"], [3, "tree-children", "tree-children-no-padding", 4, "treeAnimateOpen", "treeAnimateOpenSpeed", "treeAnimateOpenAcceleration", "treeAnimateOpenEnabled"], [3, "nodes", "templates", "treeModel", 4, "ngIf"], ["class", "tree-node-loading", 3, "padding-left", "template", "node", 4, "ngIf"], [3, "nodes", "templates", "treeModel"], [1, "tree-node-loading", 3, "template", "node"]],
      template: function TreeNodeChildrenComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TreeNodeChildrenComponent_ng_container_0_Template, 2, 4, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("treeMobxAutorun", i0.ɵɵpureFunction0(1, _c2));
        }
      },
      dependencies: () => [i1.NgIf, LoadingComponent, TreeNodeCollectionComponent, TreeAnimateOpenDirective, TreeMobxAutorunDirective],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeNodeChildrenComponent, [{
    type: Component,
    args: [{
      selector: 'tree-node-children',
      encapsulation: ViewEncapsulation.None,
      template: `
    <ng-container *treeMobxAutorun="{ dontDetach: true }">
      <div
        [class.tree-children]="true"
        [class.tree-children-no-padding]="node.options.levelPadding"
        *treeAnimateOpen="
          node.isExpanded;
          speed: node.options.animateSpeed;
          acceleration: node.options.animateAcceleration;
          enabled: node.options.animateExpand
        "
      >
        <tree-node-collection
          *ngIf="node.children"
          [nodes]="node.children"
          [templates]="templates"
          [treeModel]="node.treeModel"
        >
        </tree-node-collection>
        <tree-loading-component
          [style.padding-left]="node.getNodePadding()"
          class="tree-node-loading"
          *ngIf="!node.children"
          [template]="templates.loadingTemplate"
          [node]="node"
        ></tree-loading-component>
      </div>
    </ng-container>
  `,
      standalone: false
    }]
  }], null, {
    node: [{
      type: Input
    }],
    templates: [{
      type: Input
    }]
  });
})();
class TreeNodeCollectionComponent {
  constructor() {
    this._dispose = [];
  }
  get nodes() {
    return this._nodes;
  }
  set nodes(nodes) {
    this.setNodes(nodes);
  }
  get marginTop() {
    const firstNode = this.viewportNodes && this.viewportNodes.length && this.viewportNodes[0];
    const relativePosition = firstNode && firstNode.parent ? firstNode.position - firstNode.parent.position - firstNode.parent.getSelfHeight() : 0;
    return `${relativePosition}px`;
  }
  setNodes(nodes) {
    this._nodes = nodes;
  }
  ngOnInit() {
    this.virtualScroll = this.treeModel.virtualScroll;
    this._dispose = [
    // return node indexes so we can compare structurally,
    reaction(() => {
      return this.virtualScroll.getViewportNodes(this.nodes).map(n => n.index);
    }, nodeIndexes => {
      this.viewportNodes = nodeIndexes.map(i => this.nodes[i]);
    }, {
      compareStructural: true,
      fireImmediately: true
    }), reaction(() => this.nodes, nodes => {
      this.viewportNodes = this.virtualScroll.getViewportNodes(nodes);
    })];
  }
  ngOnDestroy() {
    this._dispose.forEach(d => d());
  }
  trackNode(index, node) {
    return node.id;
  }
  /** @nocollapse */
  static {
    this.ɵfac = function TreeNodeCollectionComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeNodeCollectionComponent)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TreeNodeCollectionComponent,
      selectors: [["tree-node-collection"]],
      inputs: {
        nodes: "nodes",
        treeModel: "treeModel",
        templates: "templates"
      },
      standalone: false,
      decls: 1,
      vars: 2,
      consts: [[4, "treeMobxAutorun"], [3, "node", "index", "templates", 4, "ngFor", "ngForOf", "ngForTrackBy"], [3, "node", "index", "templates"]],
      template: function TreeNodeCollectionComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TreeNodeCollectionComponent_ng_container_0_Template, 3, 4, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("treeMobxAutorun", i0.ɵɵpureFunction0(1, _c2));
        }
      },
      dependencies: () => [i1.NgForOf, TreeNodeComponent, TreeMobxAutorunDirective],
      encapsulation: 2
    });
  }
}
__decorate([observable], TreeNodeCollectionComponent.prototype, "_nodes", void 0);
__decorate([observable], TreeNodeCollectionComponent.prototype, "viewportNodes", void 0);
__decorate([computed], TreeNodeCollectionComponent.prototype, "marginTop", null);
__decorate([action], TreeNodeCollectionComponent.prototype, "setNodes", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeNodeCollectionComponent, [{
    type: Component,
    args: [{
      selector: 'tree-node-collection',
      encapsulation: ViewEncapsulation.None,
      template: `
    <ng-container *treeMobxAutorun="{ dontDetach: true }">
      <div [style.margin-top]="marginTop">
        <tree-node
          *ngFor="let node of viewportNodes; let i = index; trackBy: trackNode"
          [node]="node"
          [index]="i"
          [templates]="templates"
        >
        </tree-node>
      </div>
    </ng-container>
  `,
      standalone: false
    }]
  }], null, {
    nodes: [{
      type: Input
    }],
    treeModel: [{
      type: Input
    }],
    _nodes: [],
    templates: [{
      type: Input
    }],
    viewportNodes: [],
    marginTop: [],
    setNodes: []
  });
})();
class TreeNodeComponent {
  /** @nocollapse */static {
    this.ɵfac = function TreeNodeComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeNodeComponent)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TreeNodeComponent,
      selectors: [["TreeNode"], ["tree-node"]],
      inputs: {
        node: "node",
        index: "index",
        templates: "templates"
      },
      standalone: false,
      decls: 1,
      vars: 2,
      consts: [[4, "treeMobxAutorun"], [3, "class", "tree-node", "tree-node-expanded", "tree-node-collapsed", "tree-node-leaf", "tree-node-active", "tree-node-focused", 4, "ngIf"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], [3, "dropIndex", "node", 4, "ngIf"], [3, "node", "index", "templates"], [3, "node", "templates"], [3, "dropIndex", "node"]],
      template: function TreeNodeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TreeNodeComponent_ng_container_0_Template, 3, 8, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("treeMobxAutorun", i0.ɵɵpureFunction0(1, _c2));
        }
      },
      dependencies: [i1.NgIf, i1.NgTemplateOutlet, TreeNodeChildrenComponent, TreeNodeDropSlot, TreeNodeWrapperComponent, TreeMobxAutorunDirective],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeNodeComponent, [{
    type: Component,
    args: [{
      selector: 'TreeNode, tree-node',
      encapsulation: ViewEncapsulation.None,
      template: `
    <ng-container *treeMobxAutorun="{ dontDetach: true }">
      <div
        *ngIf="!templates.treeNodeFullTemplate"
        [class]="node.getClass()"
        [class.tree-node]="true"
        [class.tree-node-expanded]="node.isExpanded && node.hasChildren"
        [class.tree-node-collapsed]="node.isCollapsed && node.hasChildren"
        [class.tree-node-leaf]="node.isLeaf"
        [class.tree-node-active]="node.isActive"
        [class.tree-node-focused]="node.isFocused"
      >
        <tree-node-drop-slot
          *ngIf="index === 0"
          [dropIndex]="node.index"
          [node]="node.parent"
        ></tree-node-drop-slot>

        <tree-node-wrapper
          [node]="node"
          [index]="index"
          [templates]="templates"
        ></tree-node-wrapper>

        <tree-node-children
          [node]="node"
          [templates]="templates"
        ></tree-node-children>
        <tree-node-drop-slot
          [dropIndex]="node.index + 1"
          [node]="node.parent"
        ></tree-node-drop-slot>
      </div>
      <ng-container
        [ngTemplateOutlet]="templates.treeNodeFullTemplate"
        [ngTemplateOutletContext]="{
          $implicit: node,
          node: node,
          index: index,
          templates: templates
        }"
      >
      </ng-container>
    </ng-container>
  `,
      standalone: false
    }]
  }], null, {
    node: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    templates: [{
      type: Input
    }]
  });
})();
class TreeViewportComponent {
  constructor(elementRef, virtualScroll) {
    this.elementRef = elementRef;
    this.virtualScroll = virtualScroll;
    this.setViewport = this.throttle(() => {
      this.virtualScroll.setViewport(this.elementRef.nativeElement);
    }, 17);
    this.scrollEventHandler = this.setViewport.bind(this);
  }
  ngOnInit() {
    this.virtualScroll.init();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.setViewport();
      this.virtualScroll.fireEvent({
        eventName: TREE_EVENTS.initialized
      });
    });
    let el = this.elementRef.nativeElement;
    el.addEventListener('scroll', this.scrollEventHandler);
  }
  ngOnDestroy() {
    this.virtualScroll.clear();
    let el = this.elementRef.nativeElement;
    el.removeEventListener('scroll', this.scrollEventHandler);
  }
  getTotalHeight() {
    return this.virtualScroll.isEnabled() && this.virtualScroll.totalHeight + 'px' || 'auto';
  }
  throttle(func, timeFrame) {
    let lastTime = 0;
    return function () {
      let now = Date.now();
      if (now - lastTime >= timeFrame) {
        func();
        lastTime = now;
      }
    };
  }
  /** @nocollapse */
  static {
    this.ɵfac = function TreeViewportComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeViewportComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(TreeVirtualScroll));
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TreeViewportComponent,
      selectors: [["tree-viewport"]],
      standalone: false,
      features: [i0.ɵɵProvidersFeature([TreeVirtualScroll])],
      ngContentSelectors: _c4,
      decls: 1,
      vars: 2,
      consts: [[4, "treeMobxAutorun"]],
      template: function TreeViewportComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, TreeViewportComponent_ng_container_0_Template, 3, 2, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("treeMobxAutorun", i0.ɵɵpureFunction0(1, _c2));
        }
      },
      dependencies: [TreeMobxAutorunDirective],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeViewportComponent, [{
    type: Component,
    args: [{
      selector: 'tree-viewport',
      providers: [TreeVirtualScroll],
      template: `
    <ng-container *treeMobxAutorun="{ dontDetach: true }">
      <div [style.height]="getTotalHeight()">
        <ng-content></ng-content>
      </div>
    </ng-container>
  `,
      standalone: false
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: TreeVirtualScroll
  }], null);
})();
class TreeComponent {
  // Will be handled in ngOnChanges
  set nodes(nodes) {}
  set options(options) {}
  set focused(value) {
    this.treeModel.setFocus(value);
  }
  set state(state) {
    this.treeModel.setState(state);
  }
  constructor(treeModel, treeDraggedElement) {
    this.treeModel = treeModel;
    this.treeDraggedElement = treeDraggedElement;
    treeModel.eventNames.forEach(name => this[name] = new EventEmitter());
    treeModel.subscribeToState(state => this.stateChange.emit(state));
  }
  onKeydown($event) {
    if (!this.treeModel.isFocused) return;
    if (['input', 'textarea'].includes(document.activeElement.tagName.toLowerCase())) return;
    const focusedNode = this.treeModel.getFocusedNode();
    this.treeModel.performKeyAction(focusedNode, $event);
  }
  onMousedown($event) {
    function isOutsideClick(startElement, nodeName) {
      return !startElement ? true : startElement.localName === nodeName ? false : isOutsideClick(startElement.parentElement, nodeName);
    }
    if (isOutsideClick($event.target, 'tree-root')) {
      this.treeModel.setFocus(false);
    }
  }
  ngOnChanges(changes) {
    if (changes.options || changes.nodes) {
      this.treeModel.setData({
        options: changes.options && changes.options.currentValue,
        nodes: changes.nodes && changes.nodes.currentValue,
        events: this.pick(this, this.treeModel.eventNames)
      });
    }
  }
  sizeChanged() {
    this.viewportComponent.setViewport();
  }
  pick(object, keys) {
    return keys.reduce((obj, key) => {
      if (object && object.hasOwnProperty(key)) {
        obj[key] = object[key];
      }
      return obj;
    }, {});
  }
  /** @nocollapse */
  static {
    this.ɵfac = function TreeComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeComponent)(i0.ɵɵdirectiveInject(TreeModel), i0.ɵɵdirectiveInject(TreeDraggedElement));
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TreeComponent,
      selectors: [["Tree"], ["tree-root"]],
      contentQueries: function TreeComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, _c5, 5);
          i0.ɵɵcontentQuery(dirIndex, _c6, 5);
          i0.ɵɵcontentQuery(dirIndex, _c7, 5);
          i0.ɵɵcontentQuery(dirIndex, _c8, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.loadingTemplate = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.treeNodeTemplate = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.treeNodeWrapperTemplate = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.treeNodeFullTemplate = _t.first);
        }
      },
      viewQuery: function TreeComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c9, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.viewportComponent = _t.first);
        }
      },
      hostBindings: function TreeComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("keydown", function TreeComponent_keydown_HostBindingHandler($event) {
            return ctx.onKeydown($event);
          }, false, i0.ɵɵresolveBody)("mousedown", function TreeComponent_mousedown_HostBindingHandler($event) {
            return ctx.onMousedown($event);
          }, false, i0.ɵɵresolveBody);
        }
      },
      inputs: {
        nodes: "nodes",
        options: "options",
        focused: "focused",
        state: "state"
      },
      outputs: {
        toggleExpanded: "toggleExpanded",
        activate: "activate",
        deactivate: "deactivate",
        nodeActivate: "nodeActivate",
        nodeDeactivate: "nodeDeactivate",
        select: "select",
        deselect: "deselect",
        focus: "focus",
        blur: "blur",
        updateData: "updateData",
        initialized: "initialized",
        moveNode: "moveNode",
        copyNode: "copyNode",
        loadNodeChildren: "loadNodeChildren",
        changeFilter: "changeFilter",
        event: "event",
        stateChange: "stateChange"
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([TreeModel]), i0.ɵɵNgOnChangesFeature],
      decls: 5,
      vars: 6,
      consts: [["viewport", ""], [1, "angular-tree-component"], [3, "nodes", "treeModel", "templates", 4, "ngIf"], ["class", "empty-tree-drop-slot", 3, "dropIndex", "node", 4, "ngIf"], [3, "nodes", "treeModel", "templates"], [1, "empty-tree-drop-slot", 3, "dropIndex", "node"]],
      template: function TreeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "tree-viewport", null, 0)(2, "div", 1);
          i0.ɵɵtemplate(3, TreeComponent_tree_node_collection_3_Template, 1, 8, "tree-node-collection", 2)(4, TreeComponent_tree_node_drop_slot_4_Template, 1, 2, "tree-node-drop-slot", 3);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵclassProp("node-dragging", ctx.treeDraggedElement.isDragging())("angular-tree-component-rtl", ctx.treeModel.options.rtl);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.treeModel.roots);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.treeModel.isEmptyTree());
        }
      },
      dependencies: [i1.NgIf, TreeNodeDropSlot, TreeNodeCollectionComponent, TreeViewportComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeComponent, [{
    type: Component,
    args: [{
      selector: 'Tree, tree-root',
      providers: [TreeModel],
      template: `
      <tree-viewport #viewport>
          <div
                  class="angular-tree-component"
                  [class.node-dragging]="treeDraggedElement.isDragging()"
                  [class.angular-tree-component-rtl]="treeModel.options.rtl">
              <tree-node-collection
                      *ngIf="treeModel.roots"
                      [nodes]="treeModel.roots"
                      [treeModel]="treeModel"
                      [templates]="{
            loadingTemplate: loadingTemplate,
            treeNodeTemplate: treeNodeTemplate,
            treeNodeWrapperTemplate: treeNodeWrapperTemplate,
            treeNodeFullTemplate: treeNodeFullTemplate
          }">
              </tree-node-collection>
              <tree-node-drop-slot
                      class="empty-tree-drop-slot"
                      *ngIf="treeModel.isEmptyTree()"
                      [dropIndex]="0"
                      [node]="treeModel.virtualRoot">
              </tree-node-drop-slot>
          </div>
      </tree-viewport>
  `,
      standalone: false
    }]
  }], () => [{
    type: TreeModel
  }, {
    type: TreeDraggedElement
  }], {
    loadingTemplate: [{
      type: ContentChild,
      args: ['loadingTemplate', {
        static: false
      }]
    }],
    treeNodeTemplate: [{
      type: ContentChild,
      args: ['treeNodeTemplate', {
        static: false
      }]
    }],
    treeNodeWrapperTemplate: [{
      type: ContentChild,
      args: ['treeNodeWrapperTemplate', {
        static: false
      }]
    }],
    treeNodeFullTemplate: [{
      type: ContentChild,
      args: ['treeNodeFullTemplate', {
        static: false
      }]
    }],
    viewportComponent: [{
      type: ViewChild,
      args: ['viewport', {
        static: false
      }]
    }],
    nodes: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    focused: [{
      type: Input
    }],
    state: [{
      type: Input
    }],
    toggleExpanded: [{
      type: Output
    }],
    activate: [{
      type: Output
    }],
    deactivate: [{
      type: Output
    }],
    nodeActivate: [{
      type: Output
    }],
    nodeDeactivate: [{
      type: Output
    }],
    select: [{
      type: Output
    }],
    deselect: [{
      type: Output
    }],
    focus: [{
      type: Output
    }],
    blur: [{
      type: Output
    }],
    updateData: [{
      type: Output
    }],
    initialized: [{
      type: Output
    }],
    moveNode: [{
      type: Output
    }],
    copyNode: [{
      type: Output
    }],
    loadNodeChildren: [{
      type: Output
    }],
    changeFilter: [{
      type: Output
    }],
    event: [{
      type: Output
    }],
    stateChange: [{
      type: Output
    }],
    onKeydown: [{
      type: HostListener,
      args: ['body: keydown', ['$event']]
    }],
    onMousedown: [{
      type: HostListener,
      args: ['body: mousedown', ['$event']]
    }]
  });
})();
class TreeModule {
  /** @nocollapse */static {
    this.ɵfac = function TreeModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TreeModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TreeModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeModule, [{
    type: NgModule,
    args: [{
      declarations: [TreeComponent, TreeNodeComponent, TreeNodeContent, LoadingComponent, TreeDropDirective, TreeDragDirective, TreeNodeExpanderComponent, TreeNodeChildrenComponent, TreeNodeDropSlot, TreeNodeCollectionComponent, TreeViewportComponent, TreeNodeWrapperComponent, TreeNodeCheckboxComponent, TreeAnimateOpenDirective, TreeMobxAutorunDirective],
      exports: [TreeComponent, TreeNodeComponent, TreeNodeContent, LoadingComponent, TreeDropDirective, TreeDragDirective, TreeNodeExpanderComponent, TreeNodeChildrenComponent, TreeNodeDropSlot, TreeNodeCollectionComponent, TreeViewportComponent, TreeNodeWrapperComponent, TreeNodeCheckboxComponent, TreeAnimateOpenDirective, TreeMobxAutorunDirective],
      imports: [CommonModule],
      providers: []
    }]
  }], null, null);
})();

/*
 * Public API Surface of angular-tree-component
 */

/**
 * Generated bundle index. Do not edit.
 */

export { KEYS, LoadingComponent, TREE_ACTIONS, TreeAnimateOpenDirective, TreeComponent, TreeDragDirective, TreeDraggedElement, TreeDropDirective, TreeMobxAutorunDirective, TreeModel, TreeModule, TreeNode, TreeNodeCheckboxComponent, TreeNodeChildrenComponent, TreeNodeCollectionComponent, TreeNodeComponent, TreeNodeContent, TreeNodeDropSlot, TreeNodeExpanderComponent, TreeNodeWrapperComponent, TreeViewportComponent, TreeVirtualScroll };

@if (isLoading()){
<div class="loading-screen">
  <div class="container loading-message-container">
    <img
      class="loading-animation"
      src="../../../../assets/loading_animation_grey.gif"
      alt="loading_animation.gif"
    />
    <div class="loading-text">&#160;&#160;Loading...</div>
  </div>
</div>
}

<a href="/"
  ><img
    class="projectzen-logo"
    src="/assets/projectzen_logo.jpg"
    alt="projectzen_logo.jpg"
/></a>
<a class="small-button position-top-right" href="login">Log In</a>
<h1 class="main-header">Password Reset</h1>
<h2 class="subheader">
  Enter the email associated with your account below to reset your password.
</h2>
<form class="container password-reset-form">
  <div class="text-inputs">
    <div class="container label text-box-label-container">
      <label class="label text-box-label">Email<br /></label>
    </div>
    <input
      class="input text-input-field"
      id="txtEmail"
      type="email"
      name="email"
      required
      spellcheck="false"
      autocorrect="off"
      maxlength="75"
      autocapitalize="off"
    />
  </div>
  <div class="container password-reset-button-container">
    <button
      id="btnResetPassword"
      class="password-reset-button"
      class="btn btn-secondary"
      (click)="sendResetPasswordEmail('txtEmail')"
    >
      Send Password Reset Email
    </button>
  </div>
</form>
<p
  id="emailSentConfirmationText"
  class="password-reset-email-sent-confirmation-text"
></p>
<div class="page-footer">
  <p>ProjectZen, 6 Liberty Square #2334, Boston, MA 02109</p>
  <p>&nbsp;</p>
  <a href="terms">Terms</a>
  <p>&nbsp;</p>
  <a href="privacy">Privacy</a>
  <p>&nbsp;</p>
  <a href="mailto:help@projectzen.com">Help</a>
</div>

import { Component, OnInit, AfterViewInit, inject, model } from '@angular/core';
import { AuthService } from '../../../authentication/auth.service';
import { RECAPTCHA_SETTINGS, RecaptchaComponent, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingService } from '../../../shared/services/loading.service';
declare let $: any; // for jquery dom selections

@Component({
  selector: 'app-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss'],
  standalone: true,
  imports: [RecaptchaModule,RecaptchaFormsModule, FormsModule, ReactiveFormsModule],
  providers:[{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6LdvnDkUAAAAALe3Hei6WtoK3rsPCsGLlR1k6DfY',
    } as RecaptchaSettings,
  }]
})
export class SignUpPageComponent implements AfterViewInit {
  private readonly formBuilder = inject(FormBuilder);
  private loadingService = inject(LoadingService);
  public auth = inject(AuthService);
  formGroup:FormGroup;

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    // wait until window is fully loaded before hiding the loading screen
    // (which covers the other content)
    this.loadingService.hide();
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      name:['',Validators.required],
      email:['', Validators.email],
      password:['', Validators.required],
      reenterPassword:['', Validators.required],
    })
  }

  cap = model();
  // given a captchaRef object, execute catcha verification if sign up form
  // inputs valid; else display informative alerts
  executeCaptchaIfValidInputs(captureRef: RecaptchaComponent): void {
    // grab form input values
    let email = (<HTMLInputElement>document.getElementById('txtEmail')).value;
    let password = (<HTMLInputElement>document.getElementById('txtPassword'))
      .value;
    let reenterPassword = (<HTMLInputElement>(
      document.getElementById('txtReenterPassword')
    )).value;
    let iAgreeCheckbox = <HTMLInputElement>(
      document.getElementById('iAgreeCheckbox')
    );

    // if email invalid
    if (
      (email != '' &&
        email.indexOf('@') > 0 &&
        email.indexOf('@') != email.length - 1) == false
    ) {
      alert('Invalid email.');
    }
    // if passwords don't match
    else if (password != reenterPassword) {
      alert("Passwords don't match.");
    }
    // if "I agree to terms/privacy" checkbox isn't checked
    else if (iAgreeCheckbox.checked == false) {
      alert('Please indicate that you accept the Terms and Privacy Policy.');
    }
    // if form fields valid
    else {
      captureRef.execute(); // execute captcha
    }
  }

  // given sign up form captcha response, sign up new user
  signUpNewUser(captchaResponse: string): void {
    let displayName = (<HTMLInputElement>(
      document.getElementById('txtDisplayName')
    )).value;
    let email = (<HTMLInputElement>document.getElementById('txtEmail')).value;
    let password = (<HTMLInputElement>document.getElementById('txtPassword'))
      .value;
    let reenterPassword = (<HTMLInputElement>(
      document.getElementById('txtReenterPassword')
    )).value;

    this.auth.signUpNewUser(
      captchaResponse,
      displayName,
      email,
      password,
      reenterPassword
    );
  }
}

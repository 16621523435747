<div id="mainDashboardWrapper" class="defaultTheme" (click)="setFocus($event)">
  <nav id="navBar" class="navbar navbar-fixed-top">
    <div id="navBarContainer" class="container-fluid">
      <div id="navBarHeader" class="navbar-header">
        <button
          type="button"
          class="navbar-toggle"
          data-toggle="collapse"
          data-target="#navBarFields"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <span id="navBarHeaderText" class="navbar-brand"
          >ProjectZen<sup [hidden]="!isProAccount">+</sup></span
        >
      </div>
      <div id="navBarFields" class="collapse navbar-collapse">
        <ul class="nav navbar-nav">
          <li>
            <form
              id="searchBar"
              class="navbar-form dropdown-toggle"
              data-toggle="dropdown"
            >
              <input
                id="filterInput"
                #filterInput
                (keyup)="applyTextFilter()"
                type="text"
                class="form-control"
                placeholder="Search"
                name="search"
                (click)="delayFade()"
              />
            </form>
            <ul id="searchBarDropdown" class="dropdown-menu">
              <li>
                <div class="dropdown-option">
                  Try "status: to do"<a
                    data-toggle="modal"
                    data-target="#searchOptionsDialog"
                    href="#"
                    >Learn more</a
                  >
                </div>
              </li>
            </ul>
          </li>
          <li>
            <button
              [ngClass]="buttonClass.trashBtn"
              type="button"
              (click)="showTrashView()"
              class="glyphicon glyphicon-trash btn btn-default navbar-btn trash-btn"
              title="Trash"
            ></button>
          </li>
          <li>
            <button
              [ngClass]="buttonClass.listViewBtn"
              type="button"
              (click)="showListView()"
              class="glyphicon glyphicon-list btn btn-default navbar-btn list-view-btn"
              title="List View"
            ></button>
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li>
            <button
              id="navBarSaveButton"
              type="button"
              class="btn btn-default navbar-btn"
              (click)="navBarSaveButtonAction()"
              disabled
            >
              Saved
            </button>
          </li>
          <li>
            <button
              id="accountSettingsButton"
              title="Account Settings - {{ accountEmail }}"
              class="btn btn-action navbar-btn dropdown-toggle"
              type="button"
              data-toggle="dropdown"
            >
              <img src="/assets/user_icon.svg" alt="account-icon.svg" />
            </button>
            <ul id="navBarDropdown" class="dropdown-menu">
              <li>
                <button
                  id="accountSettingsDropdownOption"
                  class="dropdown-option"
                  data-toggle="modal"
                  data-target="#accountSettingsDialogBox"
                  href="#"
                >
                  Settings
                </button>
              </li>
              <li>
                <button
                  id="welcomeTutorialDropdownOption"
                  class="dropdown-option"
                  data-toggle="modal"
                  data-target="#welcomeTutorialDialog"
                  href="#"
                >
                  Help
                </button>
              </li>
              <li>
                <button class="dropdown-option" (click)="logout()">
                  Log Out
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="dashboardContent" class="default-background">
    <img
      hidden
      id="nightSkyBackground"
      src="/assets/night_background.jpg"
      alt="night_background.jpg"
    />
    
    @if(showTree() === SHOW_ITEMS.DISPLAY) {
    <div id="itemPath">
      <div class="path-value-wrapper">
        <a class="path-value" href="#/">All Items</a>
      </div>
      @for (obj of itemPath(); track $index) {
        <div class="path-value-wrapper">
          &nbsp;>&nbsp;<a class="path-value" href="{{ obj.url }}">{{
            obj.title
          }}</a>
        </div>
      }
    </div>
    
    <button
      id="addItemButton"
      type="button"
      class="btn btn-default btn-circle btn-xl"
      (click)="addNewItem()"
      title="Add item"
    >
      +
    </button>
    }

    @if (showTree() === SHOW_ITEMS.TRASH) {
    <button
      type="button"
      class="btn btn-default back-to-dashboard-button"
      (click)="showListView()"
      title="Back to Dashboard"
    >
      Back to Dashboard
    </button>
  
    <span class="trash-info">
      Items in trash automatically deleted after 2 weeks.
    </span>
    }
    <div id="allItemsContainer">
      <app-tree-root #itemsAllTree [nodes]="itemsAllTreeNodes" [options]="customTemplateStringOptions" (onMovedNode)="onMovedNode($event)"></app-tree-root>
    </div>
    @if(showTree() === SHOW_ITEMS.DISPLAY) {
      <app-tree-root #itemsDisplayedTree [nodes]="itemsDisplayedTreeNodes" [options]="customTemplateStringOptions" (onMovedNode)="onMovedNode($event)"></app-tree-root>
    }

    @if (showTree() === SHOW_ITEMS.TRASH) {
      <app-tree-root #itemsInTrashTree [nodes]="itemsInTrashTreeNodes" [options]="customTemplateStringOptionsTrash" (onMovedNode)="onMovedNode($event)"></app-tree-root>
    }
    
    <div id="accountSettingsDialogBox" class="modal fade" role="dialog">
      <div class="modal-dialog model-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
            <h4 class="modal-title">Settings</h4>
          </div>
          <div id="proSettingsContent" class="modal-body">
            <p id="proDescriptionText">
              ProjectZen Pro enables this account to create unlimited items to
              supercharge your team, along with some other benefits. Learn more
              <a href="gopro" target="_blank">here</a>.
            </p>
            <div id="paymentFormWrapper">
              <form id="paymentForm" action="/charge" method="post">
                <fieldset>
                  <div class="form-row">
                    <label for="stripeCredCardElement"
                      >Credit or debit card</label
                    >
                    <div id="stripeCredCardElement">
                      <!-- stripe "card" element inserted here -->
                    </div>
                    <div id="savedCreditCardElement">
                      <span id="credCardText"></span>
                      <a
                        id="changeLink"
                        (click)="changeCredCardLinkHandler()"
                        href="#"
                        >Change</a
                      >
                    </div>
                    <div id="credCardFormErrors" role="alert">
                      <!-- used to display "card" element errors -->
                    </div>
                  </div>
                  <div id="paymentAmountTextWrapper" class="form-row">
                    <p>Amount: $7.99/month</p>
                  </div>
                  <span id="expirationNotice"></span>
                  <div id="proActivationButtonWrapper">
                    <button
                      id="proActivationButton"
                      class="btn btn-secondary"
                      (click)="handleProPaymentFormSubmit()"
                    >
                      Upgrade to Pro
                    </button>
                  </div>
                  <div id="deactivateLinkWrapper">
                    <a
                      id="deactivateLink"
                      (click)="deactivateProLinkHandler()"
                      href="#"
                      >Deactivate</a
                    >
                  </div>
                  <div id="updateCredCardDetailsButtonWrapper">
                    <button
                      id="updateCredCardDetailsButton"
                      class="btn btn-secondary"
                      (click)="handleUpdateCardDetailsFormSubmit()"
                    >
                      Update Credit Card
                    </button>
                  </div>
                  <div id="cancelLinkWrapper">
                    <a id="cancelLink" (click)="cancelLinkHandler()" href="#"
                      >Cancel</a
                    >
                  </div>
                </fieldset>
              </form>
            </div>
            <div id="changeDeleteAccountContainer">
              <div id="change-password-settings" class="modal-body">
                <a
                  id="changePasswordLink"
                  (click)="resetChangePasswordDialog()"
                  data-toggle="modal"
                  data-target="#changePasswordDialogBox"
                  href="#"
                  data-dismiss="modal"
                  >Change Password</a
                >
              </div>
              <div id="delete-account-settings" class="modal-body">
                <a
                  id="deleteAccountLink"
                  data-toggle="modal"
                  data-target="#deleteAccountDialogBox"
                  href="#"
                  data-dismiss="modal"
                  >Delete account</a
                >
              </div>
            </div>
            <div id="changeDashboardTheme">
              <p>Change Dashboard Theme (for Pro users)</p>
              <button
                id="defaultTheme"
                [disabled]="!isProAccount"
                (click)="dashboardThemeSelectAction('defaultTheme')"
                class="first dashboard-theme is-pro-account-{{ isProAccount }}"
              >
                <div class="theme-image-wrapper">
                  <img
                    class="theme-image"
                    src="/assets/default_theme.jpg"
                    alt="default_theme.jpg"
                  />
                </div>
                <p class="theme-title">Default</p>
              </button>
              <button
                id="seaTheme"
                [disabled]="!isProAccount"
                (click)="dashboardThemeSelectAction('seaTheme')"
                class="dashboard-theme is-pro-account-{{ isProAccount }}"
              >
                <div class="theme-image-wrapper">
                  <img
                    class="theme-image"
                    src="/assets/sea_theme.jpg"
                    alt="sea_theme.jpg"
                  />
                </div>
                <p class="theme-title">Sea</p>
              </button>
              <button
                id="nightSkyTheme"
                [disabled]="!isProAccount"
                (click)="dashboardThemeSelectAction('nightSkyTheme')"
                class="dashboard-theme is-pro-account-{{ isProAccount }}"
              >
                <div class="theme-image-wrapper">
                  <img
                    class="theme-image"
                    src="/assets/night_theme.jpg"
                    alt="night_theme.jpg"
                  />
                </div>
                <p class="theme-title">Night Sky</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="searchOptionsDialog" class="modal fade" role="dialog">
      <div class="modal-dialog model-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
            <h4 class="modal-title">Search Options</h4>
          </div>
          <div class="modal-body">
            <div class="container table-container">
              <table>
                <tr>
                  <th>
                    <div class="search-example search-table-header">
                      Example
                    </div>
                  </th>
                  <th>
                    <div class="search-result search-table-header">Result</div>
                  </th>
                </tr>
                <tr>
                  <td><div class="search-example">my lovely item</div></td>
                  <td>
                    <div class="search-result">
                      Search by header (title, status, priority, tags)
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><div class="search-example">status: to do</div></td>
                  <td><div class="search-result">Search by status</div></td>
                </tr>
                <tr>
                  <td>
                    <div class="search-example">priority: high priority</div>
                  </td>
                  <td><div class="search-result">Search by priority</div></td>
                </tr>
                <tr>
                  <td>
                    <div class="search-example">#thebesttag #anothertag</div>
                  </td>
                  <td><div class="search-result">Search by tags</div></td>
                </tr>
                <tr>
                  <td>
                    <div class="search-example">title: the best item</div>
                  </td>
                  <td><div class="search-result">Search by title</div></td>
                </tr>
                <tr>
                  <td><div class="search-example">type: task</div></td>
                  <td><div class="search-result">Search by type</div></td>
                </tr>
                <tr>
                  <td>
                    <div class="search-example">description: bla bla</div>
                  </td>
                  <td>
                    <div class="search-result">Search by description</div>
                  </td>
                </tr>
                <tr>
                  <td><div class="search-example">id: f0245ae8d12c</div></td>
                  <td><div class="search-result">Search by id</div></td>
                </tr>
                <tr>
                  <td>
                    <div class="search-example">
                      isDescendentOf: d68e270c8202
                    </div>
                  </td>
                  <td><div class="search-result">Search by parent id</div></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="limitReachedDialog" class="modal fade" role="dialog">
      <div class="modal-dialog model-lg">
        <div class="modal-content">
          <div class="modal-body">
            <p>
              You've reached the {{ maxNumAdds }}-item monthly limit for new
              items. Remove some old items, or
              <a
                class="dropdown-option"
                data-toggle="modal"
                data-target="#accountSettingsDialogBox"
                href="#"
                data-dismiss="modal"
                >upgrade to Pro</a
              >
              to get unlimited space.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="problemSavingDialog" class="modal fade" role="dialog">
      <div class="modal-dialog model-lg">
        <div class="modal-content">
          <div class="modal-body">
            <p>
              There were problems saving newest changes. Items will be saved
              once we can connect to the server.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="welcomeTutorialDialog" class="modal fade" role="dialog">
      <div class="modal-dialog model-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div id="tutorialHeader" class="centered-element-wrapper">
              Welcome!
            </div>
            <div id="tutorialSteps">
              <div>
                <img
                  class="tutorial-image"
                  src="/assets/tutorial_img_1.jpg"
                  alt="tutorial_img_1.jpg"
                />
                <div class="tutorial-text">Click "+" to create an item.</div>
              </div>
              <div>
                <img
                  class="tutorial-image"
                  src="/assets/tutorial_img_2.jpg"
                  alt="tutorial_img_2.jpg"
                />
                <div class="tutorial-text">
                  Select options, or type in your own custom values.
                </div>
              </div>
              <div>
                <img
                  class="tutorial-image"
                  src="/assets/tutorial_img_4.jpg"
                  alt="tutorial_img_4.jpg"
                />
                <div class="tutorial-text">
                  Click and drag to put items in other items, or zoom into one
                  item by clicking
                  <div class="glyphicon glyphicon-resize-horizontal"></div>
                  .
                </div>
              </div>
            </div>
            <div id="tutorialFeedbackTxt">
              For further help, feedback, or to report a bug email
              <a href="mailto:help@projectzen.com">help&#64;projectzen.com</a>.
            </div>
            <div id="noTutorialInFutureMessage">
              <input
                [(ngModel)]="welcomeTutorialDialog.noTutorialInFutureCheckbox"
                type="checkbox"
              />
              <div>
                Don't show this popup in the future (it will always be
                accessible in "Account > Help")
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-default"
              data-dismiss="modal"
              (click)="welcomePopUpOkAction()"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="editItemDialog" class="modal fade modal-grey" role="dialog">
      <div class="modal-dialog model-lg">
        <div class="modal-content">
          <div
            class="modal-body"
            [style.background-color]="
              generateColorFromString(itemBeingEdited.type.toLowerCase())
            "
          >
            <div
              class="item"
              [style.background-color]="
                generateColorFromString(itemBeingEdited.type.toLowerCase())
              "
            >
              <div class="edit-mode">
                <form class="edit" (ngSubmit)="saveItemButtonAction()">
                  <fieldset class="editable-fields">
                    <div class="item-attribute">
                      <div class="attribute-label-wrapper">
                        <span>id:</span>
                      </div>
                      <div class="attribute-label-wrapper">
                        <span>{{ itemBeingEdited.id }}</span>
                      </div>
                    </div>
                    <div class="item-attribute">
                      <div class="attribute-label-wrapper">
                        <span>Title:</span>
                      </div>
                      <input
                        class="title"
                        type="text"
                        placeholder="E.g., My Big Project"
                        name="title"
                        maxlength="{{ maxLengthShortText }}"
                        [(ngModel)]="itemBeingEdited.title"
                      />
                    </div>
                    <div class="item-attribute">
                      <div class="attribute-label-wrapper">
                        <span>Type:</span>
                      </div>
                      <input
                        list="itemTypes"
                        class="type"
                        type="text"
                        placeholder="E.g., Project"
                        name="type"
                        maxlength="{{ maxLengthShortText }}"
                        [(ngModel)]="itemBeingEdited.type"
                      />
                      <datalist id="itemTypes">
                        <option value="Task"></option>
                        <option value="Project"></option>
                        <option value="Bug"></option>
                        <option value="Story"></option>
                        <option value="Epic"></option>
                        <option value="Or anything you want!"></option>
                        <option
                          value="(just type in a custom item type)"
                        ></option>
                      </datalist>
                    </div>
                    <div class="item-attribute">
                      <div class="attribute-label-wrapper">
                        <span>Status:</span>
                      </div>
                      <input
                        list="itemStatuses"
                        class="status"
                        type="text"
                        placeholder="E.g., To Do"
                        name="status"
                        maxlength="{{ maxLengthShortText }}"
                        [(ngModel)]="itemBeingEdited.status"
                      />
                      <datalist id="itemStatuses">
                        <option value="To Do"></option>
                        <option value="In Progress"></option>
                        <option value="Blocked"></option>
                        <option value="Will Not Do"></option>
                        <option value="Done"></option>
                        <option value="Or anything you want!"></option>
                        <option value="(just type in a custom status)"></option>
                      </datalist>
                    </div>
                    <div class="item-attribute">
                      <div class="attribute-label-wrapper">
                        <span>Priority:</span>
                      </div>
                      <input
                        list="itemPriorities"
                        class="priority"
                        type="text"
                        placeholder="E.g., Critical"
                        name="priority"
                        maxlength="{{ maxLengthShortText }}"
                        [(ngModel)]="itemBeingEdited.priority"
                      />
                      <datalist id="itemPriorities">
                        <option value="Low Priority"></option>
                        <option value="Normal Priority"></option>
                        <option value="High Priority"></option>
                        <option value="Very High Priority"></option>
                        <option value="Critical"></option>
                        <option value="Or anything you want!"></option>
                        <option
                          value="(just type in a custom priority)"
                        ></option>
                      </datalist>
                    </div>
                    <div class="item-attribute">
                      <div
                        class="attribute-label-wrapper subscribed-to-updates"
                      >
                        <span>Subscribed to email updates?</span>
                      </div>
                      <input
                        name="isSubscriberCheckbox"
                        [ngModel]="isSubscriber()"
                        (change)="toggleWhetherSubscriber(isSubscriber())"
                        class="is-subscriber-checkbox"
                        type="checkbox"
                      />
                    </div>
                    <br />
                    <div class="item-attribute">
                      <div
                        class="attribute-label-wrapper shared-with-label-wrapper"
                      >
                        <span>Shared with</span>
                      </div>
                      <div
                        class="attribute-label-wrapper assignee-label-wrapper"
                      >
                        <span>Assignee?</span>
                      </div>
                      <div class="share-permissions">
                        @for (permission of getInheritedPermissions(itemBeingEdited); track $index) {
                          <div class="share-permission">
                            <span class="strictly-inherited-share-permission">{{
                              permission.user.email
                            }}</span>
                            <span
                              [hidden]="!isCurrentUser(permission.user.email)"
                            >
                              (Me)</span
                            >
                            <input
                              (change)="
                                addUserAsAssigneeWithExplicitPerm(
                                  permission.user.email
                                )
                              "
                              class="is-assignee-checkbox"
                              type="checkbox"
                            />
                          </div>
                        }
                        @for (permission of getExplicitPermissions(itemBeingEdited); track $index) {
                          <div
                            class="share-permission non-inherited"
                          >
                            <input
                              list="suggestedPermissions"
                              [disabled]="
                                isCurrentUser(permission.user.email) &&
                                itemBeingEdited.shared_with.length == 1
                              "
                              name="email{{ $index }}"
                              [(ngModel)]="permission.user.email"
                              class="email"
                              placeholder="Enter email"
                              maxlength="{{ maxLengthShortText }}"
                              type="text"
                            />
                            @for (email of getSuggestedEmails(itemBeingEdited); track $index) {
                              <datalist id="suggestedPermissions">
                                <option [value]="email"></option>
                              </datalist>
                            }
  
                            <span
                              class="show-{{
                                isCurrentUser(permission.user.email)
                              }}"
                            >
                              (Me)</span
                            >
                            <input
                              name="isAssigneeNonInher{{ $index }}"
                              [ngModel]="isAssigneeEdit(permission.user.email)"
                              (change)="
                                toggleWhetherAssignee(
                                  permission.user.email,
                                  isAssigneeEdit(permission.user.email)
                                )
                              "
                              class="is-assignee-checkbox"
                              type="checkbox"
                            />
                            <button
                              [disabled]="isCurrentUser(permission.user.email)"
                              class="remove-share-permission btn glyphicon glyphicon-minus"
                              type="button"
                              (click)="removeSharePermission(permission)"
                              title="Remove permission"
                            ></button>
                          </div>
                        }
                      </div>
                      <span id="addSharePermissionMessage"></span>
                      <button
                        type="button"
                        class="add-share-permission btn glyphicon"
                        (click)="addSharePermission($event)"
                        title="Add share permission"
                      >
                        +
                      </button>
                    </div>
                    <div class="item-attribute">
                      <div class="attribute-label-wrapper">
                        <span>Tags</span>
                      </div>
                      @for (tag of itemBeingEdited.tags; track $index) {
                        <div
                          class="tagWrapper"
                        >
                          <span># </span
                          ><input
                            name="tag{{ $index }}"
                            [(ngModel)]="itemBeingEdited.tags[$index]"
                            placeholder="Add tag"
                            maxlength="{{ maxLengthShortText }}"
                            type="text"
                          />
                          <button
                            class="remove-tag btn glyphicon glyphicon-minus"
                            type="button"
                            (click)="removeTag(tag)"
                            title="Remove tag"
                          ></button>
                        </div>
                      }
                      <button
                        type="button"
                        class="add-tag btn glyphicon"
                        (click)="addTag($event)"
                        title="Add tag"
                      >
                        +
                      </button>
                    </div>
                    <hr />
                    <div class="item-attribute">
                      <div class="attribute-label-wrapper">
                        <span>Description:</span>
                      </div>
                      <textarea
                        class="description"
                        type="text"
                        name="description"
                        [(ngModel)]="itemBeingEdited.description"
                        maxlength="{{ maxLengthLongText }}"
                      ></textarea>
                    </div>
                    <div class="item-attribute">
                      <span class="history-header">History:</span>
                      <ul class="history-content">
                        @for (record of itemBeingEdited.history.slice(0,numEventsInRecentHistory()); track $index) {
                          <li
                            class="history-record"
                          >
                            {{ generateHistoryRecordText(record) }}
                          </li>
                        }
                        <a
                          class="show-more-history-link"
                          [hidden]="!additionalHistoryHidden()"
                          (click)="showFullHistory()"
                          >More</a
                        >
                        @for (record of itemBeingEdited.history.slice(numEventsInRecentHistory()); track $index) {
                          <li
                            [hidden]="!hasFullHistoryDisplayed()"
                            class="history-record"
                          >
                            {{ generateHistoryRecordText(record) }}
                          </li>
                        }
                        <a
                          class="show-less-history-link"
                          [hidden]="!hasFullHistoryDisplayed()"
                          (click)="hideFullHistory()"
                          >Less</a
                        >
                      </ul>
                    </div>
                    <br />
                    <div class="action-buttons">
                      <div
                        class="remove-item-wrapper"
                        [hidden]="itemBeingEdited.new"
                      >
                        <button
                          type="button"
                          class="remove-item btn glyphicon glyphicon-trash"
                          (click)="moveItemToTrashButtonAction()"
                          title="Remove Item"
                        ></button>
                      </div>
                      <button
                        type="button"
                        class="cancel btn glyphicon glyphicon-remove"
                        data-dismiss="modal"
                        title="Cancel"
                      ></button>
                      <button
                        type="submit"
                        class="save-item btn glyphicon glyphicon-ok"
                        title="Save"
                      ></button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="deleteAccountDialogBox" class="modal fade" role="dialog">
      <div class="modal-dialog model-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
            <h4 class="modal-title">Delete Account</h4>
          </div>
          <div class="modal-body">
            <p>
              Are you sure you would like to delete your ProjectZen account? We
              would be sad to see you go!
            </p>
            <p>
              Keep in mind that items currently shared with other users will
              continue to be available to those users even after this account's
              deletion. To remove those items as well, either unshare or delete
              them.
            </p>
          </div>
          <div class="modal-footer">
            <button
              id="deleteAccountButton"
              type="button"
              class="btn btn-default"
              (click)="deleteUserAccount()"
            >
              Delete Account
            </button>
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="changePasswordDialogBox" class="modal fade" role="dialog">
      <div class="modal-dialog model-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
            <h4 class="modal-title">Change Password</h4>
          </div>
          <div class="modal-body">
            <form id="updatePasswordForm">
              <div class="container label text-box-label-container">
                <label class="label text-box-label">Old Password:<br /></label>
              </div>
              <input
                id="oldPassword"
                class="input text-input-field"
                type="password"
                name="oldPassword"
                required
              />
              <div class="container label text-box-label-container">
                <label class="label text-box-label">New Password:<br /></label>
              </div>
              <input
                id="newPassword"
                class="input text-input-field"
                type="password"
                name="password"
                required
              />
            </form>
            <div id="passwordChangedTextWrapper">
              <span id="passwordChangedText"></span>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="changePasswordButton"
              type="button"
              class="btn btn-default"
              (click)="changeAccountPassword()"
            >
              Change Password
            </button>
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="reauthDialog" class="modal fade" role="dialog">
      <div class="modal-dialog model-lg">
        <div class="modal-content">
          <div class="modal-body">
            <p>Please reauthenticate to perform this action.</p>
            <form>
              <div class="container label text-box-label-container">
                <label class="label text-box-label">Password<br /></label>
              </div>
              <input
                id="txtPassword"
                class="input text-input-field"
                type="password"
                name="password"
                required
              />
              <div class="position-centered">
                <button
                  id="btnLogin"
                  class="btn btn-secondary"
                  (click)="reauthThenDeleteUserAccount()"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

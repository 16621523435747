import { Component, AfterViewInit, inject } from '@angular/core';
import { AuthService } from '../../../authentication/auth.service';
import { LoadingService } from '../../../shared/services/loading.service';
declare let $: any; // for jquery dom selections

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent implements AfterViewInit {
  private auth = inject(AuthService);
  private loadingService = inject(LoadingService);

  ngAfterViewInit(): void {
    if (this.auth.getCurrentUser()) {
      $('#welcome-login').hide();
      $('#welcome-dashboard').show();
    } else {
      $('#welcome-dashboard').hide();
      $('#welcome-login').show();
    }

    // wait until window is fully loaded before hiding the loading screen
    // (which covers the other content)
    this.loadingService.hide();
  }
}

<div class="content-shown-when-error-in-email-verification">
  <h2 class="expired-message">
    This link has expired. Please try verifying your email again.
  </h2>
</div>

<div class="content-shown-when-error-and-unverified-user-info-avail">
  <div class="container send-again-button-container">
    <button
      id="btnSendAgain"
      class="send-again-button"
      class="btn btn-secondary"
      (click)="sendVerificationEmailAgain()"
    >
      Send email again
    </button>
  </div>
  <p id="anotherEmailSent" class="another-email-sent-confirmation-text"></p>
</div>

<div class="content-shown-when-valid-password-reset-actioncode">
  <a href="/"
    ><img
      class="projectzen-logo"
      src="/assets/projectzen_logo.jpg"
      alt="projectzen_logo.jpg"
  /></a>
  <a class="small-button position-top-right" href="login">Log In</a>
  <h2 class="subheader">Enter a new password for your account below:</h2>

  <form class="container password-reset-form">
    <div class="text-inputs">
      <input
        id="txtPassword"
        class="input text-input-field"
        type="password"
        name="password"
        required
      />
    </div>

    <div class="container password-reset-button-container">
      <button
        id="btnChangePassword"
        class="password-reset-button"
        class="btn btn-secondary"
        (click)="updatePassword('txtPassword')"
      >
        Change Password
      </button>
    </div>
  </form>
  <p id="passwordChangedConfirmation"></p>

  <div class="page-footer">
    <p>ProjectZen, 6 Liberty Square #2334, Boston, MA 02109</p>
    <p>&nbsp;</p>
    <a href="terms">Terms</a>
    <p>&nbsp;</p>
    <a href="privacy">Privacy</a>
    <p>&nbsp;</p>
    <a href="mailto:help@projectzen.com">Help</a>
  </div>
</div>

<div class="content-shown-when-invalid-password-reset-actioncode">
  <h2 class="expired-message">
    This link has expired. Please try to reset your password again.
  </h2>
</div>

<div class="content-shown-when-invalid-url">
  <h2 class="invalid-url-message">
    This URL is invalid. Please try pasting the link again.
  </h2>
</div>

<div class="generic-coming-soon-screen">
  <img
    class="projectzen-logo-large"
    src="../assets/projectzen_logo.jpg"
    alt="projectzen_logo.jpg"
  />
  <h1 class="generic-coming-soon-screen-main-header main-header">
    Project Management, Simple.
  </h1>
  <h2 class="generic-coming-soon-screen-subheader subheader">
    ProjectZen is a minimalist tool to track tasks within teams. Use it to get
    more done better.
  </h2>
  <h2 class="generic-coming-soon-screen-subheader2 subheader">
    Want to learn more? Submit your email for updates on preview-testing and
    launch.
  </h2>
  <form class="container submit-email-form-container">
    <input
      class="input email-input-field"
      id="email-input-field"
      type="email"
      required
      spellcheck="false"
      autocorrect="off"
      maxlength="75"
      autocapitalize="off"
      placeholder="jane.doe@example.com"
    />
    <div class="container submit-email-button-container">
      <re-captcha
        #captchaRef="reCaptcha"
        siteKey="6LdvnDkUAAAAALe3Hei6WtoK3rsPCsGLlR1k6DfY"
        size="invisible"
        (resolved)="$event && submitEmail($event, 'email-input-field')"
      ></re-captcha>
      <button
        type="submit"
        id="submit-email-button"
        class="btn btn-secondary"
        (click)="captchaRef.execute()"
      >
        Submit
      </button>
    </div>
  </form>
  <p id="email-sent-confirmation-text"></p>
  <div class="page-footer">
    <p>ProjectZen, 6 Liberty Square #2334, Boston, MA 02109</p>
    <p>&nbsp;</p>
    <a href="terms">Terms</a>
    <p>&nbsp;</p>
    <a href="privacy">Privacy</a>
  </div>
</div>

import { Component, OnInit, HostListener, inject, signal, WritableSignal, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../../../authentication/auth.service';
import { AccountDataService } from '../../../site-pages/secure-pages/account-data.service';
import { isEqual } from 'lodash';
declare let Stripe: any; // for interacting with stripe api
declare let $: any; // for jquery dom selections
import { nanoid } from 'nanoid';
import {
  TreeComponent,
  TREE_ACTIONS,
  TreeNode,
  KEYS,
  IActionMapping,
  ITreeOptions,
  TreeModule,
} from '@ali-hm/angular-tree-component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingService } from '../../../shared/services/loading.service';
import { TreeRootComponent } from './tree-root/tree-root.component';

// a constant mapping various user input actions to tree behavior
// (part of angular2tree library)
const actionMapping: IActionMapping = {
  mouse: {
    // right-clicking on a node
    contextMenu: (tree, node, $event) => {
      /*$event.preventDefault();*/
      /*alert(`context menu for ${node.data.title}`);*/
    },
    // double-clicking on a node
    dblClick: (tree, node, $event) => {
      //if (node.hasChildren) TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
    },
    // clicking on a node
    click: (tree, node, $event) => {
      //TODO
      // $event.shiftKey
      //   ? // if simultaneously pressed Shift key, enable selection of multiple nodes
      //     TREE_ACTIONS.TOGGLE_SELECTED_MULTI(tree, node, $event)
      //   : // otherwise toggle selection of just the current node and deselect any others

      //to make node active and select
      setTimeout(() => {
        TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
      }, 0)
    },
  },
  keys: {
    // pressing the Enter key
    [KEYS.ENTER]: (tree, node, $event) => alert(`This is ${node.data.title}`),
  },
};

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TreeModule,
    TreeRootComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [AccountDataService],
})
export class MainDashboardComponent implements OnInit {
  private ac = inject(AccountDataService);
  private auth = inject(AuthService);
  private router = inject(Router);
  private readonly loadingService = inject(LoadingService);

  readonly SHOW_ITEMS = {
    TRASH: 'TRASH',
    DISPLAY: 'DISPLAY'
  };

  // variables related to user items
  @ViewChild('itemsAllTree',{static: false}) itemsAllTree: TreeRootComponent;
  @ViewChild('itemsInTrashTree',{static: false}) itemsInTrashTree: TreeRootComponent;
  @ViewChild('itemsDisplayedTree',{static: false}) itemsDisplayedTree: TreeRootComponent;

  itemsAllTreeNodes: Array<any> = [];
  itemsInTrashTreeNodes: Array<any> = [];
  itemsDisplayedTreeNodes: Array<any> = [];

  dummyData: Object = {
    allItemsData: [
      {
        assignees: [],
        description: 'abcd',
        display_order: -3.49245965480807e-10,
        history: [
          {
            action: 'add',
            after_value: null,
            before_value: null,
            new: false,
            object_name: 'Dummy Item Data',
            object_type: 'item',
            timestamp: '2018-09-02 11:25:44.304000+00:00',
            user: 'dana.bullister+15@gmail.com',
            was_automated_action: false,
          },
        ],
        id: 'jlkrrwb4',
        in_trash: false,
        parent_id: null,
        permission_type: 'Explicit',
        priority: 'Normal Priority',
        shared_with: [
          {
            id: 3561,
            type: 'Explicit',
            user: {
              display_name: '',
              email: 'dana.bullister+15@gmail.com',
              id: 295,
            },
          },
          {
            id: 3567,
            type: 'Explicit',
            user: {
              display_name: '',
              email: 'dana.bullister+14@gmail.com',
              id: 295,
            },
          },
        ],
        status: 'To Do',
        subscribers: [],
        tags: ['abc', '123'],
        title: 'Dummy Item Data',
        type: 'Dummy Data',
      },
      {
        assignees: [],
        description: 'blablabla',
        display_order: 0,
        history: [
          {
            action: 'add',
            after_value: null,
            before_value: null,
            new: false,
            object_name: 'A dummy task',
            object_type: 'item',
            timestamp: '2018-09-02 11:25:56.169000+00:00',
            user: 'dana.bullister+15@gmail.com',
            was_automated_action: false,
          },
        ],
        id: 'jlkrsgdq',
        in_trash: false,
        parent_id: 'jlkrrwb4',
        permission_type: 'Explicit',
        priority: '',
        shared_with: [
          {
            id: 3562,
            type: 'Explicit',
            user: {
              display_name: '',
              email: 'dana.bullister+15@gmail.com',
              id: 295,
            },
          },
        ],
        status: '',
        subscribers: [],
        tags: ['acooltask'],
        title: 'A dummy task',
        type: 'Task',
      },
    ],
    itemsInTrashData: [
      {
        assignees: [],
        description: '',
        display_order: -1,
        history: [
          {
            action: 'add',
            after_value: null,
            before_value: null,
            new: false,
            object_name: 'Dummy item in trash',
            object_type: 'item',
            timestamp: '2018-09-02 11:26:11.870000+00:00',
            user: 'dana.bullister+15@gmail.com',
            was_automated_action: false,
          },
          {
            action: 'remove',
            after_value: null,
            before_value: null,
            new: false,
            object_name: 'Dummy item in trash',
            object_type: 'item',
            timestamp: '2018-09-02 11:26:16.578000+00:00',
            user: 'dana.bullister+15@gmail.com',
            was_automated_action: false,
          },
        ],
        id: 'jlkrsq2y',
        in_trash: true,
        parent_id: null,
        permission_type: 'Explicit',
        priority: '',
        shared_with: [
          {
            id: 3563,
            type: 'Explicit',
            user: {
              display_name: '',
              email: 'dana.bullister+15@gmail.com',
              id: 295,
            },
          },
        ],
        status: '',
        subscribers: [],
        tags: [],
        title: 'Dummy item in trash',
        type: 'Item',
      },
    ],
  }; // some dummy data to populate the dashboard for development and testing purposes
  itemPath = signal<any[]>([]); // an array of objects representing the path of the currently zoomed item
  detailsExpanded: number[] = []; // ids of items that currently have their details expanded
  fullHistoryExpanded: number[] = []; // ids of items that currntly have their full history displayed
  itemBeingEdited: any; // dictionary containing data of item currently displayed in "edit item" dialog box (if any)
  numEventsInRecentHistory = signal<number>(2); // the number of item history records to display by default as "recent history" (there may be more that can be made visible by selecting "More")
  showDummyData = signal<boolean>(false); // whether to show dummy data (for development/testing purposes) instead of fetching real data from the backend

  // variables related to syncing with the backend
  windowFocused = signal<boolean>(true); // whether the browser window is focused (item dashboard periodically refreshes so long as this is the case)
  queuedRequests: string[] = []; // array of strings that each represent a type of request that is queued to be sent to the server; e.g., 'refreshDashboard' or 'saveNewChanges'
  requestInProgress: string = null; // a string corresponding to the type of backend request currently in progress; e.g., 'saveNewChanges'; null if no backend requests in progress
  timeOfLastItemUpdate = signal<Date>(null); // datetime stamp of the most recent user update to an item (add/delete/edit/reposition/moveFromTrash etc.)
  timeOfLastRefresh = signal<Date>(null); // datetime stamp of the last time the dashboard was refreshed
  unsavedChanges: Object[] = []; // array of dictionaries representing each new not-yet-saved change the user has made to their items
  autoRefreshOn = signal<boolean>(true); // whether to periodically autorefresh user dashboard (true by default; may occasionally set to false for development and testing purposes)

  // variables related to general user account settings
  accountEmail: string; // user email for this account
  isProAccount: boolean; // whether this is a paid "ProjectZen Pro" account
  newlyAccessedAccount: boolean; // whether this is the first time the user is accessing their account
  dashboardTheme: string; // the style theme of this dashboard (e.g., 'defaultTheme', 'seaTheme', 'nightSkyTheme')
  proExpiryDate = signal<Date>(null); // date when this account's Pro subscription will expire (if applicable); if in the past, date when the most recent subscription already has expired
  numItemsAddedThisMonth = signal<number>(null); // net number of items this user has added in their dashboard so far this month (= number of items this user has added this month minus number of items this user has removed this month)
  maxNumAdds: number; // net number of items that non-Pro account users are allowed to add per month (helpful to store as local variable in case this number has to be tweaked for any reason)
  maxLengthShortText: number; // maximum number of characters allowed in "short text" form inputs, such as item title (populated from backend)
  maxLengthLongText: number; // maximum number of characters allowed in "long text" form inputs, such as item description (populated from backend)

  // variables related to stripe credit card payment
  credCardLastFourDigits: string; // last four digits of credit card associated with this account if applicable
  stripeInstance: any; // a single instance of stripe for interacting with the stripe API
  stripeCredCard: any; // instance of stripe 'card' Element (credit card input element) in Pro account settings dialog box
  isStripeCardMounted = signal<boolean>(false); // whether or not the stripe credit card is currently linked to an Html element in the dashboard
  showTree = signal<string>(this.SHOW_ITEMS.DISPLAY); // whether to show items in the tree

  buttonClass: {
    listViewBtn: string; // list view button
    trashBtn: string; // trash Button
  } = {
    listViewBtn: 'selected',
    trashBtn: '',
  };

  // Welcome dialog
  welcomeTutorialDialog: {
    noTutorialInFutureCheckbox: WritableSignal<boolean>
  } = {
    noTutorialInFutureCheckbox: signal<boolean>(false)
  };

  // trigger an "unsaved changes" alert when user attempts to navigate away from
  // the browser window while there are still unsaved changes
  @HostListener('window:beforeunload', ['$event'])
  confirmExit($event) {
    if (this.unsavedChanges.length > 0) {
      $event.returnValue = true;
    }
  }

  ngOnInit(): void {
    this.loadingService.show();

    // create a stripe instance for making stripe API calls
    this.stripeInstance = Stripe(environment.stripePublishableKey);
    this.stripeCredCard = this.createStripeCredCard();

    // initialize itemBeingEdited with placeholder value
    let placeholderValue = {
      id: -1,
      parent_id: null,
      new: true,
      display_order: 0,
      subitems_expanded: false,
      hasChildren: false,
      children: [],
      title: '',
      type: '',
      status: '',
      priority: '',
      description: '',
      permission_type: '',
      assignees: [],
      subscribers: [],
      shared_with: [],
      tags: [],
      history: [],
    };
    this.itemBeingEdited = placeholderValue;

    // store account email as variable
    this.accountEmail = this.auth.getCurrentUser().email;

    // initialize and populate dashboard
    let self = this;
    if (!this.showDummyData()) {
      // refresh dashboard by fetching account data from the backend
      this.refreshDashboard().then((data) => {
        if (data['result'] == 'Success') {
          // show intro tutorial if applicable
          if (
            self.newlyAccessedAccount ||
            (self.auth.justLoggedIn && self.welcomeTutorialDialog.noTutorialInFutureCheckbox() == false)
          ) {
            self.showWelcomeTutorial();
            self.auth.justLoggedIn = false;
          }

          // set intervals so that when browser window becomes focused, dashboard
          // is refreshed and continues to periodically autorefresh as long as
          // it continues to be focused
          if (self.autoRefreshOn()) {
            let autoRefresh;
            $(window).focus(() => {
              if (!this.windowFocused()) {
                let timeInterval = 60; // (every 60 seconds)
                if (self.queuedRequests.indexOf('refreshDashboard') == -1) {
                  self.queueRequest('refreshDashboard');
                }
                autoRefresh = setInterval(() => {
                  // calculate seconds since last refresh
                  let secondsSinceLastRefresh = null;
                  if (self.timeOfLastRefresh()) {
                    let currentTime = new Date();
                    secondsSinceLastRefresh =
                      (currentTime.getTime() -
                        self.timeOfLastRefresh().getTime()) /
                      1000;
                  }

                  // if it's been a while since the last dashboard refresh, queue a dashboard refresh if it isn't queued already
                  if (
                    !secondsSinceLastRefresh ||
                    secondsSinceLastRefresh > timeInterval
                  ) {
                    if (self.queuedRequests.indexOf('refreshDashboard') == -1) {
                      self.queueRequest('refreshDashboard');
                    }
                  }
                }, timeInterval * 1000);
                this.windowFocused.set(true);
              }
            });
            $(window).blur(() => {
              if (this.windowFocused()) {
                clearInterval(autoRefresh);
                autoRefresh = 0;
                this.windowFocused.set(false);
              }
            });
          }

          // self.hideTrash();
          this.loadingService.hide();
        } else {
          // if request for newest account data unsuccessful, wait 10 seconds before trying again
          setTimeout(() => {
            self.ngOnInit();
          }, 10000);
        }
      });
    }

    // if "showDummyData" is true, show dummy dashboard data instead of refreshing from the backend (for development and testing purposes)
    else {
      setTimeout(() => {
        // pause for a second first to ensure that class variables have successfully initialized

        // set appropriate class variables
        let dummyItems = this.dummyData['allItemsData'];
        this.itemsAllTreeNodes = this.convertToTreeStructure(dummyItems);
        // this.allItems.treeModel.update();
        this.applyItemFilter();

        let dummyItemsInTrash = this.dummyData['itemsInTrashData'];
        this.itemsInTrashTreeNodes = this.convertToTreeStructure(dummyItemsInTrash);
        // this.itemsInTrash.treeModel.update();

        if (environment.debuggingConsoleLogsOn) {
          console.log(
            'Dashboard refreshed. Item tree:',
            this.itemsAllTreeNodes
          );
        }
      }, 1000);

      this.isProAccount = true;
      this.setDashboardTheme('defaultTheme');
      this.welcomeTutorialDialog.noTutorialInFutureCheckbox.set(true);
      this.newlyAccessedAccount = false;
      this.proExpiryDate.set(null);
      this.numItemsAddedThisMonth.set(60);
      this.maxLengthLongText = 200;
      this.maxLengthLongText = 50000;
      this.credCardLastFourDigits = '2872';

      // show intro tutorial if applicable
      if (this.newlyAccessedAccount) {
        this.showWelcomeTutorial();
        this.auth.justLoggedIn = false;
      }

      // set appropriate display in pro account settings dialog box
      if (!this.isProAccount) {
        this.setNonProAccountDisplay();
      } else {
        if (this.proExpiryDate()) {
          this.setProAccountSetToExpireDisplay();
        } else {
          this.setProAccountAutoRenewDisplay();
        }
      }

      this.timeOfLastRefresh.set(new Date());

      this.loadingService.hide();
    }

    $(window).on('hashchange', function () {
      self.applyItemFilter();
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // functions related to fetching/saving account data
  /////////////////////////////////////////////////////////////////////////////

  // given a string such as 'refreshDashboard' or 'saveNewChanges', adds it to
  // queuedRequests and kicks off requests if necessary
  queueRequest(str: string): void {
    this.queuedRequests.push(str);
    if (!this.requestInProgress) {
      this.processRequests();
    }
  }

  // if there are any unsaved changes, wait until there's been a pause since the
  // last item update and then save any unsaved changes
  scheduleAutoSave(): void {
    if (this.unsavedChanges.length > 0) {
      let timeInterval = 10;

      // find seconds since last item update
      let secondsSinceLastItemUpdate;
      if (this.timeOfLastItemUpdate()) {
        let currentTime = new Date();
        secondsSinceLastItemUpdate =
          (currentTime.getTime() - this.timeOfLastItemUpdate().getTime()) /
          1000;
      }

      if (
        this.timeOfLastItemUpdate() == null ||
        secondsSinceLastItemUpdate > timeInterval
      ) {
        // if it's been a while since the last item update
        if (this.queuedRequests.indexOf('saveNewChanges') == -1) {
          // queue "save new changes" it it isn't already
          this.queueRequest('saveNewChanges');
        }
      } else {
        setTimeout(() => {
          this.scheduleAutoSave();
        }, 1000 * timeInterval); // else wait a bit and start again
      }
    }
  }

  // given a dictionary representing an update to an item, push it to the
  // "unsavedChanges" array
  queueItemUpdate(itemUpdate: any): void {
    // queue deep copy, rather than pointer, so that relevant data remains
    // static and doesn't update when items are updated in the meantime
    let itemUpdateCopy = $.extend(true, {}, itemUpdate);
    if (itemUpdateCopy['type'] == 'addItem') {
      this.numItemsAddedThisMonth.set(this.numItemsAddedThisMonth() + 1);
    }
    this.unsavedChanges.push(itemUpdateCopy);

    if (itemUpdate['data']['itemId']) {
      let thisNode = this.getTreeDataById(this.itemsAllTreeNodes, itemUpdate['data']['itemId']);

      if (!thisNode) {
        thisNode = this.getTreeDataById(this.itemsInTrashTreeNodes, itemUpdate['data']['itemId']);
      }

      let thisNodeHistory = thisNode['history'];
      for (let i = 0; i < thisNodeHistory.length; i++) {
        let r = thisNodeHistory[i];
        r['new'] = false;
      }

      // this.allItems.treeModel.update();
    }
    this.unsavedChangesDisplay();
  }

  private getTreeDataById(nodes: Array<any>, value: string){
    for (const node of nodes) {
      if (node.id === value) {
        return node;
      }
      const foundNode = this.getTreeDataById(node.children, value);
      if (foundNode) {
        return foundNode;
      }
    }
    return null;
  }

  // recursively cycle through all requests in the request queue
  processRequests(): void {
    if (this.queuedRequests.length == 0) {
      this.requestInProgress = null;
    } else {
      let nextRequest = this.queuedRequests[0];
      this.requestInProgress = nextRequest;
      let self = this;

      if (nextRequest == 'refreshDashboard') {
        // if there are new unsaved item changes, first save changes
        if (this.unsavedChanges.length > 0) {
          if (this.queuedRequests.indexOf('saveNewChanges') == -1) {
            this.queueRequest('saveNewChanges');
          }
          this.queuedRequests = this.queuedRequests.splice(
            1,
            this.queuedRequests.length - 1
          );
          if (this.queuedRequests.indexOf('refreshDashboard') == -1) {
            this.queueRequest('refreshDashboard');
          }
          this.processRequests();
        }

        // else refresh the dashboard and keep cycling through requests
        else {
          this.refreshDashboard().then(function (data) {
            if (data['result'] == 'Success') {
              self.queuedRequests = self.queuedRequests.splice(
                1,
                self.queuedRequests.length - 1
              );
              self.processRequests();
            }
            // if error in refreshing, wait then try again
            else {
              setTimeout(() => {
                self.processRequests();
              }, 10000);
            }
          });
        }
      }

      if (nextRequest == 'saveNewChanges') {
        // save new changes if any, keep cycling through requests
        let unsavedChangesCopy = $.extend(true, [], this.unsavedChanges);
        if (unsavedChangesCopy.length == 0) {
          self.queuedRequests = self.queuedRequests.splice(
            1,
            self.queuedRequests.length - 1
          );
          self.allChangesSavedDisplay();
          self.processRequests();
        } else {
          this.saveUnsavedChanges(unsavedChangesCopy).then(function (data) {
            if (data['result'] == 'Success') {
              self.unsavedChanges.splice(0, unsavedChangesCopy.length); // remove just saved changes from "unsavedChanges" variable
              self.processRequests(); // and continue processing requests
            }

            // if error in saving, wait then try again
            else {
              setTimeout(() => {
                self.processRequests();
              }, 10000);
            }
          });
        }
      }
      if (nextRequest == 'saveWelcomePopupPreference') {
        this.ac
          .updateWelcomePopupPreference(this.welcomeTutorialDialog.noTutorialInFutureCheckbox())
          .then(function (data) {
            if (environment.debuggingConsoleLogsOn) {
              console.log('Saved new intro tutorial opt out preference.');
            }
            self.queuedRequests = self.queuedRequests.splice(
              1,
              self.queuedRequests.length - 1
            );
            self.processRequests(); // continue processing requests
          });
      }
      if (nextRequest == 'saveDashboardTheme') {
        let dashboardTheme = this.dashboardTheme;
        this.ac.saveDashboardTheme(dashboardTheme).then(function (response) {
          if (environment.debuggingConsoleLogsOn) {
            console.log(response['result']);
          }
          self.queuedRequests = self.queuedRequests.splice(
            1,
            self.queuedRequests.length - 1
          );
          self.processRequests(); // continue processing requests
        });
      }
    }
  }

  // given an array of dictionaries representing a set of unsaved changes,
  // returns a promise to save these to the backend
  saveUnsavedChanges(unsavedChanges: any): Promise<any> {
    if (environment.debuggingConsoleLogsOn) {
      console.log('Saving newest changes...');
    }
    this.changesBeingSavedDisplay();
    let self = this;

    return this.ac
      .saveUnsavedChanges(unsavedChanges)
      .then(function (response) {
        if (environment.debuggingConsoleLogsOn) {
          console.log(response.result);
        }
        $('#problemSavingDialog').modal('hide');
        return { result: 'Success', details: response };
      })
      .catch(function (error) {
        if (environment.debuggingConsoleLogsOn) {
          console.log('Error in saving latest changes.');
        }
        $('#problemSavingDialog').modal('show');
        return { result: 'Error', details: error };
      });
  }

  // actions taken when a user clicks the nav bar save button at the top right
  // in the dashboard;
  //  - modifies the nav bar save button to be disabled and say "saving"
  //  - adds "saveNewChanges" to request queue if isn't there already
  navBarSaveButtonAction(): void {
    this.changesBeingSavedDisplay();

    if (this.queuedRequests.indexOf('saveNewChanges') == -1) {
      this.queueRequest('saveNewChanges');
    }
  }

  // add an item to the bottom of the item tree
  addNewItem(): void {
    if (this.addLimitReached()) {
      this.showLimitReachedDialog();
    } else {
      this.itemBeingEdited = this.generateNewItem(null, -1); // set dummy display order; will set it on save
      this.showEditItemDialog();
    }
  }

  // handles button action for "Ok" button of intro tutorial popup dialog
  // if opt out preference is different from current value, queue request to the
  // backend to update this preference
  welcomePopUpOkAction(): void {
    // this.welcomeTutorialDialog.noTutorialInFutureCheckbox.set(this.welcomeTutorialDialog.noTutorialInFutureCheckbox);
    this.queueRequest('saveWelcomePopupPreference');
  }

  // triggered when user selects a new dashboard theme
  // given a dashboard theme selected by the user, if the theme differs from the
  // current dashboard theme, queue 'saveDashboardTheme' in requests
  dashboardThemeSelectAction(selectedTheme: string): void {
    this.setDashboardTheme(selectedTheme);
    this.queueRequest('saveDashboardTheme');
  }

  // deletes this user's account after reauthenticating the current user in
  // firebase (necessary for performing sensitive account actions)
  reauthThenDeleteUserAccount(): void {
    let self = this;
    let password = (<HTMLInputElement>document.getElementById('txtPassword'))
      .value;

    this.auth
      .reauthUser(password)
      .then(function () {
        if (environment.debuggingConsoleLogsOn) {
          console.log('User successfully reauthenticated.');
        }
        self.ac
          .deleteUserAccountInFirebase()
          .then(function () {
            if (environment.debuggingConsoleLogsOn) {
              console.log('Account deleted in firebase');
            }
            self.router.navigate(['home']); // and navigate to homepage
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // queues the item being edited to be saved to the backend database
  saveItemButtonAction(): void {
    this.timeOfLastItemUpdate.set(new Date());
    let isNewItem = this.itemBeingEdited['new'];

    // make lowercase, convert spaces to underscores in any item tags
    let nodeTags = this.itemBeingEdited['tags'];
    let newTags = [];
    for (let i = 0; i < nodeTags.length; i++) {
      let thisTag = nodeTags[i];
      let newTag = thisTag.trim().replace(' ', '_').toLowerCase();
      if (newTag != '') {
        newTags.push(newTag);
      }
    }
    // dedup them
    newTags = this.dedupArray(newTags);
    this.itemBeingEdited['tags'] = newTags;

    // trim, filter out invalid share permissions
    let permissions = this.itemBeingEdited['shared_with'];
    let newPermissions = [];
    for (let i = 0; i < permissions.length; i++) {
      let p = permissions[i];
      let u = p['user']['email'];
      u = u.trim();
      if (u.indexOf('@') > 0 && u.indexOf('@') != u.length - 1) {
        newPermissions.push(p);
      }
    }
    this.itemBeingEdited['shared_with'] = newPermissions;

    // add default title if empty
    if (this.itemBeingEdited['title'] == '') {
      let itemType = this.itemBeingEdited['type'];
      if (itemType == '') {
        this.itemBeingEdited['title'] = 'Untitled';
      } else {
        this.itemBeingEdited['title'] = 'Untitled ' + itemType;
      }
    }

    // if a new item, set display order value
    if (isNewItem) {
      let parentId = this.itemBeingEdited['parent_id'];
      let siblings = this.itemsAllTreeNodes;
      if (parentId) {
        const parentNode = this.getTreeDataById(this.itemsAllTreeNodes, parentId);
        siblings = parentNode?.children;
      }

      // if no siblings set default display order
      let newDisplayOrder = 0;
      if (siblings.length) {
       const oldestSibling = siblings[0]; // else just set to 1 less than oldest siblings
        newDisplayOrder = oldestSibling['display_order'] - 1;
      }
      this.itemBeingEdited['display_order'] = newDisplayOrder;

      // update item history
      this.itemBeingEdited['history'] = [
        {
          action: 'add',
          object_type: 'item',
          object_name: this.abbreviatedVersion(this.itemBeingEdited['title']),
          timestamp: new Date(),
          before_value: null,
          after_value: null,
          user: this.auth.getCurrentUser().email,
          was_automated_action: false,
          new: true,
        },
      ];
      this.itemBeingEdited['new'] = false;
    } else {
      // update item history
      let originalItem = this.getTreeDataById(this.itemsAllTreeNodes, this.itemBeingEdited['id']);

      if (this.itemBeingEdited['title'] != originalItem['title']) {
        let newEvent = {
          action: 'update',
          object_type: 'title',
          object_name: this.abbreviatedVersion(originalItem['title']),
          timestamp: new Date(),
          before_value: this.abbreviatedVersion(originalItem['title']),
          after_value: this.abbreviatedVersion(this.itemBeingEdited['title']),
          user: this.auth.getCurrentUser().email,
          was_automated_action: false,
          new: true,
        };
        this.itemBeingEdited['history'].unshift(newEvent);
      }

      if (this.itemBeingEdited['type'] != originalItem['type']) {
        let newEvent = {
          action: 'update',
          object_type: 'type',
          object_name: this.abbreviatedVersion(originalItem['type']),
          timestamp: new Date(),
          before_value: this.abbreviatedVersion(originalItem['type']),
          after_value: this.abbreviatedVersion(this.itemBeingEdited['type']),
          user: this.auth.getCurrentUser().email,
          was_automated_action: false,
          new: true,
        };
        this.itemBeingEdited['history'].unshift(newEvent);
      }

      if (this.itemBeingEdited['status'] != originalItem['status']) {
        let newEvent = {
          action: 'update',
          object_type: 'status',
          object_name: this.abbreviatedVersion(originalItem['status']),
          timestamp: new Date(),
          before_value: this.abbreviatedVersion(originalItem['status']),
          after_value: this.abbreviatedVersion(this.itemBeingEdited['status']),
          user: this.auth.getCurrentUser().email,
          was_automated_action: false,
          new: true,
        };
        this.itemBeingEdited['history'].unshift(newEvent);
      }

      if (this.itemBeingEdited['priority'] != originalItem['priority']) {
        let newEvent = {
          action: 'update',
          object_type: 'priority',
          object_name: this.abbreviatedVersion(originalItem['priority']),
          timestamp: new Date(),
          before_value: this.abbreviatedVersion(originalItem['priority']),
          after_value: this.abbreviatedVersion(
            this.itemBeingEdited['priority']
          ),
          user: this.auth.getCurrentUser().email,
          was_automated_action: false,
          new: true,
        };
        this.itemBeingEdited['history'].unshift(newEvent);
      }

      if (this.itemBeingEdited['description'] != originalItem['description']) {
        let newEvent = {
          action: 'update',
          object_type: 'description',
          object_name: this.abbreviatedVersion(originalItem['description']),
          timestamp: new Date(),
          before_value: this.abbreviatedVersion(originalItem['description']),
          after_value: this.abbreviatedVersion(
            this.itemBeingEdited['description']
          ),
          user: this.auth.getCurrentUser().email,
          was_automated_action: false,
          new: true,
        };
        this.itemBeingEdited['history'].unshift(newEvent);
      }

      // add a history record for any updated tags
      let oldTags = $.extend(true, [], originalItem['tags']);
      let oldTagCounts = this.getCounts(oldTags);
      let newTags = $.extend(true, [], this.itemBeingEdited['tags']);
      let newTagCounts = this.getCounts(newTags);
      if (!isEqual(oldTagCounts, newTagCounts)) {
        let newEvent = {
          action: 'update',
          object_type: 'tags',
          object_name: this.abbreviatedVersion(
            this.addHashTags(oldTags).join(', ')
          ),
          timestamp: new Date(),
          before_value: this.abbreviatedVersion(
            this.addHashTags(oldTags).join(', ')
          ),
          after_value: this.abbreviatedVersion(
            this.addHashTags(newTags).join(', ')
          ),
          user: this.auth.getCurrentUser().email,
          was_automated_action: false,
          new: true,
        };
        this.itemBeingEdited['history'].unshift(newEvent);
      }

      // add a history record for any updated share permissions
      let origPerms = originalItem['shared_with'];
      let oldUsersSharedWith = [];
      for (let i = 0; i < origPerms.length; i++) {
        let p = origPerms[i];
        let userEmail = p['user']['email'];
        oldUsersSharedWith.push(userEmail);
      }

      let newPerms = this.itemBeingEdited['shared_with'];
      let newUsersSharedWith = [];
      for (let i = 0; i < newPerms.length; i++) {
        let p = newPerms[i];
        let userEmail = p['user']['email'];
        newUsersSharedWith.push(userEmail);
      }

      let usersRemoved = [];
      for (let i = 0; i < oldUsersSharedWith.length; i++) {
        let u = oldUsersSharedWith[i];
        if (newUsersSharedWith.indexOf(u) == -1) {
          usersRemoved.push(u);
        }
      }
      let usersAdded = [];
      for (let i = 0; i < newUsersSharedWith.length; i++) {
        let u = newUsersSharedWith[i];
        if (oldUsersSharedWith.indexOf(u) == -1) {
          usersAdded.push(u);
        }
      }

      if (usersRemoved.length > 0) {
        let displayText = usersRemoved.join(', ');
        let newEvent = {
          action: 'remove',
          object_type: 'share permission',
          object_name: this.abbreviatedVersion(displayText),
          timestamp: new Date(),
          before_value: null,
          after_value: displayText,
          user: this.auth.getCurrentUser().email,
          was_automated_action: false,
          new: true,
        };
        this.itemBeingEdited['history'].unshift(newEvent);
      }
      if (usersAdded.length > 0) {
        let displayText = usersAdded.join(', ');
        let newEvent = {
          action: 'add',
          object_type: 'share permission',
          object_name: this.abbreviatedVersion(displayText),
          timestamp: new Date(),
          before_value: null,
          after_value: displayText,
          user: this.auth.getCurrentUser().email,
          was_automated_action: false,
          new: true,
        };
        this.itemBeingEdited['history'].unshift(newEvent);
      }

      // add a history record for any updated assignees
      let oldAssignees = originalItem['assignees'];
      let newAssignees = this.itemBeingEdited['assignees'];

      let removedAssignees = [];
      for (let i = 0; i < oldAssignees.length; i++) {
        let u = oldAssignees[i];
        if (newAssignees.indexOf(u) == -1) {
          removedAssignees.push(u);
        }
      }

      let addedAssignees = [];
      for (let i = 0; i < newAssignees.length; i++) {
        let u = newAssignees[i];
        if (oldAssignees.indexOf(u) == -1) {
          addedAssignees.push(u);
        }
      }

      if (removedAssignees.length > 0) {
        let displayText = removedAssignees.join(', ');
        let newEvent = {
          action: 'remove',
          object_type: 'assignee',
          object_name: this.abbreviatedVersion(displayText),
          timestamp: new Date(),
          before_value: null,
          after_value: displayText,
          user: this.auth.getCurrentUser().email,
          was_automated_action: false,
          new: true,
        };

        this.itemBeingEdited['history'].unshift(newEvent);
      }
      if (addedAssignees.length > 0) {
        let displayText = addedAssignees.join(', ');
        let newEvent = {
          action: 'add',
          object_type: 'assignee',
          object_name: this.abbreviatedVersion(displayText),
          timestamp: new Date(),
          before_value: null,
          after_value: displayText,
          user: this.auth.getCurrentUser().email,
          was_automated_action: false,
          new: true,
        };
        this.itemBeingEdited['history'].unshift(newEvent);
      }
    }

    // update item tree
    let itemBeingEditedCopy = $.extend(true, {}, this.itemBeingEdited);
    let expandParentId = null;
    if (isNewItem) {
      if (itemBeingEditedCopy['parent_id'] == null) {
        this.itemsAllTreeNodes.unshift(itemBeingEditedCopy);
      } else {
        // expand parent subitems if not already expanded
        expandParentId = itemBeingEditedCopy['parent_id'];
        const parent = this.getTreeDataById(this.itemsAllTreeNodes, expandParentId);
        this.itemsAllTree.expandById(expandParentId);
        parent.children.unshift(itemBeingEditedCopy);
      }
    } else {
      const originalNode = this.itemsAllTree.getTreeNodeById(itemBeingEditedCopy['id']);
      const parent = originalNode.parent;
      const index = originalNode.index;
      const allSibs = parent.data.children;
      allSibs.splice(index, 1);
      allSibs.unshift(itemBeingEditedCopy);

      this.sortByKey(allSibs, 'display_order');
    }
    this.itemsAllTreeNodes = [...this.itemsAllTreeNodes];
    this.itemsAllTree.updateModel();
    this.applyItemFilter();

    // expand parent node in displayed tree as well if appropriate
    if (expandParentId && this.itemsDisplayedTree) {
      this.itemsDisplayedTree.expandById(expandParentId);
    }

    const newlyUpdated = this.itemsAllTree.getTreeNodeById(itemBeingEditedCopy['id']);
    this.updateInheritedPermissionsForItemAndDescendents(newlyUpdated);

    // set node to be active and visible
    // newlyUpdated?.setActiveAndVisible();

    // hide and reenable item edit form
    this.hideEditItemDialog();
    $('#editItemDialog * fieldset.editable-fields').prop('disabled', false);

    // add action to array of newest changes
    let type;
    if (isNewItem) {
      type = 'addItem';
    } else {
      type = 'updateItem';
    }
    let itemSave = {
      type: type,
      timestamp: new Date(),
      data: { itemId: this.itemBeingEdited['id'], item: this.itemBeingEdited },
    };
    this.queueItemUpdate(itemSave);
    this.scheduleAutoSave();
  }

  // displays an alert asking if user is sure they would like to delete the given
  // item and its descendents; if confirmed, removes the item and its descendents
  // from the dashboard and adds them to trash
  moveItemToTrashButtonAction(): void {
    this.timeOfLastItemUpdate.set(new Date());
    const alertMessage =
      'This action will move this item and all of its descendents to trash. To view items in trash, select the trash icon in the navigation bar.';
    if (confirm(alertMessage)) {
      // remove item and descendents from dashboard
      let itemToRemove = this.itemBeingEdited;
      let nodeToRemove = this.itemsAllTree.getTreeNodeById(
        itemToRemove['id']
      );
      let allSiblings = this.itemsAllTree.getTreeNodeById(itemToRemove['id'])
        .parent.data.children;
      let index = this.itemsAllTree.getTreeNodeById(
        this.itemBeingEdited['id']
      ).index;
      allSiblings.splice(index, 1);
      if (allSiblings.length == 0) {
        this.itemsAllTree.getTreeNodeById(itemToRemove['id']).parent.data[
          'hasChildren'
        ] = false;
      }
      this.itemsAllTree.updateModel();
      this.applyItemFilter();

      // add item and descendents to trash
      this.itemsInTrashTreeNodes.unshift(itemToRemove);
      this.sortByKey(this.itemsInTrashTreeNodes, 'display_order');
      this.toggleDetailsExpanded(this.itemBeingEdited['id']);
      // this.itemsInTrash.treeModel.update();
      // this.itemsInTrash.treeModel.collapseAll();

      // update numItemsAddedThisMonth
      this.numItemsAddedThisMonth.set(this.numItemsAddedThisMonth() - 1);

      // update item 'trash' attribute and history
      let newEvent = {
        action: 'remove',
        object_type: 'item',
        object_name: this.abbreviatedVersion(itemToRemove['title']),
        timestamp: new Date(),
        before_value: null,
        after_value: null,
        user: this.auth.getCurrentUser().email,
        was_automated_action: false,
        new: true,
      };
      itemToRemove['history'].unshift(newEvent);
      itemToRemove['in_trash'] = true;
      this.updateInTrashPropertyForItemAndDescendents(itemToRemove, true);
      this.itemsAllTree.updateModel();
      this.applyItemFilter();
      this.makeAllInheritedPermissionsExplicit(nodeToRemove);

      // hide edit dialog
      this.hideEditItemDialog();

      // add action to array of newest changes
      let itemUpdate = {
        type: 'moveItemToTrash',
        timestamp: new Date(),
        data: { itemId: itemToRemove.id, item: itemToRemove },
      };

      this.queueItemUpdate(itemUpdate);
      this.scheduleAutoSave();
    }
  }

  // update dashboard with newest account data after retrieving it from the
  // backend
  refreshDashboard(): Promise<any> {
    if (environment.debuggingConsoleLogsOn) {
      console.log('Refreshing dashboard...');
    }

    return this.ac
      .getNewestAccountData()
      .then((data) => {
        // if there are no new item updates queued to be saved, update
        // dashboard data with newest values from the backend
        if (
          this.unsavedChanges.length == 0 &&
          this.queuedRequests.indexOf('saveDashboardTheme') == -1 &&
          this.queuedRequests.indexOf('saveWelcomePopupPreference') == -1
        ) {
          let itemsArray = data.items.filter(function (i) {
            return i['in_trash'] == false;
          });
          let trashArray = data.items.filter(function (i) {
            return i['in_trash'];
          });

          // convert from flat arrays of items to arrays of nested dictionaries
          // formatted in compliance with the "angular2 tree" library
          this.itemsAllTreeNodes = this.convertToTreeStructure(itemsArray);
          this.itemsAllTree.updateModel();

          this.itemsInTrashTreeNodes = this.convertToTreeStructure(trashArray);
          this.applyItemFilter();

          // set other component properties
          this.isProAccount = data.pro_account_info.is_pro_account;
          this.setDashboardTheme(data.other_settings.dashboard_theme);
          this.welcomeTutorialDialog.noTutorialInFutureCheckbox.set(
            data.other_settings.opted_out_welcome_popup
          );
          this.newlyAccessedAccount = data.is_new_user;
          this.proExpiryDate.set(data.pro_account_info.pro_expiry_date);
          this.numItemsAddedThisMonth.set(
            data.pro_account_info.net_num_items_added_this_month
          );
          this.maxNumAdds = data.pro_account_info.non_pro_max_added_items;
          this.maxLengthShortText = data.other_settings.MAX_LEN_SHORT_TEX;
          this.maxLengthLongText = data.other_settings.max_len_long_txt;
          this.credCardLastFourDigits =
            data.pro_account_info.cred_card_last_four_digits;

          // if in the midst of editing an existing item and its values have since
          // been updated, display an informative alert and update displayed history
          let dialogOpen =
            ($('#editItemDialog').data('bs.modal') || {}).isShown == true;
          let fieldsDisabled =
            $('#editItemDialog * fieldset.editable-fields').attr('disabled') !=
            undefined;
          let editingInProgress = dialogOpen && !fieldsDisabled;
          let existingItem = this.itemBeingEdited['new'] != true;
          let newestItemVersion = this.itemsAllTree.getTreeNodeById(
            this.itemBeingEdited['id']
          );
          let recentChanges =
            newestItemVersion == null ||
            this.itemBeingEdited.history.length !=
              newestItemVersion.data.history.length;
          if (editingInProgress && existingItem && recentChanges) {
            // update edit form's displayed history
            this.itemBeingEdited.history = newestItemVersion?.data?.history;
            // show an alert that the item has been modified elsewhere
            setTimeout(() => {
              alert(
                'This item has been modified elsewhere. Please cancel and reopen the edited item to see the latest version so as not to overwrite important changes.'
              );
            }, 1000);
          }

          // set appropriate display in pro account settings dialog box
          if (!this.isProAccount) {
            this.setNonProAccountDisplay();
          } else {
            if (this.proExpiryDate()) {
              this.setProAccountSetToExpireDisplay();
            } else {
              this.setProAccountAutoRenewDisplay();
            }
          }

          this.timeOfLastRefresh.set(new Date());

          if (environment.debuggingConsoleLogsOn) {
            console.log(
              'Dashboard refreshed. Item tree:',
              this.itemsAllTreeNodes
            );
          }
        }
        return { result: 'Success', details: data };
      })
      .catch((error) => {
        if (environment.debuggingConsoleLogsOn) {
          console.log('Error in fetching newest item data. Details:', error);
        }
        return { result: 'Error', details: error };
      });
  }

  /////////////////////////////////////////////////////////////////////////////
  // functions related to getting item attributes
  /////////////////////////////////////////////////////////////////////////////

  // given a tree node, returns an array of those share permissions that the node
  // has inherited from its ancestors that are not explicitly assigned to
  // the node
  getInheritedPermissions(node: any): any[] {
    if (node) {
      let allPermissions = node['shared_with'];
      let inherPerms = allPermissions.filter(function (p) {
        return p.type !== 'Explicit';
      });
      return inherPerms;
    } else {
      return [];
    }
  }

  // given a tree node, returns an array of those share permissions that the node
  // has inherited from its ancestors that are not explicitly assigned to
  // the node
  getExplicitPermissions(node: any): any[] {
    if (node) {
      let allPermissions = node['shared_with'];
      let explicPerms = allPermissions.filter(function (p) {
        return p.type == 'Explicit';
      });
      return explicPerms;
    } else {
      return [];
    }
  }

  // given a user email, returns whether this email is included or has been
  // added to this item's list of assignees within its edit form
  isAssigneeEdit(userEmail: string): boolean {
    let itemAssignees = this.itemBeingEdited.assignees;

    for (let i = 0; i < itemAssignees.length; i++) {
      let thisAssignee = itemAssignees[i];
      if (userEmail == thisAssignee) {
        return true;
      }
    }
    return false;
  }

  // returns whether the current user is a subscriber of the item being edited
  isSubscriber(): boolean {
    let userEmail = this.auth.getCurrentUser()?.email;
    let itemSubscribers = this.itemBeingEdited['subscribers'];
    let isSubscriber = itemSubscribers.indexOf(userEmail) != -1;
    return isSubscriber;
  }

  // given an item history record object, returns a string of text to be
  // displayed to the user communicating the contents of that record
  generateHistoryRecordText(record: any): string {
    let reformattedTimestamp = [
      record.timestamp.getFullYear(),
      '/',
      record.timestamp.getMonth() + 1,
      '/',
      record.timestamp.getDate(),
      ' ',
      record.timestamp.getHours(),
      ':',
      record.timestamp.getMinutes(),
      ':',
      record.timestamp.getSeconds(),
    ].join('');
    let userDisplayText = this.getPrefix(record.user);
    let entry;

    if (record.object_type == 'item' && record.action == 'add') {
      if (!record.was_automated_action) {
        entry = ['Item created by ', userDisplayText].join('');
      } else {
        entry = 'Item autocreated';
      }
    } else if (record.object_type == 'item' && record.action == 'remove') {
      if (!record.was_automated_action) {
        entry = ['Item moved to trash by ', userDisplayText].join('');
      } else {
        entry = 'Item automoved to trash';
      }
    } else if (record.object_type == 'item' && record.action == 'undelete') {
      if (!record.was_automated_action) {
        entry = ['Item moved from trash by ', userDisplayText].join('');
      } else {
        entry = 'Item automoved from trash';
      }
    } else if (
      record.object_type == 'share permission' &&
      record.action == 'add'
    ) {
      if (!record.was_automated_action) {
        entry = [
          userDisplayText,
          ' shared item with ',
          this.removeAllSuffixes(record.object_name),
        ].join('');
      } else {
        entry = [
          'Item shared with ',
          this.removeAllSuffixes(record.object_name),
        ].join('');
      }
    } else if (
      record.object_type == 'share permission' &&
      record.action == 'remove'
    ) {
      if (!record.was_automated_action) {
        entry = [
          userDisplayText,
          ' stopped sharing item with ',
          this.removeAllSuffixes(record.object_name),
        ].join('');
      } else {
        entry = [
          'Share permission removed for ',
          this.removeAllSuffixes(record.object_name),
        ].join('');
      }
    } else if (record.object_type == 'assignee' && record.action == 'add') {
      if (!record.was_automated_action) {
        entry = [
          userDisplayText,
          ' added ',
          this.removeAllSuffixes(record.object_name),
          ' as an assignee',
        ].join('');
      } else {
        entry = [
          this.removeAllSuffixes(record.object_name),
          ' added as an assignee',
        ].join('');
      }
    } else if (record.object_type == 'assignee' && record.action == 'remove') {
      if (!record.was_automated_action) {
        entry = [
          userDisplayText,
          ' removed ',
          this.removeAllSuffixes(record.object_name),
          ' as an assignee',
        ].join('');
      } else {
        entry = [
          this.removeAllSuffixes(record.object_name),
          ' removed as an assignee',
        ].join('');
      }
    } else if (record.object_type == 'subscriber' && record.action == 'add') {
      if (!record.was_automated_action) {
        entry = [
          userDisplayText,
          ' added ',
          this.getPrefix(record.object_name),
          ' as an subscriber',
        ].join('');
      } else {
        entry = [
          this.getPrefix(record.object_name),
          ' added as an subscriber',
        ].join('');
      }
    } else if (
      record.object_type == 'subscriber' &&
      record.action == 'remove'
    ) {
      if (!record.was_automated_action) {
        entry = [
          userDisplayText,
          ' removed ',
          this.getPrefix(record.object_name),
          ' as an subscriber',
        ].join('');
      } else {
        entry = [
          this.getPrefix(record.object_name),
          ' removed as an subscriber',
        ].join('');
      }
    } else if (record.object_type == 'tags') {
      if (!record.was_automated_action) {
        entry = [
          userDisplayText,
          ' updated ',
          record.object_type,
          ' from ',
          record.before_value,
          ' to ',
          record.after_value,
          '',
        ].join('');
      } else {
        entry = [
          record.object_type,
          ' updated from ',
          record.before_value,
          ' to ',
          record.after_value,
          '',
        ].join('');
      }
    } else {
      if (!record.was_automated_action) {
        entry = [
          userDisplayText,
          ' updated ',
          record.object_type,
          ' from "',
          record.before_value,
          '" to "',
          record.after_value,
          '"',
        ].join('');
      } else {
        entry = [
          record.object_type,
          ' updated from "',
          record.before_value,
          '" to "',
          record.after_value,
          '"',
        ].join('');
      }
    }

    let text = [reformattedTimestamp, '   ', entry].join('');
    return text;
  }

  // given a string, returns the first reasonable chunk of that string
  // concatenated with an ellipses ("...") if the string is long; otherwise,
  // return the original string
  // (used in populating entries in item history corresponding to updates of
  // values too long to list in the history record in their entirety; e.g.,
  // updates of long item descriptions)
  abbreviatedVersion(str: string): string {
    let snippetLength = 50;
    if (str) {
      if (str.length > snippetLength) {
        str = str.substring(0, snippetLength);
        str = str.concat('...');
      }
      return str;
    } else {
      return '';
    }
  }

  // given a user email, returns whether it is the email of the currently logged
  // in user
  isCurrentUser(email: string): boolean {
    let currentUserEmail = this.auth.getCurrentUser().email;
    return email == currentUserEmail;
  }

  // given a string email, return just the prefix of that email as a string
  // e.g., given 'dana.bullister@gmail.com', returns 'dana.bullister'
  getPrefix(email: string): string {
    return email.substring(0, email.indexOf('@'));
  }

  // given a string with a comma separated list of concatenated email addresses,
  // return the same string but without the suffixes of the emails
  // e.g., "example@abc.com, example2@abc.com" -> "example1, example2"
  removeAllSuffixes(emails: string): string {
    if (emails.indexOf('@') == -1) {
      return emails;
    } else {
      let ind1 = emails.indexOf('@');
      let ind2 = emails.indexOf(',', ind1);
      let minusSuffix;
      if (ind2 == -1) {
        minusSuffix = emails.substring(0, ind1);
      } else {
        minusSuffix = emails.substring(0, ind1).concat(emails.substring(ind2));
      }
      return this.removeAllSuffixes(minusSuffix);
    }
  }

  // returns a boolean indicating whether this are additional events in this item's
  // history (aside from those in "recent history") that are currently not displayed in edit mode
  additionalHistoryHidden(): boolean {
    return this.isMoreHistory() && !this.hasFullHistoryDisplayed();
  }

  // returns a boolean of whether or not this item has more events in its item
  // history than are displayed by default as "recent history"
  isMoreHistory(): boolean {
    return (
      this.itemBeingEdited.history.length > this.numEventsInRecentHistory()
    );
  }

  // returns true if this item has its full history (not just its recent history)
  // displayed
  hasFullHistoryDisplayed(): boolean {
    return false;
  }

  // given an item, the current tree model, and a flat array of the newly
  // retrieved and most updated items from the backend, generate the appropriate
  // new parent item id for this item based on where it should be with relation to
  // the newly updated set of items
  generateParentId(item, currentTreeModel, newItemArray) {
    let itemsTreeNode = currentTreeModel.getNodeById(item['id']);
    let currentItemParent = itemsTreeNode.parent;

    // if the node doesn't have a parent, return null
    if (itemsTreeNode.parent.data.virtual) {
      return null;
    } else {
      // else determine whether the array of updated items contains this item's
      // current parent
      let containsParent = this.containsItem(
        newItemArray,
        currentItemParent.data.id
      );

      // if it does, return this parent's id
      if (containsParent) {
        return currentItemParent.data.id;
      }

      // else return the generated parent id for this item's parent
      else {
        return this.generateParentId(
          currentItemParent.data,
          currentTreeModel,
          newItemArray
        );
      }
    }
  }
  // given an item, the current tree model, and a flat array of newly retrieved
  // and most updated items from the backend, generate the appropriate new display
  // order value (index among its siblings) for the item based on where it should be
  // with relation to the newly updated set of items
  generateDisplayOrder(item, currentTreeModel, newItemArray) {
    let itemsTreeNode = currentTreeModel.getNodeById(item['id']);
    return itemsTreeNode.index;
  }

  showListView(): void {
    this.showMainDashboard();
    this.buttonClass.listViewBtn = 'selected';
    this.buttonClass.trashBtn = '';
    $('#progressBoardButton').removeClass('selected');
  }

  showTrashView(): void {
    this.showTrash();

    this.buttonClass.listViewBtn = '';
    this.buttonClass.trashBtn = 'selected';
    $('#progressBoardButton').removeClass('selected');
  }

  showProgressBoardsView(): void {
    this.showTree.set('');

    this.buttonClass.listViewBtn = '';
    this.buttonClass.trashBtn = '';
    $('#progressBoardButton').addClass('selected');
  }

  showTrash(): void {
    this.showTree.set(this.SHOW_ITEMS.TRASH);
  }

  showMainDashboard(): void {
    this.showTree.set(this.SHOW_ITEMS.DISPLAY);
  }

  // given an array of item objects and an integer id, returns whether the array
  // contains an item object with that id
  containsItem(itemArray: Object[], itemId: number): boolean {
    for (var i = 0; i < itemArray.length; i++) {
      let thisItem = itemArray[i];
      if (thisItem['id'] == itemId) {
        return true;
      }
    }
    return false;
  }

  // given a parent id and a display order value, return a dictionary object
  // representing a newly generated item with those attributes
  generateNewItem(parentId, displayOrder): any {
    // generate new maximum 12-digit unique hexadecimal id
    let id = nanoid(12);
    let sharedWith = [
      {
        type: 'Explicit',
        user: {
          display_name: this.auth.getCurrentUser().displayName,
          firebase_uid: this.auth.getCurrentUser().uid,
          email: this.auth.getCurrentUser().email,
        },
      },
    ];
    if (parentId) {
      sharedWith = this.makeInherited(
        this.itemsAllTree.getTreeNodeById(parentId).data['shared_with']
      );
    }

    let newItem = {
      id: id,
      new: true,
      display_order: displayOrder,
      subitems_expanded: false,
      hasChildren: false,
      in_trash: false,
      children: [],
      parent_id: parentId,
      title: '',
      type: '',
      status: '',
      priority: '',
      description: '',
      permission_type: 'Explicit',
      assignees: [],
      subscribers: [],
      shared_with: sharedWith,
      tags: [],
      history: [],
    };

    return newItem;
  }

  // given an array of items, returns just those items that don't have parents
  // that are also in the array
  getRootItems(arrayOfItems: TreeNode[]): TreeNode[] {
    let rootItems = [];
    for (let i = 0; i < arrayOfItems.length; i++) {
      let thisItem = arrayOfItems[i];
      if (!this.parentInArray(thisItem, arrayOfItems)) {
        rootItems.push(thisItem);
      }
    }
    return rootItems;
  }

  // returns true if the given item has any children contained within the
  // given array
  hasChildrenInArray(inputItem: TreeNode, arrayOfItems: TreeNode[]): boolean {
    for (let i = 0; i < arrayOfItems.length; i++) {
      let thisItem = arrayOfItems[i];
      if (thisItem['parent_id'] == inputItem['id']) {
        return true;
      }
    }
    return false;
  }

  // returns an array of all descendents of the given item in the given array
  // (i.e., all children, grandchildren, etc.)
  getDescendents(inputItem: TreeNode, arrayOfItems: TreeNode[]): TreeNode[] {
    let descendents = [];
    for (let i = 0; i < arrayOfItems.length; i++) {
      let thisItem = arrayOfItems[i];
      if (thisItem['parent_id'] == inputItem['id']) {
        descendents.push(thisItem);
        let itsDescendents = this.getDescendents(thisItem, arrayOfItems);
        descendents = descendents.concat(itsDescendents);
      }
    }
    return descendents;
  }

  // returns true if the parent of the specified item is contained within the
  // given array
  parentInArray(inputItem: TreeNode, arrayOfItems: TreeNode[]): boolean {
    for (let i = 0; i < arrayOfItems.length; i++) {
      let thisItem = arrayOfItems[i];
      if (thisItem['id'] == inputItem['parent_id']) {
        return true;
      }
    }
    return false;
  }

  /////////////////////////////////////////////////////////////////////////////
  // functions related to modifying item edit form
  /////////////////////////////////////////////////////////////////////////////

  // given a user email, add this email to item assignees and also generate a
  // new share permission corresponding to this email specific for this item
  // (triggered by adding a user with an inherited share permission as an
  // assignee)
  addUserAsAssigneeWithExplicitPerm(userEmail: string): void {
    let sharePermission = this.itemBeingEdited.shared_with.filter(function (p) {
      return p.user.email == userEmail;
    })[0];
    sharePermission.type = 'Explicit';
    this.itemBeingEdited.assignees.push(userEmail);
  }

  // given a user email as well as whether that email is an assignee,
  // adjust whether the user email is an assignee to be the opposite of what it
  // is currently
  // (triggered by toggling the "is assignees" checkbox)
  toggleWhetherAssignee(userEmail: string, isCurrentlyAssignee: boolean): void {
    if (isCurrentlyAssignee) {
      // remove from assignees
      let currentAssignees = this.itemBeingEdited.assignees;
      let index = currentAssignees.indexOf(userEmail);
      if (index > -1) {
        currentAssignees.splice(index, 1);
      }
    } else {
      // add to assignees as well as add new explicity share permission
      // corresponding to this email
      this.itemBeingEdited.assignees.push(userEmail);
    }
  }

  // given a user email as well as whether that email is an subscriber,
  // adjust whether the user email is an subscriber to be the opposite of what it
  // is currently
  // (triggered by toggling the "is subscribers" checkbox)
  toggleWhetherSubscriber(isCurrentlySubscriber: boolean): void {
    let userEmail = this.auth.getCurrentUser().email;

    if (isCurrentlySubscriber) {
      // remove from subscribers
      let currentSubscribers = this.itemBeingEdited.subscribers;
      let index = currentSubscribers.indexOf(userEmail);
      if (index > -1) {
        currentSubscribers.splice(index, 1);
      }
    } else {
      // add to subscribers as well as add new explicity share permission
      // corresponding to this email
      this.itemBeingEdited.subscribers.push(userEmail);
    }
  }

  // removes a share permission from an item's edit form as well as the user
  // email from the list of the item's assignees and subscribers
  removeSharePermission(permission: any): void {
    // remove from item permissions
    this.itemBeingEdited.shared_with = this.itemBeingEdited.shared_with.filter(
      function (p) {
        return p.user.email !== permission.user.email;
      }
    );

    // also remove user email from list of assignees
    let currentAssignees = this.itemBeingEdited.assignees;
    let index = currentAssignees.indexOf(permission.user.email);
    if (index > -1) {
      currentAssignees.splice(index, 1);
    }

    // also remove user email from list of subscribers
    let currentSubscribers = this.itemBeingEdited.subscribers;
    index = currentSubscribers.indexOf(permission.user.email);
    if (index > -1) {
      currentSubscribers.splice(index, 1);
    }
  }

  // removes a tag from an item's edit form
  removeTag(tag: any): void {
    // remove from item tags
    this.itemBeingEdited.tags = this.itemBeingEdited.tags.filter(function (t) {
      return t !== tag;
    });
  }

  // adds a share permission to the item by showing a new available share permission html element
  addSharePermission($event: any): void {
    this.itemBeingEdited.shared_with.push({
      id: -1,
      user: { id: -1, email: '', display_name: '' },
      type: 'Explicit',
    });
    $('#addSharePermissionMessage').text(
      'New users, assignees will be notified by email (if they have a preview account).'
    );
  }

  // adds a tag to the item by showing a new available "add tag" input element
  addTag($event: any): void {
    this.itemBeingEdited.tags.push('');
  }

  // show full history of this item
  showFullHistory(): void {
    // add to list of node ids with expanded full history displayed
    let nodeId = this.itemBeingEdited.id;
    let fullHistoryExpanded = this.fullHistoryExpanded;
    fullHistoryExpanded.push(nodeId);
  }

  // show only most recent history of this item
  hideFullHistory(): void {
    // remove node from fullHistoryExpanded
    let nodeId = this.itemBeingEdited.id;
    let fullHistoryExpanded = this.fullHistoryExpanded;
    let index = fullHistoryExpanded.indexOf(nodeId);
    fullHistoryExpanded.splice(index, 1);
  }

  /////////////////////////////////////////////////////////////////////////////
  // functions related to generating item colors
  /////////////////////////////////////////////////////////////////////////////
  // given an arbitrary string, returns a string hsl representation of a color
  // based on a hardwired mapping function
  generateColorFromString(str: string): string {
    if (str.toLowerCase() == 'project') {
      return 'rgb(150,185,220)';
    } else if (str.toLowerCase() == 'task') {
      return 'rgb(191,186,207)';
    } else if (str == '') {
      return 'rgb(168,179,219)';
    } else {
      return this.constrainToColorRange(
        this.toHSL(this.intToRGB(this.hashCode(str)))
      );
    }
  }

  // given a string, returns an integer code corresponding to that string
  // (used in generating colors for items based on their types)
  hashCode(str: string): number {
    // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  // given an integer, returns a hex value corresponding to a css color that
  // falls within the web-safe color palette
  intToRGB(i: number): string {
    // get hex value
    let h = i & 0x00ffffff;

    // format as hex string
    let str = h.toString(16).toUpperCase();
    str = '00000'.substring(0, 6 - str.length) + str;

    return str;
  }

  // given a hex value as a string that represents a color, returns an hsl
  // representation of the same color
  toHSL(hex: string): number[] {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);

    (r /= 255), (g /= 255), (b /= 255);
    var max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    var h,
      s,
      l = (max + min) / 2;

    if (max == min) {
      h = s = 0; // achromatic
    } else {
      var d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    s = s * 100;
    s = Math.round(s);
    l = l * 100;
    l = Math.round(l);
    h = Math.round(360 * h);

    return [h, s, l];
  }

  // given an array of 3 numbers corresponding to an hsl color value,
  // map this color to one that falls within a specified color range
  // and return a string hsl representation of that new color
  constrainToColorRange([h, s, l]: number[]): string {
    // constrain saturation value to one that falls within range of colors
    // that isn't blindingly bright
    let minS = 0;
    let maxS = 70;
    let rangeS = maxS - minS;
    let newS = Math.round(minS + rangeS * (s / 100));

    // constrain luminence value to one that falls within range of colors
    // light enough to be backgrounds for black text but dark enough to stand
    // out from the light grey dashboard background
    let minL = 76;
    let maxL = 85;
    let rangeL = maxL - minL;
    let newL = Math.round(minL + rangeL * (l / 100));

    // constrain hue value to one that falls within blue/green range of colors
    let minH = 206;
    let maxH = 272;
    let rangeH = maxH - minH;
    let newH = Math.round(minH + rangeH * (h / 358));

    let colorInHSL = 'hsl(' + newH + ', ' + newS + '%, ' + newL + '%)';
    return colorInHSL;
  }

  /////////////////////////////////////////////////////////////////////////////
  // other miscellaneous helper functions
  /////////////////////////////////////////////////////////////////////////////

  // show and then fade the search bar dropdown after a slight delay
  // (called on click of search bar)
  delayFade(): void {
    $('#searchBarDropdown').show();
    $('#searchBarDropdown').delay(5000).fadeOut(400);
  }

  // returns whether this user has reached their limit for number of items they
  // are allowed to add per month
  //
  // If they are a Pro user there is no limit (always returns false); otherwise,
  // returns whether the user has already added the maximum number of items for
  // this month
  addLimitReached(): boolean {
    if (this.isProAccount) {
      return false;
    } else {
      let limitReached = this.numItemsAddedThisMonth() >= this.maxNumAdds;
      return limitReached;
    }
  }

  getPath(): Object[] {
    let zoomedItem = this.getZoomedItem();
    let path = [];
    if (zoomedItem) {
      path = this.getPathHelper(zoomedItem, path);
    }
    return path;
  }

  getPathHelper(node: TreeNode, pathSoFar: Object[]): Object[] {
    if (node.data.virtual) {
      // if the node is virtual; i.e., it is the parent of a root node
      return pathSoFar;
    } else {
      pathSoFar.unshift({
        title: node.data['title'],
        url: '#/'.concat(node.data['id']),
      });
      return this.getPathHelper(node.parent, pathSoFar);
    }
  }

  // if the browser url indicates that the tree should be zoomed in to a
  // particular item, set "displayedItems" equal to that item so that only that
  // item and its descendents are displayed
  applyItemFilter(): void {
    let zoomedItem = this.getZoomedItem();
    this.zoomToItem(zoomedItem);
    this.applyTextFilter();
  }

  // given an item, filter the displayed tree to show just that item and its
  // descendents
  zoomToItem(item: any): void {
    if (item) {
      // update displayed nodes to include only the zoomed in item and its
      // descendents
      this.itemsDisplayedTreeNodes = [ item.data ];
      // this.displayedItems.treeModel.nodes = [item.data];
      // this.displayedItems.treeModel.update();
    } else {
      // if not a valid id, set displayed nodes equal to all nodes and set url
      // anchor hash to indicate an empty string
      this.itemsDisplayedTreeNodes = [...this.itemsAllTreeNodes];
      // this.displayedItems.treeModel.nodes = this.allItems.treeModel.nodes;
      // this.displayedItems.treeModel.update();
      window.location.hash = '#';
    }
    this.itemPath.set(this.getPath());
  }

  // returns the tree node corresponding to the id specified in the URL anchor
  // if it exists; otherwise, returns null
  getZoomedItem(): TreeNode {
    let zoomedItemId = this.getUrlAnchorValue();
    if (!zoomedItemId) {
      return null;
    } else {
      return this.itemsAllTree.getTreeNodeById(zoomedItemId);
    }
  }

  // return the segment of the current URL that follows the "/#/" substring, if
  // it exists; otherwise, returns null
  // this segment of the URL indicates the item currently zoomed into, if any
  // (i.e., the item that is made the root of the tree in the current view)
  getUrlAnchorValue(): string {
    let urlAnchor = window.location.hash;
    if (urlAnchor == '' || urlAnchor == '#/') {
      return null;
    } else {
      let id = urlAnchor.substring(2); // remove '#/' prefix
      return id;
    }
  }

  // deactivate and blur any focused items (tree nodes) if click away from item
  setFocus($event: any): void {
    let clickedOnItem = this.clickedOnItem($event);
    if (!clickedOnItem) {
      this.blurFocusedNodes();
    }
  }

  // deactivate and blur any activated or focused nodes
  blurFocusedNodes(): void {
    if(this.itemsDisplayedTree){
      this.itemsDisplayedTree.blurFocusedNodes();
    }
  }

  // given a click event, returns whether the click was on an item
  clickedOnItem($event): boolean {
    let htmlElemPath = $event.path;
    let onItem = this.containsItemElem(htmlElemPath);
    return onItem;
  }

  // given a set of html elements, return whether any of them are items in the
  // item tree (i.e., have a class of "item")
  containsItemElem(elems: any[]): boolean {
    if (!elems) return false;
    let itemElems = elems.filter(function (e) {
      if (e.className) {
        return e.className.split(' ').indexOf('item') > -1;
      } else {
        return false;
      }
    });
    let hasItems = itemElems.length > 0;
    return hasItems;
  }

  // given an item, return deduped, alphabetically ordered array of emails
  // corresponding to all share permissions associated with item tree, minus
  // those already in the current item's permissions
  // (for populating suggested emails dropdown on share permission editing)
  getSuggestedEmails(item: any): string[] {
    let currentPermissions = item['shared_with'];

    let emails = [];
    this.itemsAllTreeNodes.forEach(item => {
      this.getSuggestedEmailsHelper(item, emails);
    });

    // remove emails that are already in current permissions
    emails = emails.filter(function (e) {
      let matchingCurrentPermissions = currentPermissions.filter(function (p) {
        return p['user']['email'] == e;
      });
      let isInCurrentPermissions = matchingCurrentPermissions.length > 0;
      if (!isInCurrentPermissions) {
        return true;
      } else {
        return false;
      }
    });

    emails = this.dedupArray(emails);
    emails.sort();
    return emails;
  }

  // given an item and a set of relevant emails so far, add all emails
  // corresponding to this item's share permissions to the emails so far
  // and do the same for its children
  getSuggestedEmailsHelper(item: any, emailsSoFar: string[]): void {
    let permissions = item['shared_with'];
    for (let p = 0; p < permissions.length; p++) {
      let permission = permissions[p];
      let email = permission['user']['email'];
      emailsSoFar.push(email);
    }
    let children = item['children'];
    if (children.length) {
      for (let c = 0; c < children.length; c++) {
        let child = children[c];
        this.getSuggestedEmailsHelper(child, emailsSoFar);
      }
    }
  }

  // given a string corresponding to a dashboard style theme, set dashboard
  // display to match this theme
  setDashboardTheme(theme: string): void {
    if (theme == 'seaTheme') {
      $('#mainDashboardWrapper').attr('class', 'seaTheme');
      $('#nightSkyBackground').hide();
      $('#dashboardContent').attr('class', 'sea-background');
      $('#defaultTheme').removeClass('selected');
      $('#nightSkyTheme').removeClass('selected');
      $('#seaTheme').addClass('selected');
      this.dashboardTheme = 'seaTheme';
    } else if (theme == 'nightSkyTheme') {
      $('#mainDashboardWrapper').attr('class', 'nightSkyTheme');
      $('#dashboardContent').removeClass();
      $('#nightSkyBackground').show();
      $('#defaultTheme').removeClass('selected');
      $('#seaTheme').removeClass('selected');
      $('#nightSkyTheme').addClass('selected');
      this.dashboardTheme = 'nightSkyTheme';
    } else {
      // else set to default theme
      $('#mainDashboardWrapper').attr('class', 'defaultTheme');
      $('#nightSkyBackground').hide();
      $('#dashboardContent').attr('class', 'default-background');
      $('#nightSkyTheme').removeClass('selected');
      $('#seaTheme').removeClass('selected');
      $('#defaultTheme').addClass('selected');
      this.dashboardTheme = 'defaultTheme';
    }
  }

  // a helpful function for de-duplicating the elements of an array
  dedupArray(jsArray: any[]): any[] {
    let deduppedArray = [];
    $.each(jsArray, function (i, el) {
      if ($.inArray(el, deduppedArray) === -1) deduppedArray.push(el);
    });
    return deduppedArray;
  }

  // given an array of tags, return that same array but with a '#' character
  // concatenated to the beginning of each of them
  addHashTags(arrayOfTags: string[]): string[] {
    let newArray = [];
    for (let i = 0; i < arrayOfTags.length; i++) {
      let thisTag = arrayOfTags[i];
      let newTag = '#'.concat(thisTag);
      newArray.push(newTag);
    }
    return newArray;
  }

  // modify nav bar save button to reflect that there are new changes currently
  // being saved
  changesBeingSavedDisplay(): void {
    $('#navBarSaveButton').prop('disabled', true);
    $('#navBarSaveButton').text('Saving');
  }

  // modify nav bar save button to reflect that there are new changes that
  // haven't yet been saved
  unsavedChangesDisplay(): void {
    $('#navBarSaveButton').text('Save');
    $('#navBarSaveButton').prop('disabled', false);
  }

  // modify nav bar save button to reflect that all dashboard changes have been
  // saved
  allChangesSavedDisplay(): void {
    $('#navBarSaveButton').text('Saved');
    $('#navBarSaveButton').prop('disabled', true);
  }

  // given an array of strings, return an object storing the number of times each
  // distinct strings appears in the array; e.g., ['tag1','tag2','tag2','tag2'] ->
  // {'tag1': 1, 'tag2': 3}
  getCounts(strArray: string[]): Object {
    let counts = {};
    for (let i = 0; i < strArray.length; i++) {
      let thisStr = strArray[i];
      if (!counts[thisStr]) {
        counts[thisStr] = 1;
      } else {
        counts[thisStr] = counts[thisStr] + 1;
      }
    }
    return counts;
  }

  // given an item id, adds/removes the item to/from the current list of items
  // with details expanded (called when user clicks the "expand/collapse item
  // details" button)
  toggleDetailsExpanded(itemId: number): void {
    let nodeId = itemId;
    let listExpandedNodeIds = this.detailsExpanded;
    let isExpanded = listExpandedNodeIds.indexOf(nodeId) != -1;

    if (isExpanded) {
      // remove this node's id from list of details expanded node ids
      let index = listExpandedNodeIds.indexOf(nodeId);
      if (index > -1) {
        listExpandedNodeIds.splice(index, 1);
      }
    } else {
      listExpandedNodeIds.push(nodeId);
    }
  }

  // display a modal dialog with the intro tutorial informing user of how to
  // use ProjectZen
  showWelcomeTutorial(): void {
    $('#welcomeTutorialDialog').modal('show');
  }

  // logout function for when click logout button
  logout(): void {
    let self = this;

    this.loadingService.show();

    // wait until no further unsaved changes before logging out
    if (this.unsavedChanges.length > 0) {
      setTimeout(() => {
        self.logout();
      }, 3000);
    } else {
      this.auth.logout().then((data) => {
        $('.modal').modal('hide');
        this.loadingService.hide();
        this.router.navigate(['login']);
      });
    }
  }

  // necessary function for the "trackBy" statement in *ngModel inside *ngFor
  // within item.component.html
  trackByIndex(index: number, obj: any): any {
    return index;
  }

  // Displays a dialog box that enables the user to edit an item
  showEditItemDialog(): void {
    $('#editItemDialog').modal({ backdrop: 'static', keyboard: false });
  }

  // Hides dialog box that enables the user to edit an item
  hideEditItemDialog(): void {
    $('#editItemDialog').modal('hide');
  }

  // display a modal dialog informing user that in order to add more items they
  // should upgrade to a Pro account
  showLimitReachedDialog(): void {
    $('#limitReachedDialog').modal('show');
  }

  // when page initialized, show "Click to expand details" instructional popover
  showExpandProjectPopover(): void {
    $(document).ready(function () {
      $('[data-toggle="popover"]').popover();
      $('.my-first-project-item * .expand-project-popover').popover('show');
    });
  }

  // given text string, filter the item tree to just those nodes whose headers
  // contain that text string (not case sensitive), as well as their parents up
  // the hierarchy
  //  - additionally, if includes certain special keywords (e.g., "status:"),
  //    additionally filter out any items whose status field does not match the
  //    following value
  //  - (used by the search bar for filtering item tree)
  applyTextFilter(): void {
    let text = $('#filterInput').val();
    text = text.toLowerCase().trim(); // clean text, make lower case

    // possible included keywords
    let keywords = [
      { name: 'status', value: 'status:' },
      { name: 'priority', value: 'priority:' },
      { name: 'description', value: 'description:' },
      { name: 'title', value: 'title:' },
      { name: 'type', value: 'type:' },
      { name: 'isDescendentOf', value: 'isdescendentof:' },
      { name: 'id', value: 'id:' },
      { name: 'tags', value: '#' },
    ];

    // parse input text as list of query objects
    let parsedQuery = [];
    parsedQuery.push({
      keyword: { name: 'header', value: '' },
      index: 0,
      value: '',
    }); // by default query item headers

    // for each instance of keyword in text, push an additional query field object into array
    for (let i = 0; i < keywords.length; i++) {
      let thisKeyword = keywords[i];
      let keywordIndex = text.indexOf(thisKeyword['value']);

      while (keywordIndex >= 0) {
        parsedQuery.push({
          keyword: thisKeyword,
          index: keywordIndex,
          value: '',
        });
        keywordIndex = text.indexOf(thisKeyword['value'], keywordIndex + 1); // set to index of next instance of keyword
      }
    }

    // sort query field objects by index
    parsedQuery.sort(function (a, b) {
      return a['index'] - b['index'];
    });

    // fill in values for each query field object
    for (let i = 0; i < parsedQuery.length; i++) {
      let queryField = parsedQuery[i];
      let nextQueryField = parsedQuery[i + 1];
      let startInd =
        queryField['index'] + queryField['keyword']['value'].length;
      let endInd;
      if (nextQueryField) {
        endInd = nextQueryField['index'];
      } else {
        endInd = text.length;
      }

      let queryVal = text.substring(startInd, endInd);
      queryVal = queryVal.trim();
      queryField['value'] = queryVal;
    }

    // get default tree that would be shown without any filter
    let defaultTree;
    let zoomedItem = this.getZoomedItem();
    if (zoomedItem) {
      defaultTree = [zoomedItem.data];
    } else {
      defaultTree = this.itemsAllTreeNodes;
    }

    // get new tree to be displayed after filter applied
    const defaultTreeClone = $.extend(true, [], defaultTree);
    const defaultArray = this.convertFromTreeStructure(defaultTreeClone, []);
    const self = this;
    const newDisplayedArray = defaultArray.filter(function (item) {
      return self.isMatch(item, parsedQuery);
    });
    this.itemsDisplayedTreeNodes = this.convertToTreeStructure(newDisplayedArray);
    // this.displayedItems.treeModel.nodes = newDisplayedTree;
    // this.displayedItems.treeModel.update();
  }

  isMatch(item: Object, parsedQuery: Object[]): boolean {
    let isMatch = true;

    // for each query field, set isMatch to false if item fields don't match logic
    for (let i = 0; i < parsedQuery.length; i++) {
      let thisQueryField = parsedQuery[i];

      // if queries by ancestor
      if (thisQueryField['keyword']['name'] == 'isDescendentOf') {
        const ancestorId = thisQueryField['value'];
        const ancestor = this.itemsAllTree.getTreeNodeById(ancestorId);
        if (ancestor) {
          window.location.hash = '/'.concat(ancestorId);
        }
      }

      // if queries item header
      else if (thisQueryField['keyword']['name'] == 'header') {
        // get words in query
        let wordsInQuery = thisQueryField['value'].split(' ');
        for (let i = 0; i < wordsInQuery.length; i++) {
          let wordInQuery = wordsInQuery[i];
          wordInQuery.trim();
        }

        // get words in item header
        let itemHeader = [
          item['title'],
          item['status'],
          item['priority'],
          item['tags'].join(' '),
        ]
          .join(' ')
          .toLowerCase()
          .trim();
        let wordsInItemHeader = itemHeader.split(' ');
        for (let i = 0; i < wordsInItemHeader.length; i++) {
          let wordInItemHeader = wordsInItemHeader[i];
          wordInItemHeader.trim();
        }

        // check for any match
        for (let i = 0; i < wordsInQuery.length; i++) {
          let wordInQuery = wordsInQuery[i];
          let anyMatch = false;

          for (let j = 0; j < wordsInItemHeader.length; j++) {
            let wordInItemHeader = wordsInItemHeader[j];
            if (wordInItemHeader.indexOf(wordInQuery) >= 0) {
              anyMatch = true;
            }
          }

          if (!anyMatch) {
            isMatch = false;
          }
        }
      }

      // if queries item tags
      else if (thisQueryField['keyword']['name'] == 'tags') {
        // get tags in query
        let tagsInQuery = thisQueryField['value'].split(' ');
        for (let i = 0; i < tagsInQuery.length; i++) {
          let tagInQuery = tagsInQuery[i];
          tagInQuery.trim();
        }
        tagsInQuery = tagsInQuery.filter(function (tag) {
          return tag != '';
        });

        // get item tags
        let itemTags = item['tags'];
        for (let i = 0; i < itemTags.length; i++) {
          let itemTag = itemTags[i];
          itemTag.trim();
        }

        // check for any match
        for (let i = 0; i < tagsInQuery.length; i++) {
          let tagInQuery = tagsInQuery[i];
          let anyMatch = false;

          for (let j = 0; j < itemTags.length; j++) {
            let itemTag = itemTags[j];
            if (itemTag.indexOf(tagInQuery) >= 0) {
              anyMatch = true;
            }
          }
          if (!anyMatch) {
            isMatch = false;
          }
        }
      }

      // else
      else {
        let itemVal = item[thisQueryField['keyword']['name']]
          .toLowerCase()
          .trim();
        if (!itemVal.includes(thisQueryField['value'])) {
          isMatch = false;
        }
      }
    }
    return isMatch;
  }

  // prints a console log of the currently active nodes in the tree
  getActiveNodes(treeModel: any): void {
    if (environment.debuggingConsoleLogsOn) {
      console.log(treeModel.activeNodes);
    }
  }


  // given a tree node, update its inherited share permissions as well as those for its
  // descendents
  updateInheritedPermissionsForItemAndDescendents(node: any): void {
    // get useful variables
    let existingInheritedPermissions = this.getInheritedPermissions(node.data);
    let existingExplicitPermissions = this.getExplicitPermissions(node.data);
    let parentNode = node.parent;
    let permissionsToInherit = [];
    if (!parentNode?.data?.virtual) {
      permissionsToInherit = parentNode?.data?.shared_with || [];
    }

    // remove any permissions that should no longer be inherited
    for (let i = 0; i < existingInheritedPermissions.length; i++) {
      let p = existingInheritedPermissions[i];
      let y = permissionsToInherit.filter(function (e) {
        return e['user']['email'] == p['user']['email'];
      });
      if (y.length == 0) {
        p['toBeRemoved'] = true;
      }
    }
    node.data.shared_with = existingInheritedPermissions
      .filter(function (p) {
        return p['toBeRemoved'] != true;
      })
      .concat(existingExplicitPermissions);

    // add any that should be inherited if not already there
    for (let i = 0; i < permissionsToInherit.length; i++) {
      let permToInherit = permissionsToInherit[i];
      let y = node.data['shared_with'].filter(function (e) {
        return e['user']['email'] == permToInherit['user']['email'];
      });
      if (y.length == 0) {
        let newPerm = {
          user: { email: permToInherit['user']['email'] },
          type: 'Inherited',
        };
        node.data['shared_with'].push(newPerm);
      }
    }

    this.itemsAllTree.updateModel();
    this.applyItemFilter();

    // add current user permission back in if missing
    let self = this;
    let currentUserStillInSharedWith =
      node.data['shared_with'].filter(function (e) {
        return e['user']['email'] == self.auth.getCurrentUser().email;
      }).length > 0;
    if (currentUserStillInSharedWith == false) {
      let currentUserPermission = {
        type: 'Explicit',
        user: {
          display_name: this.auth.getCurrentUser().displayName,
          firebase_uid: this.auth.getCurrentUser().uid,
          email: this.auth.getCurrentUser().email,
        },
      };
      node.data['shared_with'].push(currentUserPermission);
    }

    this.itemsAllTree.updateModel();
    this.applyItemFilter();

    // do the same for its descendents
    if (node.data.children) {
      for (let i = 0; i < node.data.children.length; i++) {
        let child = node.data.children[i];
        let childNode = this.itemsAllTree.getTreeNodeById(child['id']);
        this.updateInheritedPermissionsForItemAndDescendents(childNode);
      }
    }
  }

  // given a node, make all of its inherited permissions to be instead explicit
  // and do the same for its descendents
  makeAllInheritedPermissionsExplicit(node: any): void {
    // for each inherited permission, set it to be explicit
    if (node) {
      let existingInheritedPermissions = this.getInheritedPermissions(
        node.data
      );
      for (let i = 0; i < existingInheritedPermissions.length; i++) {
        let inherPerm = existingInheritedPermissions[i];
        inherPerm['type'] = 'Explicit';
      }
      this.itemsAllTree.updateModel();
      this.applyItemFilter();

      // do the same for this node's descendents
      if (node.data['children']) {
        for (let c = 0; c < node.data['children'].length; c++) {
          let child = node.data['children'][c];
          let childNode = this.itemsAllTree.getTreeNodeById(child['id']);
          this.makeAllInheritedPermissionsExplicit(childNode);
        }
      }
    }
  }

  // giving an array of permissions, return the same array but with all of them
  // set to be inherited
  makeInherited(permissions: any[]): any[] {
    let newPermissions = $.extend(true, [], permissions);
    for (let i = 0; i < newPermissions.length; i++) {
      let p = newPermissions[i];
      p['type'] = 'Inherited';
    }
    return newPermissions;
  }

  // when the user drags and drops an item to a different position within the
  // tree,
  //   - update the item's corresponding parent_id and display_order attributes
  //   - add a corresponding "event" object to a "tree updates" variable so that
  //     this is eventually saved to the backend
  onMovedNode($event: any): void {
    this.timeOfLastItemUpdate.set(new Date());

    // get necessary variables
    let itemMovedId = $event.node.id;
    let itemMoved = this.itemsAllTree.getTreeNodeById(itemMovedId);
    let oldParentId = itemMoved.data['parent_id'];
    let oldParent = null;
    let oldParentTitle = null;
    if (oldParentId) {
      oldParent = this.itemsAllTree.getTreeNodeById(oldParentId);
      oldParentTitle = oldParent.data['title'];
    }
    let newParent = $event.to.parent;
    let newParentId;
    if (newParent.virtual) {
      newParentId = null;
    } else {
      newParentId = newParent.id;
    }
    let newParentTitle = null;
    if (newParentId) {
      newParentTitle = newParent['title'];
    }
    let newIndex = $event.to.index;

    let newOlderSib = newParent.children[newIndex - 1];
    let DisplayOrderOS;
    if (newOlderSib) {
      DisplayOrderOS = newOlderSib['display_order'];
    } else {
      DisplayOrderOS = null;
    }
    let newYoungerSib = newParent.children[newIndex + 1];
    let DisplayOrderYS;
    if (newYoungerSib) {
      DisplayOrderYS = newYoungerSib['display_order'];
    } else {
      DisplayOrderYS = null;
    }

    let newDisplayOrder;
    let randomFraction = Math.random();
    if (newOlderSib && newYoungerSib) {
      // if item now has both a new older and a new younger sibling
      let diff = DisplayOrderYS - DisplayOrderOS;
      newDisplayOrder = DisplayOrderOS + randomFraction * diff;
    } else if (newOlderSib) {
      // if item now has only an older sibling
      newDisplayOrder = DisplayOrderOS + randomFraction * 10;
    } else if (newYoungerSib) {
      // if item now has only a younger sibling
      newDisplayOrder = DisplayOrderYS - randomFraction * 10;
    } else {
      // if item now has no siblings, set to some arbitrary default value
      newDisplayOrder = 0;
    }

    itemMoved.data['parent_id'] = newParentId;
    itemMoved.data['display_order'] = newDisplayOrder;

    this.itemsAllTree.updateModel();
    this.applyItemFilter();
    this.updateInheritedPermissionsForItemAndDescendents(itemMoved);

    // add action to array of newest changes
    let itemUpdate = {
      type: 'updateItem',
      timestamp: new Date(),
      data: { itemId: itemMovedId, item: itemMoved.data },
    };
    this.queueItemUpdate(itemUpdate);
    this.scheduleAutoSave();
  }

  // given a json array of items retrieved from the backend, convert into a
  // nested structure that's compliant with the way the angular tree library
  // wants it
  convertToTreeStructure(arrayOfItems: any[]): any[] {
    return this.convertToTreeStructureHelper(arrayOfItems, null);
  }

  // helper for convertToTreeStructure()
  convertToTreeStructureHelper(
    arrayOfItems: any[],
    parentId: number
  ): any[] {
    const output: Array<any> = [];

    // get all those items that don't have a parent within the array
    let rootItems = this.getRootItems(arrayOfItems);
    rootItems = this.sortByKey(rootItems, 'display_order'); // sort root items by display order
    for (let i = 0; i < rootItems.length; i++) {
      let thisRootItem = rootItems[i];

      // this node's parent isn't in this tree but is in the main dashboard tree, this means the node is
      // a root node in trash and therefore should retain original parent id value in case it's
      // moved out of trash back into the dashboard (in which case we want to move it back to its
      // original position under the same parent if possible)
      if (parentId == null && thisRootItem['parent_id'] != null) {
        if (this.itemsAllTree.getTreeNodeById(thisRootItem['parent_id'])) {
          parentId = thisRootItem['parent_id'];
        }
      }

      let reformattedItem = {};
      reformattedItem['id'] = thisRootItem['id'];
      reformattedItem['parent_id'] = parentId;

      reformattedItem['in_trash'] = thisRootItem['in_trash'];
      reformattedItem['title'] = thisRootItem['title'];
      reformattedItem['type'] = thisRootItem['type'];
      reformattedItem['status'] = thisRootItem['status'];
      reformattedItem['priority'] = thisRootItem['priority'];
      reformattedItem['description'] = thisRootItem['description'];
      reformattedItem['shared_with'] = $.extend(
        true,
        [],
        thisRootItem['shared_with']
      );
      reformattedItem['tags'] = $.extend(true, [], thisRootItem['tags']);
      reformattedItem['assignees'] = $.extend(
        true,
        [],
        thisRootItem['assignees']
      );
      reformattedItem['subscribers'] = $.extend(
        true,
        [],
        thisRootItem['subscribers']
      );
      reformattedItem['permission_type'] = thisRootItem['permission_type'];

      // convert datetimes within item history records to proper javascript format
      let historyDeepCopy = $.extend(true, [], thisRootItem['history']);
      for (let i = 0; i < historyDeepCopy.length; i++) {
        let record = historyDeepCopy[i];
        record.timestamp = new Date(record.timestamp);
      }

      // and sort records by most recent first
      historyDeepCopy.sort(function (a, b) {
        return b.timestamp - a.timestamp;
      });
      reformattedItem['history'] = historyDeepCopy;
      reformattedItem['display_order'] = thisRootItem['display_order'];
      reformattedItem['subitems_expanded'] = false;
      if (!this.hasChildrenInArray(thisRootItem, arrayOfItems)) {
        reformattedItem['hasChildren'] = false;
        reformattedItem['children'] = [];
        output.push(reformattedItem);
      } else {
        reformattedItem['hasChildren'] = true;
        reformattedItem['children'] = this.convertToTreeStructureHelper(
          this.getDescendents(thisRootItem, arrayOfItems),
          reformattedItem['id']
        );
        output.push(reformattedItem);
      }
    }
    return output;
  }

  // given a json array of items nested as a tree structure as well as the
  // array of items so far, return all items as an array
  convertFromTreeStructure(itemTree: any[], arraySoFar: any[]): any[] {
    let outputArray = $.extend(true, [], arraySoFar);

    for (let i = 0; i < itemTree.length; i++) {
      // push item to array
      let thisItem = itemTree[i];
      let clonedItem = $.extend(true, {}, thisItem);
      clonedItem['children'] = [];
      clonedItem['hasChildren'] = false;
      outputArray.push(clonedItem);

      // push its children to the array
      let itemChildren = thisItem['children'];
      if (itemChildren) {
        outputArray = this.convertFromTreeStructure(itemChildren, outputArray);
      }
    }

    return outputArray;
  }

  // given an item, modifies all of its history records to have their 'new'
  // values equal to false
  historyNoLongerNew(item: any): any {
    let itemHistory = item['history'];
    let newItemHistory = [];
    for (let i = 0; i < itemHistory.length; i++) {
      let historyRecord = itemHistory[i];
      let newRecord = $.extend(true, {}, historyRecord);
      newRecord['new'] = false;
      newItemHistory.push(newRecord);
    }
    let newItem = $.extend(true, {}, item);
    newItem['history'] = newItemHistory;
    return newItem;
  }

  // helper function to sort an array of json objects by a particular object
  // field
  sortByKey(array: any[], key: any): any[] {
    return array.sort(function (a, b) {
      let x = a[key];
      let y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  // given a dictionary representing an item as well as a boolean of the new
  // value its 'in_trash' property should be (as well as for its descendents),
  //
  // update its 'in_trash' property to be this and do the same for its
  // descendents
  updateInTrashPropertyForItemAndDescendents(
    item: any,
    newValue: boolean
  ): void {
    item['in_trash'] = newValue;

    if (item['children']) {
      for (let i = 0; i < item['children'].length; i++) {
        let child = item['children'][i];
        this.updateInTrashPropertyForItemAndDescendents(child, newValue);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // functions related to modifying general account settings
  /////////////////////////////////////////////////////////////////////////////
  // display applicable errors, else generate a temporary token corresponding to
  // the input credit card and send it to the server such to upgrade sql backend
  // and stripe api to show account is upgraded. then display appropriate
  // content to user
  handleProPaymentFormSubmit(): void {
    let self = this;

    // create a temporary token or display an error when the form is submitted
    self.stripeInstance
      .createToken(self.stripeCredCard)
      .then(function (result) {
        if (result.error) {
          // inform the customer that there was an error.
          let errorElement = document.getElementById('credCardFormErrors');
          errorElement.textContent = result.error.message;
        } else {
          // send credit card token to the server, upgrade this account to pro
          let credCardTokenObj = result.token;

          // disable form submit button
          $('#proActivationButton').attr('disabled', 'true');

          // insert the temporary credit card token into the form so it gets
          // submitted to the server
          self.insertCcTokenIntoForm(credCardTokenObj.id);

          // send data to backend to upgrade account
          self.ac
            .upgradeAccountToPro({ creditCardToken: credCardTokenObj.id })
            .then(function (response) {
              let result = response['result'];
              let lastFourDigits = response['lastFourDigits'];

              self.credCardLastFourDigits = lastFourDigits;
              self.isProAccount = true;
              self.proExpiryDate.set(null);

              if (environment.debuggingConsoleLogsOn) {
                console.log(result);
              }
              self.setProAccountAutoRenewDisplay();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
  }

  // make appropriate call to backend to deactivate pro account and then
  // show correct display
  deactivateProLinkHandler(): void {
    let self = this;

    self.ac
      .deactivateProForThisAccount()
      .then(function (response) {
        let result = response['result'];

        self.credCardLastFourDigits = '';
        self.proExpiryDate.set(response['pro_expiry_date']);

        if (environment.debuggingConsoleLogsOn) {
          console.log(result);
        }
        self.setProAccountSetToExpireDisplay();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // display the appropriate screen for inputing new credit card details
  changeCredCardLinkHandler(): void {
    this.setUpdateCredCardDetailsDisplay();
  }

  // reset to main pro account display again if press cancel on update credit
  // card details form
  cancelLinkHandler(): void {
    this.setProAccountAutoRenewDisplay();
  }

  // deletes this user's ProjectZen account by marking account as deleted in
  // backend SQL database and deleting associated account in firebase
  // authentication
  deleteUserAccount(): void {
    let self = this;

    this.ac
      .markAccountAsDeletedInSQL()
      .then(function (response) {
        if (environment.debuggingConsoleLogsOn) {
          console.log(response.result);
        }
        self.ac
          .deleteUserAccountInFirebase()
          .then(function () {
            if (environment.debuggingConsoleLogsOn) {
              console.log('Account deleted in firebase');
            }
            self.router.navigate(['home']); // and navigate to homepage
          })
          .catch(function (error) {
            console.log(error);
            if (error.code == 'auth/requires-recent-login') {
              $('#deleteAccountDialogBox').modal('hide');
              $('#reauthDialog').modal('show');
            }
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // updates this user's ProjectZen account password in firebase authentication
  // after verifying old password
  changeAccountPassword(): void {
    let self = this;
    let oldPassword = (<HTMLInputElement>document.getElementById('oldPassword'))
      .value;
    let newPassword = (<HTMLInputElement>document.getElementById('newPassword'))
      .value;
    self.ac
      .changeAccountPasswordInFirebase(oldPassword, newPassword)
      .then(function () {
        if (environment.debuggingConsoleLogsOn) {
          console.log('Password updated.');
        }

        // clear form and show confirmation text saying that password was successfully updated
        (<HTMLFormElement>(
          document.getElementById('updatePasswordForm')
        )).reset();
        $('#passwordChangedText').text('Password successfully updated.');
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // reset the "change password" dialog box to its original cleared values (called
  // each time it is reopened)
  resetChangePasswordDialog() {
    (<HTMLFormElement>document.getElementById('updatePasswordForm')).reset();
    $('#passwordChangedText').text('');
  }

  // show appropriate error message if applicable, else generate a temporary
  // token associated with the newly input credit card details and send it to
  // the server such to update both backend sql database and stripe api to show
  // updated credit card details. then display appropriate dashboard content.
  handleUpdateCardDetailsFormSubmit(): void {
    let self = this;

    // create a temporary token or display an error when the form is submitted
    self.stripeInstance
      .createToken(self.stripeCredCard)
      .then(function (result) {
        if (result.error) {
          // inform the customer that there was an error.
          let errorElement = document.getElementById('credCardFormErrors');
          errorElement.textContent = result.error.message;
        } else {
          // send credit card token to the server, upgrade this account to pro
          let credCardTokenObj = result.token;

          // disable form submit button
          $('#updateCredCardDetailsButton').attr('disabled', 'true');

          // insert the temporary credit card token into the form so it gets
          // submitted to the server
          self.insertCcTokenIntoForm(credCardTokenObj.id);

          // send data to backend to update credit card details for account
          self.ac
            .updateCredCardDetails({ creditCardToken: credCardTokenObj.id })
            .then(function (response) {
              let result = response['result'];
              let lastFourDigits = response['lastFourDigits'];

              self.credCardLastFourDigits = lastFourDigits;
              if (environment.debuggingConsoleLogsOn) {
                console.log(result);
              }
              self.setProAccountAutoRenewDisplay();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
  }

  // set various content and styling appropriate to an account that is not
  // currently subscribed to Pro
  setNonProAccountDisplay(): void {
    let self = this;

    // add stripe credit card input form if it isn't already there
    if (!self.isStripeCardMounted()) {
      self.stripeCredCard.mount('#stripeCredCardElement');
      self.isStripeCardMounted.set(true);
    }

    // show appropriate content in "Pro settings" dialog box and navigation bar
    $('#savedCreditCardElement').hide();
    $('#stripeCredCardElement').show();

    if (self.proExpiryDate()) {
      $('#expirationNotice').html(
        'This Pro account expired on ' + self.proExpiryDate() + ' UTC.'
      );
      $('#expirationNotice').addClass('expired');
      $('#expirationNotice').removeClass('will-expire');
      $('#expirationNotice').show();
    } else {
      $('#expirationNotice').hide();
    }

    $('#proActivationButton').html('Upgrade to Pro');
    $('#proActivationButton').removeAttr('disabled');
    $('#proActivationButton').removeClass('account-activated');
    $('#proActivationButtonWrapper').show();
    $('#updateCredCardDetailsButtonWrapper').hide();

    $('#deactivateLinkWrapper').hide();
    $('#cancelLinkWrapper').hide();
  }

  // set various content and styling appropriate to an account that has recently
  // cancelled their Pro subscription and will have it expire in the near future
  setProAccountSetToExpireDisplay(): void {
    let self = this;

    // add stripe credit card input form if it isn't already there
    if (!self.isStripeCardMounted()) {
      self.stripeCredCard.mount('#stripeCredCardElement');
      self.isStripeCardMounted.set(true);
    }

    // show appropriate content in "Pro settings" dialog box and navigation bar
    $('#savedCreditCardElement').hide();
    $('#stripeCredCardElement').show();

    $('#expirationNotice').html(
      'This Pro account is set to expire on ' + self.proExpiryDate() + ' UTC.'
    );
    $('#expirationNotice').removeClass('expired');
    $('#expirationNotice').addClass('will-expire');
    $('#expirationNotice').show();

    $('#proActivationButton').html('Reactivate Pro');
    $('#proActivationButton').removeAttr('disabled');
    $('#proActivationButton').addClass('account-activated');
    $('#proActivationButtonWrapper').show();
    $('#updateCredCardDetailsButtonWrapper').hide();

    $('#deactivateLinkWrapper').hide();
    $('#cancelLinkWrapper').hide();
  }

  // set various content and styling to indicate that this account has an
  // activated Pro subscription
  setProAccountAutoRenewDisplay(): void {
    let self = this;

    // remove stripe credit card input form if it isn't already removed
    if (self.isStripeCardMounted()) {
      self.stripeCredCard.unmount();
      self.isStripeCardMounted.set(false);
    }

    // show appropriate content in "Pro settings" dialog box and navigation bar
    $('#credCardText').text(
      '**** **** **** '.concat(this.credCardLastFourDigits)
    );
    $('#savedCreditCardElement').show();
    $('#stripeCredCardElement').hide();

    $('#expirationNotice').hide();

    $('#proActivationButton').html('Pro Account Activated');
    $('#proActivationButton').attr('disabled', 'true');
    $('#proActivationButton').addClass('account-activated');
    $('#proActivationButtonWrapper').show();
    $('#updateCredCardDetailsButtonWrapper').hide();

    $('#deactivateLinkWrapper').show();
    $('#cancelLinkWrapper').hide();
  }

  // set appropriate content and styling for updating credit card details
  setUpdateCredCardDetailsDisplay(): void {
    let self = this;

    // add stripe credit card input form if it isn't already there
    if (!self.isStripeCardMounted()) {
      self.stripeCredCard.mount('#stripeCredCardElement');
      self.isStripeCardMounted.set(true);
    }

    // show appropriate content in "Pro settings" dialog box and navigation bar
    $('#savedCreditCardElement').hide();
    $('#stripeCredCardElement').show();

    $('#expirationNotice').hide();

    $('#proActivationButtonWrapper').hide();
    $('#updateCredCardDetailsButton').removeAttr('disabled');
    $('#updateCredCardDetailsButtonWrapper').show();

    $('#deactivateLinkWrapper').hide();
    $('#cancelLinkWrapper').show();
  }

  // creates and returns a new Stripe 'card' Element (credit card input form
  // element)
  createStripeCredCard(): any {
    // create an instance of stripe elements
    let elements = this.stripeInstance.elements();

    // create custom styling for card element
    let creditCardStyle = {
      base: {
        fontSize: '16px',
        color: '#32325d',
      },
    };

    // create and return instance of a credit card element with this style
    let stripeCredCard = elements.create('card', { style: creditCardStyle });

    // add input error detection
    stripeCredCard.addEventListener('change', function (event) {
      let displayError = document.getElementById('credCardFormErrors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    return stripeCredCard;
  }

  // insert a given temporary credit card token into the form so it gets
  // submitted to the server
  insertCcTokenIntoForm(tokenId: string): void {
    let paymentForm = document.getElementById('paymentForm');
    let hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'stripeToken');
    hiddenInput.setAttribute('value', tokenId);
    paymentForm.appendChild(hiddenInput);
  }

  /////////////////////////////////////////////////////////////////////////////
  // settings for how html should render the tree
  /////////////////////////////////////////////////////////////////////////////
  customTemplateStringOptions: ITreeOptions = {
    displayField: 'title',
    isExpandedField: 'subitems_expanded',
    idField: 'id', // node id field
    actionMapping,
    nodeHeight: 23, // allotted height of the entire tree
    allowDrag: (node) => {
      return true;
    },
    allowDrop: (node) => {
      return true;
    },
    useVirtualScroll: true,
    animateExpand: true,
    animateSpeed: 30,
    animateAcceleration: 1.2,
  };

  customTemplateStringOptionsTrash: ITreeOptions = {
    displayField: 'title',
    isExpandedField: 'subitems_expanded',
    idField: 'id', // node id field
    actionMapping,
    nodeHeight: 23, // allotted height of the entire tree
    allowDrag: (node) => {
      return false;
    },
    allowDrop: (node) => {
      return false;
    },
    useVirtualScroll: true,
    animateExpand: true,
    animateSpeed: 30,
    animateAcceleration: 1.2,
  };
}

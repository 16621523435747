import { Routes } from '@angular/router';
import { AllSitePagesComponent } from './site-pages/site-pages.component';
import { SecurePagesComponent } from './site-pages/secure-pages/secure-pages.component';
import { LoggedInContentAuthGuard } from './routing/logged-in-content-auth-guard.guard';
import { MainDashboardComponent } from './site-pages/secure-pages/main-dashboard/main-dashboard.component';
import { PublicPagesComponent } from './site-pages/public-pages/public-pages.component';
import { SignUpPageComponent } from './site-pages/public-pages/signup-page/signup-page.component';
import { GoProPageComponent } from './site-pages/public-pages/gopro-page/gopro-page.component';
import { HomePageComponent } from './site-pages/public-pages/home-page/home-page.component';
import { WelcomePageComponent } from './site-pages/public-pages/welcome-page/welcome-page.component';
import { LogInPageComponent } from './site-pages/public-pages/login-page/login-page.component';
import { ResetPasswordPageComponent } from './site-pages/public-pages/reset-password-page/reset-password-page.component';
import { VerificationEmailSentPageComponent } from './site-pages/public-pages/verification-email-sent-page/verification-email-sent-page.component';
import { AuthParameterHandlerPageComponent } from './site-pages/public-pages/auth-param-handler-page/auth-param-handler-page.component';
import { TermsOfServiceComponent } from './site-pages/public-pages/terms-of-service-page/terms-of-service-page.component';
import { PrivacyPolicyComponent } from './site-pages/public-pages/privacy-policy-page/privacy-policy-page.component';
import { ComingSoonPageComponent } from './site-pages/public-pages/coming-soon-page/coming-soon-page.component';
import { PageNotFoundComponent } from './site-pages/public-pages/page-not-found-page/page-not-found-page.component';

export const routes: Routes = [
  {
    path: '',
    component: AllSitePagesComponent,
    children: [
      {
        path: '',
        component: SecurePagesComponent,
        canActivate: [LoggedInContentAuthGuard],
        children: [
          { path: '', component: MainDashboardComponent },
          { path: '#/*', component: MainDashboardComponent },
        ],
      },
      {
        path: '',
        component: PublicPagesComponent,
        children: [
          { path: 'signup', component: SignUpPageComponent },
          { path: 'gopro', component: GoProPageComponent },
          { path: 'home', component: HomePageComponent },
          { path: 'welcome', component: WelcomePageComponent },
          { path: 'login', component: LogInPageComponent },
          {
            path: 'accounts/password-reset',
            component: ResetPasswordPageComponent,
          },
          {
            path: 'verification-email-sent',
            component: VerificationEmailSentPageComponent,
          },
          { path: 'auth', component: AuthParameterHandlerPageComponent },
          { path: 'terms', component: TermsOfServiceComponent },
          { path: 'privacy', component: PrivacyPolicyComponent },
          { path: 'coming-soon', component: ComingSoonPageComponent },
          { path: '**', component: PageNotFoundComponent },
        ],
      },
    ],
  },
];

import { Component, OnInit, AfterViewInit, inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../authentication/auth.service';
import { LoadingService } from '../../../shared/services/loading.service';
declare let $: any; // for jquery dom selections

@Component({
  selector: 'app-auth-param-handler-page',
  templateUrl: './auth-param-handler-page.component.html',
  styleUrls: ['./auth-param-handler-page.component.scss'],
})
export class AuthParameterHandlerPageComponent implements OnInit, AfterViewInit {
  private mode: string;
  private oobCode: string;
  // private apiKey: string;
  private route = inject(ActivatedRoute);
  private auth = inject(AuthService);
  private loadingService = inject(LoadingService);

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      this.mode = params['mode'];
      this.oobCode = params['oobCode'];
      // this.apiKey = params['apiKey'];

      switch (this.mode) {
        case 'resetPassword':
          this.auth.handleResetPassword(this.oobCode);
          break;
        case 'recoverEmail':
          this.auth.handleRecoverEmail(this.oobCode);
          break;
        case 'verifyEmail':
          this.auth.handleVerifyEmail(this.oobCode);
          break;
        default:
          this.auth.handleInvalidURL();
      }
    });
  }

  ngAfterViewInit(): void {
    // wait until window is fully loaded before hiding the loading screen
    // (which covers the other content)
    this.loadingService.hide();
  }

  sendVerificationEmailAgain(): void {
    this.auth.sendVerificationEmailAgain();
  }

  updatePassword(txtPasswordElementId: string): void {
    let newPassword = (<HTMLInputElement>(
      document.getElementById(txtPasswordElementId)
    )).value;
    this.auth.updatePassword(this.oobCode, newPassword);
  }
}

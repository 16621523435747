<a href="/"
  ><img
    class="projectzen-logo"
    src="/assets/projectzen_logo.jpg"
    alt="projectzen_logo.jpg"
/></a>
<a class="small-button position-top-right" href="login">Log In</a>
<h1 class="main-header">Go Pro, for even more.</h1>
<h2 class="subheader">
  With a Pro account you have access to unlimited space to supercharge your
  team.
</h2>
<div class="container table-container">
  <table>
    <tr valign="top">
      <th></th>
      <th>
        <div class="container category pricing pricing-category-container">
          <div class="pricing-category">Free</div>
        </div>
      </th>
      <th><div class="pricing-category">Pro ($7.99/user/month)</div></th>
    </tr>
    <tr>
      <td><div class="product-feature">Easy UI, effortless roll-out</div></td>
      <td>
        <img
          class="checkmark"
          src="/assets/checkmark.jpg"
          alt="checkmark.jpg"
        />
      </td>
      <td>
        <img
          class="checkmark"
          src="/assets/checkmark.jpg"
          alt="checkmark.jpg"
        />
      </td>
    </tr>
    <tr>
      <td><div class="product-feature">Unlimited sharing</div></td>
      <td>
        <img
          class="checkmark"
          src="/assets/checkmark.jpg"
          alt="checkmark.jpg"
        />
      </td>
      <td>
        <img
          class="checkmark"
          src="/assets/checkmark.jpg"
          alt="checkmark.jpg"
        />
      </td>
    </tr>
    <tr>
      <td><div class="product-feature">Historical tracking</div></td>
      <td>
        <img
          class="checkmark"
          src="/assets/checkmark.jpg"
          alt="checkmark.jpg"
        />
      </td>
      <td>
        <img
          class="checkmark"
          src="/assets/checkmark.jpg"
          alt="checkmark.jpg"
        />
      </td>
    </tr>
    <tr>
      <td><div class="product-feature">Filtering/search</div></td>
      <td>
        <img
          class="checkmark"
          src="/assets/checkmark.jpg"
          alt="checkmark.jpg"
        />
      </td>
      <td>
        <img
          class="checkmark"
          src="/assets/checkmark.jpg"
          alt="checkmark.jpg"
        />
      </td>
    </tr>
    <tr>
      <td>
        <div class="product-feature">
          60 new items (projects, tasks, etc.) created per month
        </div>
      </td>
      <td>
        <img
          class="checkmark"
          src="/assets/checkmark.jpg"
          alt="checkmark.jpg"
        />
      </td>
      <td>
        <img
          class="checkmark"
          src="/assets/checkmark.jpg"
          alt="checkmark.jpg"
        />
      </td>
    </tr>
    <tr>
      <td><div class="product-feature">Unlimited created items</div></td>
      <td></td>
      <td>
        <img
          class="checkmark"
          src="/assets/checkmark.jpg"
          alt="checkmark.jpg"
        />
      </td>
    </tr>
    <tr>
      <td><div class="product-feature">Additional dashboard themes</div></td>
      <td></td>
      <td>
        <img
          class="checkmark"
          src="/assets/checkmark.jpg"
          alt="checkmark.jpg"
        />
      </td>
    </tr>
  </table>
</div>
<div class="container second-big-button-container">
  <a class="big-button position-inline-with-margin" href="signup">Sign up</a>
</div>
<div class="page-footer">
  <p>ProjectZen, 6 Liberty Square #2334, Boston, MA 02109</p>
  <p>&nbsp;</p>
  <a href="terms">Terms</a>
  <p>&nbsp;</p>
  <a href="privacy">Privacy</a>
  <p>&nbsp;</p>
  <a href="mailto:help@projectzen.com">Help</a>
</div>

import { Component, AfterViewInit, inject } from '@angular/core';
import { AuthService } from '../../../authentication/auth.service';
import { LoadingService } from '../../../shared/services/loading.service';
declare let $: any; // for jquery dom selections

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LogInPageComponent implements AfterViewInit {
  private auth = inject(AuthService);
  private loadingService = inject(LoadingService);

  ngAfterViewInit(): void {
    // wait until window is fully loaded before hiding the loading screen
    // (which covers the other content)
    this.loadingService.hide();
  }

  // log in if inputs valid; otherwise display informative alerts
  logInUser(emailElementId: string, passwordElementId: string): void {
    // grab form input values
    let email = (<HTMLInputElement>document.getElementById(emailElementId))
      .value;
    let password = (<HTMLInputElement>(
      document.getElementById(passwordElementId)
    )).value;

    // if email invalid
    if (
      (email != '' &&
        email.indexOf('@') > 0 &&
        email.indexOf('@') != email.length - 1) == false
    ) {
      alert('Invalid email.');
    }
    // if form fields valid
    else {
      this.auth.logInUser(email, password);
    }
  }
}

<div
  id="item_{{ node().data.id }}"
  class="item"
  [style.background-color]="
    generateColorFromString(node().data.type.toLowerCase())
  "
>
  <div class="view-mode">
    <div class="item-header">
      <div
        class="title-and-num-children-wrapper"
        title="{{ getItemTitleHoverText(node()) }}"
      >
        <span class="title-in-header">{{ node().data.title }}</span>
        <span class="num-children-string">{{
          getStringForNumChildrenInParen(node())
        }}</span>
      </div>
      <div
        class="shared-icon-in-header"
        title="This item is shared with {{
          getPermissionsDisplayText(node().data.shared_with)
        }}"
        [hidden]="!isShared()"
      >
        <img
          class="shared-item-icon"
          src="/assets/multiple_users_icon.png"
          alt="shared_item.png"
        />
      </div>
      <span class="status-in-header" title="Status: {{ node().data.status }}">{{
        node().data.status
      }}</span>
      <span
        class="priority-in-header"
        title="Priority: {{ node().data.priority }}"
        >{{ node().data.priority }}</span
      >
      @for (tag of node().data.tags; track $index) {
        <span
          class="tag-in-header"
          title="Tag: {{ tag }}"
          >#{{ tag }}</span
        >
      }
    </div>
    <div class="collapse item-details">
      <span class="item-attribute id">id:{{ node().data.id }} </span>
      <span class="item-attribute type">Type: {{ node().data.type }}</span>
      <div class="item-attribute assignees">
        <span>Assignee(s):</span>
        <span>{{ getAssigneesDisplayText(node().data.assignees) }}</span>
      </div>
      <div class="item-attribute shared-with">
        <span>Shared with:</span>
        <span>{{ getPermissionsDisplayText(node().data.shared_with) }}</span>
      </div>
      <div class="item-attribute subscribers">
        <span>Subscriber(s):</span>
        <span>{{ getSubscribersDisplayText(node().data.subscribers) }}</span>
      </div>
      <div class="item-attribute">
        <span class="description-label">Description:</span>
        <span class="description-content">{{ node().data.description }}</span>
      </div>
      <hr />
      <div class="item-attribute">
        <span class="history-header">History:</span>
        <ul class="history-content">
          @for (record of node().data.history.slice(0,getNumEventsDisplayedByDefault()); track $index) {
            <li class="history-record">
              {{ generateHistoryRecordText(record) }}
            </li>
          }
          <a
            class="show-more-history-link"
            [hidden]="!additionalHistoryHidden()"
            (click)="showFullHistory()"
            >More</a
          >
          @for (record of node().data.history.slice(getNumEventsDisplayedByDefault()); track $index) {
            <li
              [hidden]="!hasFullHistoryDisplayed()"
              class="history-record"
            >
              {{ generateHistoryRecordText(record) }}
            </li>
          }
          <a
            class="show-less-history-link"
            [hidden]="!hasFullHistoryDisplayed()"
            (click)="hideFullHistory()"
            >Less</a
          >
        </ul>
      </div>
      <div class="edit-button-wrapper" [hidden]="node().data.in_trash">
        <button
          class="edit btn glyphicon glyphicon-pencil"
          (click)="editItemButtonAction($event)"
          title="Edit"
        ></button>
      </div>
      <div
        class="move-from-trash-button-wrapper"
        [hidden]="!node().data.in_trash"
      >
        <button
          class="moveFromTrash btn"
          (click)="moveFromTrashButtonAction()"
          title="Undelete"
        >
          Undelete
        </button>
      </div>
    </div>
    <a
      class="zoom-to-item btn glyphicon glyphicon-resize-horizontal"
      href="#/{{ node().data.id }}"
      (click)="zoomToThisItem()"
      title="Zoom to item"
    ></a>
    <div
      class="expand-project-popover"
      data-toggle="popover"
      data-content="Click to expand details"
      data-trigger="manual"
    >
      <button
        class="expand-collapse-item-details btn glyphicon {{
          getAppropriateExpandCollapseGlyphicon()
        }}"
        (click)="toggleDetailsExpanded(node().data.id)"
        title="{{ getAppropriateExpandCollapseButtonHovertext() }}"
        data-toggle="collapse"
        [attr.data-target]="'#item_' + node().data.id + ' * .item-details'"
      ></button>
    </div>
    <button
      class="add-child"
      (click)="addChildItem()"
      title="Add subitem"
      [hidden]="node().data.in_trash"
    >
      +
    </button>
  </div>
</div>

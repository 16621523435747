<a href="/"
  ><img
    class="projectzen-logo"
    src="/assets/projectzen_logo.jpg"
    alt="projectzen_logo.jpg"
/></a>
<a class="small-button position-top-right" href="login">Log In</a>
<h1 class="main-header">Do More, Better.</h1>
<h2 class="subheader">
  ProjectZen is an elegant, flexible tool for agile teams.
</h2>
<div class="container big-button-container position-centered">
  <a class="big-button position-inline-with-margin" href="signup">Sign up</a>
  <div class="for-free-text">&nbsp;(for free)</div>
</div>
<div class="container demo-gif-container">
  <img class="demo-gif" src="/assets/demo_gif.gif" alt="demo_gif.gif" />
</div>
<div class="container homepage-screenshots-container">
  <img
    class="homepage-screenshots"
    src="/assets/homepage_screenshots.jpg"
    alt="projectzen_screenshots.jpg"
  />
  <img
    class="homepage-screenshots-mobile"
    src="/assets/homepage_screenshots_mobile.jpg"
    alt="projectzen_screenshots.jpg"
  />
</div>
<div class="go-pro-blurb">
  Create up to 60 new items (projects or tasks) per month free, or
  <a href="gopro">Go Pro</a> to get unlimited space.
</div>
<div class="container big-button-container position-centered">
  <a class="big-button position-inline-with-margin" href="signup">Sign up</a>
  <div class="for-free-text">&nbsp;(for free)</div>
</div>
<div class="page-footer">
  <p>ProjectZen, 6 Liberty Square #2334, Boston, MA 02109</p>
  <p>&nbsp;</p>
  <a href="terms">Terms</a>
  <p>&nbsp;</p>
  <a href="privacy">Privacy</a>
  <p>&nbsp;</p>
  <a href="mailto:help@projectzen.com">Help</a>
</div>

import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-site-pages',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './site-pages.component.html',
})
export class AllSitePagesComponent {}

import { Component, AfterViewInit, inject } from '@angular/core';
import { LoadingService } from '../../../shared/services/loading.service';
declare let $: any; // for jquery dom selections

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements AfterViewInit {
  private loadingService = inject(LoadingService);

  ngAfterViewInit(): void {
    // wait until window is fully loaded before hiding the loading screen
    // (which covers the other content)
    this.loadingService.hide();
  }
}

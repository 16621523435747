<div id="privacyPolicy">
  <h2>Privacy Policy</h2>
  <p>Effective Date: February 2, 2019</p>

  <p>
    ProjectZen, Inc. (“ProjectZen”) values the privacy of our users and makes
    all commercially reasonable efforts to protect it. This Privacy Policy
    covers the collection, use, and disclosure of personal information that may
    be collected any time you interact with ProjectZen, such as when you visit
    our website, use or purchase our products and services, or communicate with
    ProjectZen employees. Please read the following to learn more about what
    information we collect, what we do with that information, and how we may use
    your personal information.
  </p>

  <h2>Information disclosure to third-parties</h2>
  <p>
    We do not share, sell, rent or otherwise distribute information you enter on
    the ProjectZen website or in the ProjectZen service, including email, to
    advertisers or any other parties, except as disclosed in this Privacy
    Policy. ProjectZen may share your information with others outside of
    ProjectZen under the following circumstances:
  </p>
  <ul>
    <li>We have your consent to share the information;</li>
    <li>
      We need to share your information to fulfill your product or service
      request;
    </li>
    <li>
      We have a good faith belief that sharing or otherwise permitting access,
      disclosure, use or preservation of such information is reasonably
      necessary in order to meet any applicable law, regulation, legal process
      or enforceable governmental request;
    </li>
    <li>
      We believe it is necessary in order to investigate, prevent or take action
      regarding illegal activities, suspected fraud, security, technical issues,
      potential threats against persons or property;
    </li>
    <li>
      We need to enforce applicable <a href="terms">Terms of Service</a>,
      including investigation of potential violations;
    </li>
    <li>
      We have received a government request for your data, provided that we will
      take commercially reasonable efforts to notify you of such event, unless
      legally barred from doing so; or
    </li>
    <li>
      We are transferring (or intend to transfer) our assets in connection with
      a proposed or actual merger or sale (including any transfers made as part
      of an insolvency or bankruptcy proceeding)
    </li>
  </ul>
  <p>
    If you do not want us to share your information in these manners, please do
    not provide it to us.
  </p>

  <h2>Information Collection and Use</h2>
  <p>
    The primary reason that we collect certain personal information is to
    provide the best possible level of customer service. It enables us to
    deliver product announcements, software updates and special offers as well
    as provide convenient and personalized user access and improve the features
    and usability of our products.
  </p>
  <p>
    ProjectZen may collect information and use it for internal business purposes
    in a number of situations, for example:
  </p>
  <p>Personal information such as name and email address;</p>
  <p>
    Tracking information about your visit from the browser on your computer or
    mobile device, including IP address, cookies, and the pages you requested to
    analyze trends, administer the site, track your navigation of the site, and
    gather broad demographic information for aggregate use; and
  </p>
  <p>
    Aggregate information from multiple users without personally identifying
    individuals, such as geographic region, to improve understanding of customer
    needs and provide relevant services.
  </p>
  <p>
    You may elect to terminate your participation in the services at any time
    and/or delete information and material you have entered in ProjectZen. If
    you cancel your account, or delete any ProjectZen content, it will no longer
    be accessible to you or others who may access the service, but there may
    continue to be residual copies of such information due to the nature of the
    computer system's operation and ongoing data back-up and archiving. The
    exception to this is that content explicitly shared with other ProjectZen
    users will continue to be accessible to those other users even after account
    termination. This is so that projects or other items actively in use by a
    team will continue to be accessible to that team even if one team member
    decides to discontinue using the service. However, it is possible to delete
    and/or unshare such content before account cancellation, which will make the
    content inaccessible.
  </p>

  <h2>Cookies</h2>
  <p>
    Cookies are small data files that a website you visit may save on your hard
    drive that usually includes an anonymous unique identifier. A cookie cannot
    retrieve any other data from your hard drive, pass on computer viruses, or
    capture your email address. The ProjectZen website uses cookies for user
    authentication, keeping track of our audience size and traffic patterns, and
    in certain other cases.
  </p>
  <p>
    You can configure your browser to accept cookies, reject cookies or notify
    you when a cookie is being used. However, if you configure your browser to
    reject cookies, you may not be able to use ProjectZen products or services
    that require you to sign in/authenticate or take advantage of all the
    features of ProjectZen.
  </p>
  <p>
    ProjectZen may allow third-party business partners that display
    advertisements on some of our web pages to maintain their own cookies on
    your computer. These business partners do not have access to ProjectZen's
    cookies and their use is subject to their own privacy policies.
  </p>

  <h2>Links</h2>
  <p>
    The ProjectZen website and services may contain links to other sites. Such
    links do not constitute an endorsement by PriojectZen or association with
    those websites, their content or their operators. Such links are provided as
    an information service, for reference and convenience only. ProjectZen does
    not control any such websites, and is not responsible for their (i)
    availability or accuracy, or (ii) content, advertising, products, or
    services. It is your responsibility to evaluate the content and usefulness
    of the information obtained from other websites. You acknowledge and agree
    that ProjectZen is not involved in the creation or development of
    third-party websites and disclaims any responsibility for third-party
    websites, and cannot be liable for claims arising out of or relating to
    third-party websites. Further, you acknowledge and agree that ProjectZen has
    no obligation to monitor, review, or remove links to third-party websites,
    but reserves the right to limit or remove links to third-party websites on
    the ProjectZen website at its sole discretion.
  </p>
  <p>
    The use of any website controlled, owned or operated by third-parties is
    governed by the terms and conditions of use and privacy policies for those
    websites. You access such third-party websites at your own risk. ProjectZen
    expressly disclaims any liability arising in connection with your use and/or
    viewing of any websites or other material associated with links that may
    appear on the ProjectZen website. You hereby agree to hold ProjectZen
    harmless from any liability that may result from the use of links that may
    appear on the ProjectZen website. ProjectZen is not responsible for the
    privacy policy or other practices employed by websites linked to (including
    without limitation external websites that are framed by the ProjectZen
    website as well as any advertisements displayed in connection therewith), or
    from, our website nor the information or content contained therein. This
    Privacy Policy applies solely to information collected by ProjectZen.
  </p>

  <h2>Data Security</h2>
  <p>
    ProjectZen is committed to protecting the security of your information and
    takes reasonable precautions to protect it. Data transmissions for
    ProjectZen users are protected by SSL encryption by default, the same
    technology used by thousands of leading online services such as banks, email
    providers and remote backup services. However, Internet data transmissions,
    whether wired or wireless, cannot be guaranteed to be 100% secure and as a
    result we cannot give a guarantee of the security of information you
    transmit to us; accordingly, you acknowledge that you do so at your own
    risk. Once we receive your data transmission, we make all commercially
    reasonable efforts to ensure its security on our systems:
  </p>
  <ul>
    <li>
      Your data is encrypted at rest in our database and in all backups we
      create;
    </li>
    <li>
      Your ProjectZen password is protected by encryption and only you (or
      anyone you share it with) have access to it;
    </li>
    <li>
      Your personal information and data stored in our systems is protected by
      various physical, electronic and procedural safeguards;
    </li>
    <li>
      As a rule, ProjectZen employees do not monitor or view your personal
      information or content stored in the ProjectZen service, but it may be
      viewed if we learn that our <a href="terms">Terms of Service</a> may have
      been violated and confirmation is required, or we otherwise determine that
      we have to review it; and
    </li>
    <li>
      The ProjectZen service uses standard SSL encryption to protect data
      transmissions. However, this is not a guarantee that such data
      transmissions cannot be accessed, altered or deleted due to firewall or
      other security software failures.
    </li>
  </ul>
  <p>
    If ProjectZen learns of a security system breach we may attempt to notify
    you and provide information on protective steps, if available, through the
    email address that you supplied during registration or posting a notice on
    our website. Depending on where you live, you may have a legal right to
    receive such notices in writing. To request written notice of any security
    breach in writing, please send an email with your request and mailing
    address to <a href="mailto:help@projectzen.com">help&#64;projectzen.com</a>.
  </p>

  <h2>Children</h2>
  <p>
    ProjectZen complies with the U.S. Federal Trade Commission (FTC) Children's
    Online Privacy Protection Act (COPPA), which requires us to inform parents
    and legal guardians about how we collect, use and disclose personal
    information from children under 13 years of age.
  </p>
  <p>
    ProjectZen is not currently directed to children. We will not knowingly
    collect personal information relating to children under 13 years of age, and
    if we learn that we have inadvertently done so, we will promptly delete it.
  </p>

  <h2>International Users</h2>
  <p>
    Personal information collected on this website may be stored and processed
    in the United States or any other country in which ProjectZen maintains
    facilities, and by using this website, you consent to any such transfer of
    information outside of your country.
  </p>

  <h2>EU Users</h2>
  <p>
    ProjectZen only transfers your personal information to those third-parties
    where we can be sure that we can protect your privacy and your rights, for
    example the third-party is located in a country which the EU has deemed to
    have adequate data protection laws in place, where that third-party is
    certified on the EU-US Privacy Shield or where we have a contract in place
    with that third-party which includes the European Commission's standard data
    protection clauses.
  </p>
  <p>
    If you are an individual in the European Economic Area (EEA), we collect and
    process information about you only where we have legal bases for doing so
    under applicable EU laws. The legal bases depend on the services you use and
    how you use them. This means we collect and use your information only where:
  </p>
  <ul>
    <li>
      We need it to provide you our services, including to operate our services,
      provide customer support and personalized features and to protect the
      safety and security of our services;
    </li>
    <li>
      It satisfies a legitimate interest (which is not overridden by your data
      protection interests), such as for research and development, to market and
      promote our services and to protect our legal rights and interests;
    </li>
    <li>You give us consent to do so for a specific purpose; or</li>
    <li>We need to process your data to comply with a legal obligation.</li>
  </ul>
  <p>
    If you have consented to our use of information about you for a specific
    purpose, you have the right to change your mind at any time, but this will
    not affect any processing that has already taken place. Where we are using
    your information because we or a third-party (e.g. your employer) have a
    legitimate interest to do so, you have the right to object to that use
    although, in some cases, this may mean no longer using our services.
  </p>

  <h2>Changes and Updates</h2>
  <p>
    As this Privacy Policy may be updated from time to time, we will revise and
    display the effective date at the top of this Privacy Policy. You should
    periodically review it to keep abreast of our plans and procedures to
    protect the personal information that we collect. Your continued use of the
    ProjectZen services constitutes your agreement to this Privacy Policy and
    any updates.
  </p>
  <p>
    If ProjectZen should ever sell its assets, merge with another entity or file
    for bankruptcy, information collected from this website and in the operation
    of the services may be transferred as assets of ProjectZen.
  </p>

  <h2>Governing Law; Venue</h2>
  <p>
    The laws of Massachusetts, U.S.A., excluding Massachusetts’s conflict of
    laws rules, will apply to any disputes arising out of or relating to this
    Privacy Policy. All claims arising out of or relating to this Privacy Policy
    will be litigated exclusively in the federal or state courts of Middlesex
    County, Massachusetts, USA, and you and ProjectZen hereby consent to
    personal jurisdiction in those courts.
  </p>

  <h2>Contact Us</h2>
  <p>
    ProjectZen welcomes your feedback regarding this Privacy Policy. If you have
    questions, comments, or concerns about either one, please contact us by
    email at <a href="mailto:help@projectzen.com">help&#64;projectzen.com</a>.
    We are located at 6 Liberty Square #2334, Boston, MA 02109, United States.
  </p>
</div>

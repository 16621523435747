<a href="/"
  ><img
    class="projectzen-logo"
    src="/assets/projectzen_logo.jpg"
    alt="projectzen_logo.jpg"
/></a>
<a class="small-button position-top-right" href="login">Log In</a>
<h1 class="main-header">Do More, Better.</h1>
<h2 class="subheader">
  ProjectZen is an elegant, flexible tool for agile teams.
</h2>
<form class="container sign-up-form">
  <div class="text-inputs">
    <div class="container label text-box-label-container">
      <label class="label text-box-label">Name<br /></label>
    </div>
    <input
      id="txtDisplayName"
      class="input text-input-field"
      type="text"
      name="name"
      required
      spellcheck="false"
      autocorrect="off"
      maxlength="75"
      autocapitalize="off"
    />
    <div class="container label text-box-label-container">
      <label class="label text-box-label">Email<br /></label>
    </div>
    <input
      class="input text-input-field"
      id="txtEmail"
      type="email"
      name="email"
      required
      spellcheck="false"
      autocorrect="off"
      maxlength="75"
      autocapitalize="off"
    />
    <div class="container label text-box-label-container">
      <label class="label text-box-label">New Password<br /></label>
    </div>
    <input
      id="txtPassword"
      class="input text-input-field"
      type="password"
      name="password"
      required
    />
    <div class="container label text-box-label-container">
      <label class="label text-box-label">Re-enter Password<br /></label>
    </div>
    <input
      id="txtReenterPassword"
      class="input text-input-field"
      type="password"
      name="password"
      required
    />
  </div>
  <table id="iAgreeForm">
    <tr>
      <td id="iAgreeCheckboxWrapper">
        <input id="iAgreeCheckbox" type="checkbox" />
      </td>
      <td id="iAgreeTextWrapper">
        <p class="required-asterisk">*</p>
        <p id="iAgreeText">
          I agree to the <a href="terms">Terms of Service</a> and
          <a href="privacy">Privacy Policy</a>
        </p>
      </td>
    </tr>
  </table>
  <div class="container big-button-container position-centered">
    <re-captcha
      #captchaRef="reCaptcha"
      (resolved)="$event && signUpNewUser($event)"
      [(ngModel)]="cap"
      name="cap"
    ></re-captcha>
    <button
      id="btnSignUp"
      class="big-button position-inline-with-margin"
      class="btn btn-secondary"
      (click)="executeCaptchaIfValidInputs(captchaRef)"
    >
      Sign Up
    </button>
  </div>
  <div class="for-free-text">(for free)</div>
</form>
<div class="page-footer">
  <p>ProjectZen, 6 Liberty Square #2334, Boston, MA 02109</p>
  <p>&nbsp;</p>
  <a href="terms">Terms</a>
  <p>&nbsp;</p>
  <a href="privacy">Privacy</a>
  <p>&nbsp;</p>
  <a href="mailto:help@projectzen.com">Help</a>
</div>

import { Component, computed, inject } from '@angular/core';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-loading-screen',
  imports: [],
  templateUrl: './loading-screen.component.html',
  styleUrl: './loading-screen.component.scss',
  standalone: true
})
export class LoadingScreenComponent {
  isLoading = computed(() => this.loadingService.isLoading);

  private readonly loadingService = inject(LoadingService);

}

import { inject, Injectable } from '@angular/core';
import {
  applyActionCode,
  Auth,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
declare let $: any; // for jquery dom selections
declare let grecaptcha: any; // for dealing with captcha
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { EmailAuthProvider, reauthenticateWithCredential, sendEmailVerification, updateProfile, User } from 'firebase/auth';
import { catchError, Observable, throwError } from 'rxjs';
import { LoadingService } from '../shared/services/loading.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public justLoggedIn: boolean = false;
  private http = inject(HttpClient);
  private auth = inject(Auth);
  private router = inject(Router);
  private loadingService = inject(LoadingService);

  // returns the current firebase user
  getCurrentUser(): User {
    return this.auth.currentUser;
  }

  // returns the current firebase authentication state
  getAuthState(): any {
    return new Observable((observer) => {
      onAuthStateChanged(this.auth, (user) => {
        observer.next(user);
      });
    });
  }

  // validate captcha for signup
  validateCaptchaForSignup(captchaResponse: string) {
    return this.http
      .post(environment.baseApiUrl + 'validate_captcha_for_signup/', {
        captcha_response: captchaResponse,
      })
      .pipe(
        catchError((error) => {
          console.error('Error occurred:', error);
          return throwError(() => new Error('Something went wrong!'));
        })
      );
  }

  // sign up a new user with an account in firebase after validating captcha
  signUpNewUser(
    captchaResponse: string,
    displayName: string,
    email: string,
    password: string,
    reenterPassword: string
  ): void {
    // validate captcha
    this.validateCaptchaForSignup(captchaResponse).subscribe({
      next: (response) => {
        // then reset captcha and log result of attempt to validate
        grecaptcha.reset();
        let result = response['result'];
        if (environment.debuggingConsoleLogsOn) {
          console.log(result);
        }
        if (result == 'Invalid captcha.') {
          alert('Invalid captcha.');
        } else {
          // if valid captcha, create new user in backend, update display name,
          // and send verification email
          let router = this.router;
          this.createNewFirebaseUser(email, password).then(
            (e) => {
              this.updateUserDisplayName(displayName).then(
                (e) => {
                  let currentUser = this.getCurrentUser();
                  sendEmailVerification(currentUser).then(
                    function () {
                      if (environment.debuggingConsoleLogsOn) {
                        console.log(
                          'Verification email has been sent to',
                          email
                        );
                      }
                      router.navigate(['verification-email-sent']);
                    },
                    function (error) {
                      if (environment.debuggingConsoleLogsOn) {
                        console.log(
                          'problems with sending the email verification to',
                          email
                        );
                      }
                      alert(error.message);
                    }
                  );
                },
                (e) => {
                  alert(e.message);
                }
              );
            },
            (e) => {
              if (e.code == 'auth/email-already-in-use') {
                // better error message
                alert(
                  'The email address is already associated with an existing account. Please log in using the log in form.'
                );
              } else {
                alert(e.message);
              }
            }
          );
        }
      },
      error: (error) => {
        if (environment.debuggingConsoleLogsOn) {
          console.log(error);
        }
      },
    });
  }

  // log in user to existing account
  logInUser(email: string, password: string): Promise<Object> {
    this.logout(); // log out of any other accounts
    let privateRouter = this.router;
    let self = this;

    return this.logUserIntoFirebase(email, password).then(
      (userDetails) => {
        self.justLoggedIn = true;

        // if email isn't yet verified send verification email again and redirect to verif_email page
        if (userDetails?.user?.emailVerified == false) {
          let verifPromise = this.getCurrentUser();
          sendEmailVerification(verifPromise).then(
            function () {
              if (environment.debuggingConsoleLogsOn) {
                console.log('Verification email has been sent to', email);
              }
              privateRouter.navigate(['verification-email-sent']);
              return { result: 'Success', details: 'Email not yet verified' };
            },
            function (error) {
              if (environment.debuggingConsoleLogsOn) {
                console.log(
                  'problems with sending the email verification to',
                  email
                );
              }
              alert(error.message);
              return { result: 'Error', details: error };
            }
          );
        }
        // else redirect to main dashboard page
        else {
          this.loadingService.show();
          privateRouter.navigate(['']);
          return { result: 'Success', details: 'Email verified' };
        }
      },
      (e) => {
        if (
          e.code == 'auth/wrong-password' ||
          e.code == 'auth/user-not-found'
        ) {
          alert('Incorrect credentials.');
        } else {
          alert(e.message);
        }
        return { result: 'Error', details: e };
      }
    );
  }

  // returns a promise to reauthenticate user to their existing firebase account
  // (necessary for performing sensitive actions such as deleting account)
  reauthUser(password: string): Promise<any> {
    let firebaseUser = this.getCurrentUser();

    let credential = EmailAuthProvider.credential(firebaseUser.email, password);
    return reauthenticateWithCredential(firebaseUser, credential);
  }

  // log out user from current account
  logout(): Promise<any> {
    return signOut(this.auth);
  }

  // update a current user's account password
  updatePassword(oobCode: string, newPassword: string): void {
    confirmPasswordReset(this.auth, oobCode, newPassword).then(
      (e) => {
        if (environment.debuggingConsoleLogsOn) {
          console.log(
            'Password reset has been confirmed and new password updated.'
          );
        }
        // on-page text confirmation that password has been changed
        document.getElementById('passwordChangedConfirmation').innerHTML =
          '&nbspPassword has been updated.';
      },
      (e) => {
        if (environment.debuggingConsoleLogsOn) {
          console.log(
            'Error occurred during confirmation. The code might have expired or the password is too weak.'
          );
        }
        alert(
          'Error occurred during confirmation. The code might have expired or the password is too weak.'
        );
      }
    );
  }

  // send another address verification email
  sendVerificationEmailAgain(): void {
    let currentUser = this.getCurrentUser();
    let self = this;

    sendEmailVerification(currentUser).then(
      function () {
        let userEmail = self.getCurrentUser().email;
        if (environment.debuggingConsoleLogsOn) {
          console.log('Verification email has been sent to', userEmail);
        }
        // on-page text confirmation that email has been sent
        document.getElementById('anotherEmailSent').innerHTML =
          '&nbspEmail sent.';
      },
      function (error) {
        alert(error.message);
      }
    );
  }

  //--------------------------------------------------------------
  // auth page event handlers
  //--------------------------------------------------------------

  // Function to reset password using actioncode
  handleResetPassword(oobCode: string): void {
    // Verify the password reset code is valid.
    verifyPasswordResetCode(this.auth, oobCode).then(
      (email) => {
        // Email verified successfully, show the reset screen with the user's email
        let accountEmail = email;
        $('.content-shown-when-valid-password-reset-actioncode').show();
      },
      (error) => {
        // Invalid or expired action code. Ask user to try resetting the password again
        $('.content-shown-when-invalid-password-reset-actioncode').show();
        if (environment.debuggingConsoleLogsOn) {
          console.log(error);
        }
      }
    );
  }

  handleRecoverEmail(oobcode: string): void {
    if (environment.debuggingConsoleLogsOn) {
      console.log('skeleton function for later implementation');
    }
  }

  // Function to verify email using verification code
  handleVerifyEmail(oobCode: string): void {
    let privateRouter = this.router;
    applyActionCode(this.auth, oobCode).then(
      (resp) => {
        if (environment.debuggingConsoleLogsOn) {
          console.log('Email address has been verified.');
        }
        privateRouter.navigate(['welcome']); // Redirect to a welcome page
      },
      (error) => {
        // Code is invalid or expired. Ask the user to verify their email address again
        if (environment.debuggingConsoleLogsOn) {
          console.log(error);
        }
        $('.content-shown-when-error-in-email-verification').show();

        // If user info available, show a "send email again button"
        if (this.getCurrentUser()) {
          if (this.getCurrentUser().emailVerified == false) {
            $(
              '.content-shown-when-error-and-unverified-user-info-avail'
            ).show();
          }
        }
      }
    );
  }

  handleInvalidURL(): void {
    $('.content-shown-when-invalid-url').show();
    if (environment.debuggingConsoleLogsOn) {
      console.log('Error: Invalid URL');
    }
  }

  //--------------------------------------------------------------
  // helper functions
  //--------------------------------------------------------------

  // given a string user id token, returns a new Headers object with that string
  // as a header
  createHeadersWithUserToken(userToken: string) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Token ${userToken}`);
    return headers;
  }

  createNewFirebaseUser(email: string, password: string): Promise<any> {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  logUserIntoFirebase(email: string, password: string): Promise<any> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  updateUserDisplayName(newDisplayName: string): Promise<any> {
    let firebaseUser = this.getCurrentUser();
    return updateProfile(firebaseUser, {
      displayName: newDisplayName,
      photoURL: null,
    });
  }

  sendResetPasswordEmail(email: string): Promise<any> {
    return sendPasswordResetEmail(this.auth, email).then(
      (onSuccess) => {
        if (environment.debuggingConsoleLogsOn) {
          console.log(email, 'Password reset email sent.');
        }
        // on-page text confirmation that email has been sent
        document.getElementById('emailSentConfirmationText').innerHTML =
          '&nbsp&nbspEmail sent.';
      },
      (onFailure) => {
        alert(onFailure.message);
      }
    );
  }
}

import { inject, Injectable } from '@angular/core';
import { AuthService } from '../../authentication/auth.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class AccountDataService {
  private http = inject(HttpClient);
  private auth = inject(AuthService);

  // given a dictionary containing a stripe credit card temporary token
  // (a string)
  // returns a promise to post this data to the backend
  upgradeAccountToPro(creditCardDict: Object): Promise<any> {
    let firebaseUser = this.auth.getCurrentUser();
    let self = this;
    let payload = creditCardDict;

    return firebaseUser
      .getIdToken()
      .then(function (userIdToken) {
        let headers = self.auth.createHeadersWithUserToken(userIdToken);
        return self.http
          .post(environment.baseApiUrl + 'upgrade_account_to_pro/', payload, {
            headers: headers,
          })
          .subscribe({
            next: (response) => {
              return response;
            },
            error: (error) => {
              if (environment.debuggingConsoleLogsOn) {
                console.log(error);
              }
            },
          });
      })
      .catch((error) => {
        return Promise.reject(error.message || error);
      });
  }

  // returns a promise to mark the account as deleted in the backend SQL
  // database
  markAccountAsDeletedInSQL(): Promise<any> {
    let firebaseUser = this.auth.getCurrentUser();
    let self = this;

    return firebaseUser
      .getIdToken()
      .then(function (userIdToken) {
        let headers = self.auth.createHeadersWithUserToken(userIdToken);
        return self.http
          .post(environment.baseApiUrl + 'mark_account_as_deleted/', [], {
            headers: headers,
          })
          .subscribe({
            next: (response) => {
              return response;
            },
            error: (error) => {
              return Promise.reject(error.message || error);
            },
          });
      })
      .catch((error) => {
        return Promise.reject(error.message || error);
      });
  }

  // returns a promise to delete this user's account in firebase authentication
  deleteUserAccountInFirebase(): Promise<any> {
    let firebaseUser = this.auth.getCurrentUser();
    return firebaseUser.delete();
  }

  // given an old password and a new password (both strings), returns a promise to update this user's
  // account password to it in firebase authentication after verifying/reauthenticating with old
  // password
  changeAccountPasswordInFirebase(
    oldPassword: string,
    newPassword: string
  ): Promise<any> {
    let firebaseUser = this.auth.getCurrentUser();
    let credential = EmailAuthProvider.credential(
      firebaseUser.email,
      oldPassword
    );

    return reauthenticateWithCredential(firebaseUser, credential).then(
      function () {
        return updatePassword(firebaseUser, newPassword);
      }
    );
  }

  // given a dictionary containing a stripe credit card temporary token
  // (a string)
  // returns a promise update this user's credit card details in the backend
  updateCredCardDetails(creditCardDict: Object): Promise<any> {
    let firebaseUser = this.auth.getCurrentUser();
    let self = this;
    let payload = creditCardDict;

    return firebaseUser
      .getIdToken()
      .then(function (userIdToken) {
        let headers = self.auth.createHeadersWithUserToken(userIdToken);
        return self.http
          .post(
            environment.baseApiUrl + 'update_credit_card_details/',
            payload,
            { headers: headers }
          )
          .subscribe({
            next: (response) => {
              return response;
            },
            error: (error) => {
              return Promise.reject(error.message || error);
            },
          });
      })
      .catch((error) => {
        return Promise.reject(error.message || error);
      });
  }

  // returns a promise to deactivate Pro from this account in the backend
  deactivateProForThisAccount(): Promise<any> {
    let firebaseUser = this.auth.getCurrentUser();
    let self = this;

    return firebaseUser
      .getIdToken()
      .then(function (userIdToken) {
        let headers = self.auth.createHeadersWithUserToken(userIdToken);
        return self.http
          .post(
            environment.baseApiUrl + 'deactivate_pro_for_this_account/',
            [],
            { headers: headers }
          )
          .subscribe({
            next: (response) => {
              return response;
            },
            error: (error) => {
              return Promise.reject(error.message || error);
            },
          });
      })
      .catch((error) => {
        return Promise.reject(error.message || error);
      });
  }

  // returns a promise to make a request to the backend for the current user's
  // dashboard data, including current items
  getNewestAccountData() {
    let firebaseUser = this.auth.getCurrentUser();
    let self = this;

    return firebaseUser
      .getIdToken()
      .then(function (userIdToken) {
        let headers = self.auth.createHeadersWithUserToken(userIdToken);
        return firstValueFrom(self.http
          .get<any>(environment.baseApiUrl + 'fetch_account_data/', {
            headers: headers,
          }))
      })
      .catch((error) => {
        return Promise.reject(error.message || error);
      });
  }

  // given an updated intro tutorial opt out preference, return a promise to
  // save it to the backend
  updateWelcomePopupPreference(newOptOutPref: boolean): Promise<any> {
    let firebaseUser = this.auth.getCurrentUser();
    let self = this;
    let payload = newOptOutPref;

    return firebaseUser
      .getIdToken()
      .then(function (userIdToken) {
        let headers = self.auth.createHeadersWithUserToken(userIdToken);
        return self.http
          .post(
            environment.baseApiUrl +
              'update_welcome_dialog_opt_out_preference/',
            payload,
            { headers: headers }
          )
          .subscribe({
            next: (response) => {
              return response;
            },
            error: (error) => {
              return Promise.reject(error.message || error);
            },
          });
      })
      .catch((error) => {
        return Promise.reject(error.message || error);
      });
  }

  // given an updated dashboard theme, return a promise to save it to the
  // backend
  saveDashboardTheme(theme: string): Promise<any> {
    let firebaseUser = this.auth.getCurrentUser();
    let self = this;
    let payload = { dashboardTheme: theme };

    return firebaseUser
      .getIdToken()
      .then(function (userIdToken) {
        let headers = self.auth.createHeadersWithUserToken(userIdToken);
        return self.http
          .post(environment.baseApiUrl + 'update_dashboard_theme/', payload, {
            headers: headers,
          })
          .subscribe({
            next: (response) => {
              return response;
            },
            error: (error) => {
              return Promise.reject(error.message || error);
            },
          });
      })
      .catch((error) => {
        return Promise.reject(error.message || error);
      });
  }

  // given an array of dictionaries representing the newest unsaved item
  // changes, return a promise to make these changes in the backend
  saveUnsavedChanges(unsavedChanges: Object[]): Promise<any> {
    let firebaseUser = this.auth.getCurrentUser();
    let self = this;
    let payload = { unsavedChanges: unsavedChanges };

    return firebaseUser
      .getIdToken()
      .then(function (userIdToken) {
        let headers = self.auth.createHeadersWithUserToken(userIdToken);
        return self.http
          .post(environment.baseApiUrl + 'save_newest_changes/', payload, {
            headers: headers,
          })
          .subscribe({
            next: (response) => {
              return response;
            },
            error: (error) => {
              return Promise.reject(error.message || error);
            },
          });
      })
      .catch((error) => {
        return Promise.reject(error.message || error);
      });
  }
}

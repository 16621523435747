<a href="/"
  ><img
    class="projectzen-logo"
    src="/assets/projectzen_logo.jpg"
    alt="projectzen_logo.jpg"
/></a>
<a class="small-button position-top-right" href="login">Log In</a>
<h2 class="subheader">
  Please check your <span id="userAccountEmail"></span> email <br />to verify
  your account address before logging in.
</h2>
<div class="container send-again-button-container">
  <button
    id="btnSendAgain"
    class="send-again-button"
    class="btn btn-secondary"
    (click)="sendVerificationEmailAgain()"
  >
    Send again
  </button>
</div>
<p id="anotherEmailSent" class="another-email-sent-confirmation-text"></p>
<div class="page-footer">
  <p>ProjectZen, 6 Liberty Square #2334, Boston, MA 02109</p>
  <p>&nbsp;</p>
  <a href="terms">Terms</a>
  <p>&nbsp;</p>
  <a href="privacy">Privacy</a>
  <p>&nbsp;</p>
  <a href="mailto:help@projectzen.com">Help</a>
</div>

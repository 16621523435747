import { Component, AfterViewInit, inject } from '@angular/core';
import { AuthService } from '../../../authentication/auth.service';
import { LoadingService } from '../../../shared/services/loading.service';
declare let $: any; // for jquery dom selections

@Component({
  selector: 'app-verification-email-sent-page',
  templateUrl: './verification-email-sent-page.component.html',
  styleUrls: ['./verification-email-sent-page.component.scss'],
})
export class VerificationEmailSentPageComponent implements AfterViewInit {
  private auth = inject(AuthService);

  private loadingService = inject(LoadingService);

  ngAfterViewInit(): void {
    // wait until window is fully loaded before hiding the loading screen
    // (which covers the other content)
    this.loadingService.hide();
  }

  sendVerificationEmailAgain(): void {
    this.auth.sendVerificationEmailAgain();
  }
}

import { Component, inject, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RecaptchaModule } from 'ng-recaptcha';
import { LoadingService } from '../../../shared/services/loading.service';
declare let $: any; // for jquery dom selections
declare let grecaptcha: any; // for dealing with captcha

@Component({
  selector: 'app-coming-soon-page',
  templateUrl: './coming-soon-page.component.html',
  styleUrls: ['./coming-soon-page.component.scss'],
  standalone: true,
  imports: [RecaptchaModule],
})
export class ComingSoonPageComponent implements OnInit {
  private http = inject(HttpClient);
  private loadingService = inject(LoadingService);

  // takes a submitted email (of someone interested in learning more
  // about the product launch) as well as their captcha response
  // validates captcha response and, if valid, saves submitted email to the
  // back end
  submitEmail(captchaResponse: string, emailElementId: string): void {
    let email = (<HTMLInputElement>document.getElementById(emailElementId))
      .value;
    email = email.trim();

    // if it's a valid email
    if (
      email != '' &&
      email.indexOf('@') > 0 &&
      email.indexOf('@') != email.length - 1
    ) {
      // attempt to post it to the backend database, along with the user captcha
      this.http
        .post(environment.baseApiUrl + 'store_email_address/', {
          submitted_email: email,
          captcha_response: captchaResponse,
        })
        .subscribe({
          next: (response: any) => {
            // then reset captcha and log response
            grecaptcha.reset();
            response = response;
            let result = response['result'];
            if (environment.debuggingConsoleLogsOn) {
              console.log(result);
            }

            // set confirmation text
            if (result == 'Invalid captcha.') {
              document.getElementById(
                'email-sent-confirmation-text'
              ).innerHTML = '&nbsp&nbsp&nbsp&nbspError: Invalid captcha';
            } else {
              document.getElementById(
                'email-sent-confirmation-text'
              ).innerHTML = '&nbsp&nbsp&nbsp&nbspEmail submitted.';
            }
          },
          error: (error) => {
            if (environment.debuggingConsoleLogsOn) {
              console.log(error);
            }
          },
        });
    } else {
      alert('Email badly formatted.');
    }
  }

  ngOnInit(): void {
    this.loadingService.hide();
  }
}

<div id="termsOfService">
  <h2>ProjectZen Terms of Service</h2>
  <p>Effective Date: February 2, 2019</p>

  <p>Welcome to ProjectZen!</p>

  <p>
    Thanks for using our website and embedded task management software
    (“Service”). The Service is provided by ProjectZen, Inc. (“ProjectZen”),
    located at 6 Liberty Square #2334, Boston, MA 02109, United States. By using
    our Service, you are agreeing to these terms. Please read them carefully.
  </p>

  <h2>Using the Service</h2>
  <p>
    By using the Service (and registering an account on the Service), you
    represent and warrant that you are at least 13 years of age, and no one
    under the age of 13 may use the Service.
  </p>
  <p>
    You are not permitted to interfere with our Service or try to access it
    using a method other than the interface and the instructions that we
    provide. You may use our Service only as permitted by law. We may suspend or
    stop providing our Service to you if you do not comply with our terms or
    policies or if we are investigating suspected misconduct.
  </p>
  <p>
    These terms do not grant you the right to use any branding or logos used in
    our Service. Don’t remove, obscure, or alter any legal notices displayed in
    or along with our Service.
  </p>
  <p>
    ProjectZen may, in its sole discretion, refuse to offer the Service to any
    person or entity and change its eligibility criteria at any time.
  </p>
  <p>
    In connection with your use of the Service, we may send you Service
    announcements, administrative messages, and other information. You may opt
    out of some of those communications.
  </p>
  <p>
    Our Service is available from the browser on mobile devices. Do not use this
    Service in a way that distracts you and prevents you from obeying traffic or
    safety laws.
  </p>

  <h2>Your ProjectZen Account</h2>
  <p>You will need a ProjectZen Account in order to use our Service.</p>
  <p>
    To protect your ProjectZen Account, keep your password confidential. You are
    responsible for the activity that happens on or through your ProjectZen
    Account. Try not to reuse your ProjectZen Account password on third-party
    applications. If you learn of any unauthorized use of your password or
    ProjectZen Account, please promptly change your account password and/or
    email <a href="mailto:help@projectzen.com">help&#64;projectzen.com</a>.
  </p>

  <h2>Billing/Payment Terms</h2>
  <p>
    Additional creation of items beyond the default monthly limit may be added
    to the Service and made available as a paid "Pro Plan." All currency
    references in specified pricing are in U.S. dollars.
  </p>
  <p>
    Upon election of a Pro Plan, the credit card that you provide as part of the
    billing data will be automatically and immediately billed, and will continue
    to be automatically billed every 30 days until cancellation.
  </p>
  <p>
    You agree that as long as your account remains active and in good standing,
    you will be charged the subscription fee even if you never use the Service.
    You may, however, cancel Your Pro Plan at any time.
  </p>
  <p>
    ProjectZen may change prices at any time without prior notice, but will
    endeavor to provide reasonable advance notice via the ProjectZen website
    and/or email.
  </p>

  <h2>Privacy</h2>
  <p>
    ProjectZen’s <a href="privacy">Privacy Policy</a> explains how we treat your
    personal data and protect your privacy when you use our Service. By using
    our Service, you agree that ProjectZen can use such data in accordance with
    our privacy policies.
  </p>

  <h2>Your Content in our Service</h2>
  <p>
    The Service may contain information, data, text, written posts and comments,
    software, and scripts generated, provided, or otherwise made accessible on
    or through the Service (collectively, “Content”). You retain ownership of
    any intellectual property rights that you hold in that Content. In short,
    what belongs to you stays yours.
  </p>
  <p>
    You understand that all Content is the sole responsibility of the person
    from which such Content originated. This means that you, and not ProjectZen,
    are entirely responsible for all Content that you post or otherwise make
    available via your account. ProjectZen does not control the Content posted
    via the Service and, as such, does not guarantee the accuracy, integrity or
    quality of such Content.
  </p>
  <p>
    You understand that by using the Service, you may be exposed to Content that
    is offensive, indecent or objectionable. Under no circumstances will
    ProjectZen be liable in any way for any Content, including, but not limited
    to, for any errors or omissions in any Content, or for any loss or damage of
    any kind incurred as a result of the use of any Content posted, transmitted
    or otherwise made available via the Service. You acknowledge that ProjectZen
    does not pre-screen Content, but that ProjectZen and its designees shall
    have the right (but not the obligation) in their sole discretion to refuse,
    modify or move any Content that is available via the Service. Without
    limiting the foregoing, ProjectZen and its designees shall have the right to
    remove any Content that violates the Terms of Service or is otherwise
    objectionable. You agree that you must evaluate, and bear all risks
    associated with, the use of any Content, including any reliance on the
    accuracy, completeness, or usefulness of such Content. In this regard, you
    acknowledge that you may not reasonably rely on any Content created by
    ProjectZen or submitted to ProjectZen.
  </p>
  <p>
    Should Content be found or reported to be in violation with, but not limited
    to, the following terms, it will be in ProjectZen's sole discretion as to
    what action should be taken. You agree that you will not:
  </p>
  <ol type="a">
    <li>
      upload, post, transmit or otherwise make available any Content that is
      unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory,
      vulgar, obscene, libelous, invasive of another's privacy (up to, but not
      excluding any address, email, phone number, or any other contact
      information without the written consent of the owner of such information),
      hateful, or racially, ethnically or otherwise objectionable;
    </li>
    <li>harm minors in any way;</li>
    <li>
      impersonate any person or entity, including, but not limited to, a
      ProjectZen official, forum leader, guide or host, or falsely state or
      otherwise misrepresent your affiliation with a person or entity;
    </li>
    <li>
      forge headers or otherwise manipulate identifiers in order to disguise the
      origin of any Content transmitted through the Service;
    </li>
    <li>
      upload, post or otherwise transmit any Content that you do not have a
      right to transmit under any law or under contractual or fiduciary
      relationships (such as inside information, proprietary and confidential
      information learned or disclosed as part of employment relationships or
      under nondisclosure agreements);
    </li>
    <li>
      upload, post or otherwise transmit any Content that infringes any patent,
      trademark, trade secret, copyright, rights of privacy or publicity, or
      other proprietary rights of any party;
    </li>
    <li>
      upload, post, or transmit unsolicited commercial email or "spam". This
      includes unethical marketing, advertising, or any other practice that is
      in any way connected with "spam", including but not limited to (i) sending
      mass email to recipients who haven't requested email from you or with a
      fake return address, (ii) promoting a site with inappropriate links,
      titles, descriptions, or (iii) promoting your site by posting multiple
      submissions in public forums that are identical;
    </li>
    <li>
      upload, post or otherwise transmit any material that contains software
      viruses or any other computer code, files or programs designed to
      interrupt, destroy or limit the functionality of any computer software or
      hardware or telecommunications equipment;
    </li>
    <li>
      interfere with or disrupt the Service or servers or networks connected to
      the Service, or disobey any requirements, procedures, policies or
      regulations of networks connected to the Service;
    </li>
    <li>
      intentionally or unintentionally violate any applicable local, state,
      national or international law, including, but not limited to, regulations
      promulgated by the U.S. Securities and Exchange Commission, any rules of
      any national or other securities exchange, including without limitation,
      the New York Stock Exchange, the American Stock Exchange or the NASDAQ,
      and any regulations having the force of law;
    </li>
    <li>"stalk" or otherwise harass another;</li>
    <li>
      promote or provide instructional information about illegal activities,
      promote physical harm or injury against any group or individual, or
      promote any act of cruelty to animals. This may include, without
      limitation, providing instructions on how to assemble bombs, grenades and
      other weapons or incendiary devices;
    </li>
    <li>
      offer for sale or sell any item, good or Service that (i) violates any
      applicable federal, state, or local law or regulation, (ii) you do not
      have full power and authority under all relevant laws and regulations to
      offer and sell, including all necessary licenses and authorizations, or
      (iii) ProjectZen determines, in its sole discretion, is inappropriate for
      sale through the Service provided by ProjectZen;
    </li>
    <li>
      use any portion of ProjectZen’s website as a redirecting/forwarding
      Service to another website; or
    </li>
    <li>
      exceed the scope of the Service that you have signed up for; for example,
      accessing and using the tools that you do not have a right to use, or
      having humans share user logins, or deleting, adding to, or otherwise
      changing other people's comments or Content as an Account holder.
    </li>
  </ol>
  <p>
    If any user is reported to be in violation with the letter or spirit of
    these terms, ProjectZen, in its sole discretion, retains the right to
    terminate such account at any time without further warning.
  </p>

  <h2>About Software in our Service</h2>

  <p>
    Our software is proudly
    <a href="https://github.com/dana-bullister/projectzen/blob/master/LICENSE"
      >free and open source</a
    >
    and may be found, downloaded, used, and contributed to on Github at
    <a href="https://github.com/dana-bullister/projectzen"
      >https://github.com/dana-bullister/projectzen</a
    >.
  </p>

  <p>
    By using our Service, you consent to the installation of any updates or
    upgrades that are released through the website. Any updates or upgrades may
    (i) cause your device to automatically communicate with ProjectZen’s servers
    to deliver the functionality and to record usage metrics, (ii) affect
    preferences or data stored your device, and (iii) collect personal
    information as set out in our <a href="privacy">Privacy Policy</a>. You can
    stop using our Service at any time.
  </p>

  <h2>Modifying and Terminating our Service</h2>
  <p>
    We are constantly changing and improving our Service. We may add or remove
    functionalities or features, and we may suspend or stop a feature
    altogether. ProjectZen is not liable to you for any modification or
    discontinuance of all or any portion of the software.
  </p>
  <p>
    You can stop using our Service at any time, although we’ll be sorry to see
    you go. ProjectZen may also stop providing the Service to you, or add or
    create new limits to our Service at any time.
  </p>
  <p>
    We believe that you own your data and preserving your access to such data is
    important. If we discontinue a feature, where reasonably possible, we will
    give you reasonable advance notice and a chance to get information out of
    that feature.
  </p>
  <p>
    If ProjectZen terminates or limits your right to use the Service, you are
    prohibited from registering and creating a new account under your name, a
    fake or borrowed name, or the name of any third party, even if you may be
    acting on behalf of the third party.
  </p>
  <p>
    You may terminate this Terms of Service at any time by ceasing all use of
    the Service. All parts of these Terms of Service which by their nature
    should survive the expiration or termination of these Terms of Service shall
    continue in full force and effect subsequent to and notwithstanding the
    expiration or termination of these Terms of Service.
  </p>

  <h2>Our Warranties and Disclaimers</h2>
  <p>
    The use of our Service is entirely at your own risk. ProjectZen provides our
    Service using a commercially reasonable level of skill and care. But there
    are certain things that ProjectZen does not promise about our Service.
  </p>
  <p>
    Other than as expressly set out in these terms or additional terms,
    ProjectZen does not make any specific promises about the Service. For
    example, ProjectZen does not make any commitments about the content within
    the Service, the specific functions of the Service, or their reliability,
    availability, or ability to meet your needs.
  </p>
  <p>
    Our Service is provided on an “as is” basis without warranties or conditions
    of any kind, either express or implied, including, but not limited to,
    warranties or conditions of merchantability, fitness for a particular
    purpose, and non-infringement. ProjectZen makes no warranties or
    representations about the accuracy or completeness of the content provided
    through our Service or the content of any sites linked to our Service and
    assumes no liability or responsibility in contract, warranty or in tort for
    any (i) errors, mistakes, or inaccuracies of content, (ii) personal injury
    or property damage, of any nature whatsoever, resulting from your access to
    and use of our Service, (iii) any access to or use of our secure servers
    and/or any and all personal information and/or financial information stored
    therein; or (iv) events beyond our reasonable control.
  </p>
  <p>
    ProjectZen does not warrant, endorse, guarantee or assume responsibility for
    any service advertised or offered by a third party through our Service or
    any hyperlinked website or featured in any banner or other advertising, and
    ProjectZen will not be a party to or in any way be responsible for
    monitoring any transaction between you and third-party providers of products
    or services. As with the purchase of a product or service through any medium
    or in any environment, you should use your best judgment and exercise
    caution where appropriate. Without limiting the foregoing, ProjectZen does
    not warrant that access to our Service will be uninterrupted or that our
    Service will be error-free; nor do we make any warranty as to the results
    that may be obtained from the use of our Service, or as to the timeliness,
    accuracy, reliability, completeness or content of any Task, service,
    information or materials provided through or in connection with the use of
    our Service. ProjectZen is not responsible for the conduct, whether online
    or offline, of any user. ProjectZen does not warrant that our Service is
    free from computer viruses, system failures, worms, Trojan Horses, or other
    harmful components or malfunctions, including during hyperlink to or from
    third-party websites. ProjectZen cannot and does not guarantee that any
    personal information supplied by you will not be misappropriated,
    intercepted, deleted, destroyed or used by others.
  </p>
  <p>
    Some jurisdictions provide for certain warranties, like the implied warranty
    of merchantability, fitness for a particular purpose and non-infringement.
    To the extent permitted by law, we exclude all warranties.
  </p>

  <h2>Liability for our Service</h2>
  <p>
    You acknowledge and agree that ProjectZen is only willing to provide our
    Service if you agree to certain limitations of our liability to you and
    third parties. Therefore, you agree not to hold ProjectZen or any of its
    officers, directors, employees, agents, suppliers, or distributors
    (collectively, the “Indemnified Parties”) liable for any claims, demands,
    damages, expenses, losses, governmental obligations, suits, and/or
    controversies of every kind and nature, known and unknown, suspected and
    unsuspected, disclosed and undisclosed, direct, indirect, incidental,
    actual, consequential, economic, special, or exemplary, including attorneys’
    fees and costs (collectively, “Liabilities”) that have arisen or may arise,
    relating to your or any other party’s use of or inability to use our
    Service, including without limitation any Liabilities arising in connection
    with the conduct, act or omission of any user (including without limitation
    stalking, harassment that is sexual or otherwise, acts of physical violence,
    and destruction of personal property), any dispute with any user, any
    instruction, advice, act, or service provided by ProjectZen and any
    destruction of your Content.
  </p>
  <p>
    To the extent permitted by law, the total liability of ProjectZen, for any
    claims under these terms, including for any implied warranties, is limited
    to the amount you paid us to use the Service.
  </p>
  <p>
    In all cases, no Indemnified Party will be liable for any loss or damage
    that is not reasonably foreseeable.
  </p>

  <h2>Indemnification</h2>
  <p>
    You hereby agree to indemnify, defend, and hold harmless the Indemnified
    Parties from and against any and all Liabilities incurred in connection with
    (i) your use or inability to use the Service, (ii) your breach or violation
    of these Terms of Service; (iii) your violation of any law, or the rights of
    any user or third party and (iv) any content submitted by you or your use of
    our Service, including, but not limited to the extent such content may
    infringe on the intellectual rights of a third party or otherwise be illegal
    or unlawful.
  </p>

  <h2>Notices and Consent to Receive Notices Electronically</h2>
  <p>
    You consent to receive any agreements, notices, disclosures and other
    communications (collectively, “Notices”) to which these Terms of Service
    refers electronically including without limitation by e-mail to the last
    email address provided or by posting Notices on our website. You agree that
    all Notices that we provide to you electronically satisfy any legal
    requirement that such communications be in writing.
  </p>

  <h2>About these Terms</h2>
  <p>
    We may modify these terms to, for example, reflect changes to the law or
    changes to our Service. You should look at the terms regularly. Changes will
    not apply retroactively and will become effective no sooner than fourteen
    days after they are posted. However, changes addressing new functions for
    the Service or changes made for legal reasons will be effective immediately.
    If you do not agree to the modified terms for the Service, you should
    discontinue your use of the Service.
  </p>
  <p>
    If there is a conflict between these terms and the additional terms, the
    additional terms will control for that conflict.
  </p>
  <p>
    These terms control the relationship between ProjectZen and you. They do not
    create any third party beneficiary rights, except to the Indemnified Parties
    to the extent set forth herein.
  </p>
  <p>
    If you do not comply with these terms, and we don’t take action right away,
    this doesn’t mean that we are giving up any rights that we may have (such as
    taking action in the future).
  </p>
  <p>
    If it turns out that a particular term is not enforceable, this will not
    affect any other terms.
  </p>
  <p>
    The laws of Massachusetts, U.S.A., excluding Massachusetts’s conflict of
    laws rules, will apply to any disputes arising out of or relating to these
    terms or the Service. All claims arising out of or relating to these terms
    or the Service will be litigated exclusively in the federal or state courts
    of Middlesex County, Massachusetts, USA, and you and ProjectZen hereby
    consent to personal jurisdiction in those courts.
  </p>
  <p>
    Your obligations under these terms shall survive any termination of these
    terms. These terms shall be governed by and construed in accordance with the
    laws of Massachusetts. You hereby agree that breach of these terms will
    cause ProjectZen irreparable damage for which recovery of damages would be
    inadequate, and that ProjectZen shall therefore be entitled to obtain timely
    injunctive relief under these Terms of Service, as well as such further
    relief as may be granted by a court of competent jurisdiction. You will not
    assign or transfer any rights or obligations under these Terms of Service,
    or any other agreement with ProjectZen, without the prior written consent of
    ProjectZen, and any such assignment or transfer without such consent shall
    be null and void.
  </p>
  <p>
    To contact ProjectZen, please reach us by email at
    <a href="mailto:help@projectzen.com">help&#64;projectzen.com</a>.
  </p>
</div>

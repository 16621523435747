<div id="welcomePageContent">
  <div id="welcome-login" class="welcome-button-container">
    <a class="small-button" href="login">Welcome! Click here to log in.</a>
  </div>
  <div id="welcome-dashboard" class="welcome-button-container">
    <a class="small-button" href=""
      >Welcome! Click here to view your dashboard.</a
    >
  </div>
</div>
